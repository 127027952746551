import React from 'react';
import './ProgressBar.scss';
import classnames from 'classnames';

interface ProgressBarProps {
  className?: string;
  progress?: number;
  color?: string;
  progressClassName?: string;
  animate?: boolean;
}

const ProgressBar: React.FunctionComponent<ProgressBarProps> = ({
  progress,
  className = '',
  animate = false,
  progressClassName = '',
}) => {
  return (
    <div className={classnames('ProgressBar', { [className]: className })}>
      {!animate && (
        <div
          className="ProgressBar-progress"
          style={{ width: `${progress}% ` }}
        />
      )}
      {animate && (
        <div
          className={classnames('ProgressBar-progress ProgressBar-animate', {
            [progressClassName]: progressClassName,
          })}
        />
      )}
    </div>
  );
};
export default ProgressBar;
