import React from 'react';
import { Stylist } from '../../types/stylist/stylist';
import ProvidersSlide from '../ProvidersSlide/ProvidersSlide';
import { RequestStage } from '../../types/request/request';

interface OffersReceivedProps {
  exclude?: string;
  providers?: Stylist[];
  heading?: string;
  type: 'home' | 'mobile';
  onProfileClick?: (s: Stylist) => void;
}

const OffersReceived: React.FunctionComponent<OffersReceivedProps> = ({
  exclude = '',
  providers = [],
  heading = `Offers you've received`,
  type = 'mobile',
  onProfileClick,
}) => {
  if (!providers || !providers.length) {
    return null;
  }

  let providersWithOffers = providers.filter(
    provider =>
      provider.request && provider.request.stage === RequestStage.offered
  );

  if (exclude) {
    providersWithOffers = providersWithOffers.filter(
      provider => provider.providerId !== exclude
    );
  }

  if (!providersWithOffers.length) {
    return null;
  }

  return (
    <div>
      <ProvidersSlide
        type={type}
        providers={providersWithOffers}
        heading={heading}
        onProfileClick={onProfileClick}
      />
    </div>
  );
};
export default OffersReceived;
