import React from 'react';

interface AccordionItemProps {
  heading: React.ReactNode;
  id: string;
}

const AccordionItem: React.FunctionComponent<AccordionItemProps> = ({
  children,
}) => {
  return <div className="AccordionItem">{children}</div>;
};
export default AccordionItem;
