import React from 'react';
import { formatSmsLinkForDevice } from '../../utils';

interface HelpEmailTextProps {
  requestId?: string;
}

const HelpEmailText: React.FunctionComponent<HelpEmailTextProps> = ({
  requestId = '',
}) => {
  function handleClick(link) {
    window.location.replace(link);
  }
  return (
    <div className="columns is-marginless is-mobile has-text-centered is-size-14p">
      <div className="column is-flex is-column">
        <i className="fal fa-envelope is-size-3" />
        <strong className="has-line-height-3">email Flayr</strong>
        <div>
          <a
            onClick={() => {
              handleClick(
                `mailto:hey@flayr.io?subject=Help with search ${requestId}`
              );
            }}
            href={`mailto:hey@flayr.io?subject=Help with search ${requestId}`}
          >
            hey@flayr.io
          </a>
        </div>
      </div>
      <div className="column is-flex is-column">
        <i className="fal fa-comment is-size-3" />
        <strong className="has-line-height-3">call/text Flayr</strong>
        <div>
          <a
            onClick={() =>
              handleClick(
                formatSmsLinkForDevice(
                  '+61488851655',
                  `Help with search ${requestId}`
                )
              )
            }
            href={formatSmsLinkForDevice(
              '+61488851655',
              `Help with search ${requestId}`
            )}
          >
            0488 851 655
          </a>
        </div>
      </div>
    </div>
  );
};
export default HelpEmailText;
