import {
  STYLIST_SET_INFO,
  SORT_STYLISTS,
  FILTER_STYLISTS,
  SET_REVIEWS,
  SET_PROFILE_PRICING,
} from './action-types';
import { Stylist } from '../types/stylist/stylist';
import { IFilterBy } from '../types/search/search';
import { IReview } from '../types/stylist/review';
import { Pricing } from '../types/quote/pricing';

export function setStylistDetails(payload: Stylist) {
  return {
    type: STYLIST_SET_INFO,
    payload,
  };
}

export function sortStylists(payload: 'price' | 'reviews' | 'distance') {
  return {
    type: SORT_STYLISTS,
    payload,
  };
}

export function filterStylists(payload: IFilterBy) {
  return {
    type: FILTER_STYLISTS,
    payload,
  };
}

export function setCurrentReviews(payload: IReview) {
  return {
    type: SET_REVIEWS,
    payload
  }
}

export function setProfilePricing(payload: Pricing | undefined) {
  return {
    type: SET_PROFILE_PRICING,
    payload
  }
}