import { Stylist } from '../stylist/stylist';
import { IReview } from '../stylist/review';
import { IFaq } from '../faq/faqs';

export interface ISearch {
  mobileProviders: Stylist[];
  homeProviders: Stylist[];
  featuredHomeProviders?: Stylist[];
  featuredMobileProviders?: Stylist[];
  hasLoaded: boolean;
  currentProfile: Stylist & { offers?: Stylist[] };
  currentReviews?: IReview;
  filterBy: IFilterBy;
  sortBy?: ESortBy;
  notifications?: { mobile: number; home: number };
  mobilePage: number;
  homePage: number;
  faqs?: IFaq[];
}

export interface IFilterBy {
  hideBooked: boolean;
  hideNew: boolean;
  showInstantBooks: boolean;
}

export enum ESortBy {
  'price' = 'price',
  'reviews' = 'reviews',
  'distance' = 'distance'
}
