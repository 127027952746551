import React, { forwardRef, ForwardRefExoticComponent } from 'react';
import './ScrollPanel.scss';
import classnames from 'classnames';

interface ScrollPanelProps {
  scrollBarVisible?: boolean;
  className?: string;
  onScroll?: (e: any) => void;
}
export type Ref = HTMLDivElement;
const ScrollPanel: any = forwardRef<Ref, ScrollPanelProps>(
  ({ children, scrollBarVisible, onScroll, className = '' }, ref) => {
    return (
      <div className={'ScrollPanel' + ` ${className}`}>
        <div
          className={classnames('ScrollPanel-frame-wrapper', {
            'has-scrollbar-hidden': !scrollBarVisible,
          })}
          ref={ref}
          onScroll={onScroll}
        >
          <div className="ScrollPanel-frame">
            <div className="ScrollPanel-panel has-padding-2">{children}</div>
          </div>
        </div>
      </div>
    );
  }
);

export default ScrollPanel;
