import React, { useState } from 'react';
import './Carousel.scss';
import Slider from 'react-slick';
import { Image } from '../../types/stylist/stylist';
import { optimizeImage, isMobile } from '../../utils';

interface CarouselProps {
  images: Image[];
}

const Carousel: React.FunctionComponent<CarouselProps> = props => {
  const { images } = props;
  const [currentIndex, setIndex] = useState(0);
  const settings: any = {
    lazyLoad: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile() ? 1 : 5,
    slidesToScroll: 1,
    arrows: true,
    afterChange: (idx: number) => setIndex(idx),
  };

  return (
    <>
      <div className="Carousel__container">
        <Slider {...settings}>
          {(!images || !images.length) && <section />}
          {images &&
            images.length &&
            images.map(({ src }, idx) => (
              <div className="Carousel__img-container" key={idx}>
                <figure className="image has-covering-img">
                  <img
                    src={optimizeImage(src, 700)}
                    alt="gallery image"
                    height="100vw"
                    width="100%"
                  />
                </figure>
              </div>
            ))}
        </Slider>
        {!!images.length && (
          <div className="Carousel__count has-text-secondary is-size-5">
            <span className="has-text-weight-bold">{currentIndex + 1} </span> of{' '}
            {images.length}
          </div>
        )}
      </div>
    </>
  );
};

export default Carousel;
