import React from 'react';

export interface AppContextType {
  loader: {
    isLoading: boolean;
    message: string;
  };
  modalOpen: boolean;

  setLoader: (value?: { isLoading: boolean; message?: string }) => any;
  setModalOpen: (value: boolean) => void;
}
const AppContext = React.createContext<AppContextType>({
  loader: {
    isLoading: false,
    message: '',
  },
  modalOpen: false,
  setLoader: () => null,
  setModalOpen: () => {},
});

export default AppContext;
