import React, { FunctionComponent } from 'react';
import { ConnectedComponentClass } from 'react-redux';
import classnames from 'classnames';

interface ConditionalWrapperProps {
  wrap: boolean;
  tag?:
    | React.ComponentClass<any>
    | ConnectedComponentClass<any, any>
    | FunctionComponent<any>;
  className?: string;
}
const ConditionalWrapper: React.FunctionComponent<ConditionalWrapperProps> = ({
  wrap = false,
  children,
  tag,
  className = '',
}) => {
  if (wrap && tag) {
    const TagName = tag;
    return <TagName>{children}</TagName>;
  }
  if (wrap && !tag) {
    return (
      <div className={classnames('ConditionalWrapper', className)}>
        {children}
      </div>
    );
  }

  return <>{children}</>;
};
export default ConditionalWrapper;
