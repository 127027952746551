import React from 'react';
import classnames from 'classnames';

interface FormFieldProps {
  label?: React.ReactChild;
  htmlFor?: string;
  className?: string;
  labelClass?: string;
  required?: boolean;
  message?: string;
  hasError?: boolean;
  hasSuccess?: boolean;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  hasAddon?: boolean;
  isInputExpanded?: boolean;
  addonLabel?: string;
  addonIcon?: React.ReactNode;
  isAddonBtnDisabled?: boolean;
  addOnBtnClassName?: string;
  onBtnClick?: () => void;
}
const FormField: React.FC<FormFieldProps> = props => {
  const {
    label,
    htmlFor,
    className = '',
    labelClass = '',
    children,
    message,
    required,
    hasError,
    hasSuccess,
    iconLeft,
    iconRight,
    hasAddon,
    isInputExpanded,
    addonLabel,
    addonIcon,
    isAddonBtnDisabled,
    addOnBtnClassName = '',
    onBtnClick,
  } = props;
  return (
    <div
      className={classnames('field', {
        [className]: className,
        'has-addons': hasAddon,
        'has-error': hasError,
      })}
    >
      {!!label && (
        <label
          htmlFor={htmlFor}
          className={classnames('has-text-secondary', {
            [labelClass]: labelClass,
          })}
        >
          {label}
          {required && <span className="has-text-danger is-size-7">*</span>}
        </label>
      )}
      <div
        className={classnames('control', {
          'has-icons-left': !!iconLeft,
          'has-icons-right': !!iconRight,
          'is-expanded': hasAddon && isInputExpanded,
        })}
      >
        {!!iconLeft && (
          <span className="icon is-small is-left">{iconLeft}</span>
        )}
        {children}
        {!!iconLeft && (
          <span className="icon is-small is-right">{iconRight}</span>
        )}
      </div>
      {hasAddon && (
        <div className="control">
          <button
            type="button"
            className={`button is-primary ${addOnBtnClassName}`}
            onClick={onBtnClick}
            disabled={isAddonBtnDisabled}
          >
            {!!addonIcon && (
              <span className="is-size-6">
                {addonIcon}
                &nbsp;
              </span>
            )}
            {addonLabel}
          </button>
        </div>
      )}
      {!!message && hasError && <p className="help is-danger">{message}</p>}
    </div>
  );
};

export default FormField;
