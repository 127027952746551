import { combineReducers } from 'redux';
import { authReducer } from './auth';
import { userReducer } from './user';
import { searchReducer } from './search';
import { quoteReducer } from './quote';
import { requestsReducer } from './requests';
import { tabReducer } from './tab';

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  search: searchReducer,
  quote: quoteReducer,
  requests: requestsReducer,
  tab: tabReducer
});

export default rootReducer;
