import React from 'react';

const CalendarHeader: React.FunctionComponent = () => {
  const days = ['s', 'm', 't', 'w', 't', 'f', 's'];
  return (
    <div className="has-margin-top-3">
      <div className="columns is-marginless is-mobile is-capitalized">
        {
          days.map((day, idx) => (
            <div 
              className="column is-paddingless has-text-centered has-text-weight-bold has-text-purple has-margin-1" 
              key={day+idx}
            >
              {day}
            </div>
          ))
        }
      </div>
    </div>
  )
};

export default CalendarHeader;
