import React from 'react';
import classnames from 'classnames';
import './Tab.scss';

interface TabProps {
  activeTab?: string;
  headers?: Array<{
    label: React.ReactNode;
    id: string;
    notifications?: number;
  } | null>;
  onSelect?: (id: any) => void;
  hideHeader?: boolean;
}

const Tab: React.FunctionComponent<TabProps> = ({
  activeTab,
  headers = [],
  onSelect,
  children,
  hideHeader = false,
}) => {
  const tabContent: React.ReactNodeArray = React.Children.toArray(children);
  return (
    <div className="Tab">
      {!hideHeader && (
        <div className="tabs is-centered has-margin-bottom-0 has-background-white">
          <ul>
            {headers.map(header => {
              if (!header) return null;
              return (
                <li
                  key={header.id}
                  className={classnames({
                    'is-active': header.id === activeTab,
                  })}
                  onClick={() => onSelect && onSelect(header.id)}
                >
                  <a>
                    <span className="is-inline-block is-relative">
                      {header.label}{' '}
                      {!!header.notifications && (
                        <span className="Tab-notification">
                          {header.notifications}
                        </span>
                      )}
                    </span>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      )}

      <div className="Tab-body has-margin-top-3">
        {tabContent.map((child: React.ReactNode) => {
          if (child!['props'].id !== activeTab) return null;
          return child!['props'].children;
        })}
      </div>
    </div>
  );
};
export default Tab;
