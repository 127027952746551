import React, { useEffect, useRef, useState } from 'react';

interface MapProps {
  width?: number | string;
  height?: number | string;
  id?: string;
  center: MapCoordinates;
  markers?: MarkerProps[];
  highlightMarker?: number;
  style?: React.CSSProperties;
  onMarkerClick?: (idx: number) => void;
}
interface MapCoordinates {
  lat: number;
  lng: number;
}

export interface MarkerProps extends MapCoordinates {
  title?: string;
  label?: string;
}

const google = window['google'];

const whiteOval = {
  url: '/img/oval-white.svg',
  anchor: new google.maps.Point(25, 50),
  scaledSize: new google.maps.Size(70, 70),
};

const greenOval = {
  url: '/img/oval-green.svg',
  anchor: new google.maps.Point(25, 50),
  scaledSize: new google.maps.Size(70, 70),
};

const Map: React.FunctionComponent<MapProps> = ({
  width = '100%',
  height = 400,
  id = 'map',
  center = {
    lat: 0,
    lng: 0,
  },
  markers = [],

  highlightMarker,
  style = {},
  onMarkerClick,
}) => {
  const mapContainer = useRef(null);

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [mapMarkers, setMapMarkers] = useState<google.maps.Marker[]>([]);

  function initMap(container, config) {
    let google = window['google'];
    const defaultConfig = {
      zoom: 12,
      gestureHandling: 'greedy',
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
    };

    return new google.maps.Map(container, { ...defaultConfig, ...config });
  }

  function initMakrers(markers, newMap) {
    return markers.map((m, idx) => {
      const markerConfig: any = {
        position: m,
        map: newMap,
        title: m.title,
        icon: whiteOval,
        label: {
          text: m.label,
          color: 'black',
        },
        zIndex: idx + 2,
      };
      if (idx === 0) {
        markerConfig.icon = greenOval;
        markerConfig.label = {
          ...markerConfig.label,
          color: 'white',
          zIndex: markers.length + 5,
        };
      }
      const marker = new google.maps.Marker(markerConfig);
      marker.addListener('click', () => {
        onMarkerClick && onMarkerClick(idx);
      });
      return marker;
    });
  }

  function updateMarker(markers, newMarkers, selectedIndex) {
    markers.map((m, idx) => {
      const markerConfig: any = {
        position: newMarkers[idx],
        icon: whiteOval,
        label: {
          ...m.getLabel(),
          color: 'black',
        },
        zIndex: idx + 2,
      };
      if (idx === selectedIndex) {
        markerConfig.icon = greenOval;
        markerConfig.label = { ...markerConfig.label, color: 'white' };
        markerConfig.zIndex = mapMarkers.length + 5;
      }
      mapMarkers[idx].setOptions(markerConfig);
    });
  }

  useEffect(() => {
    if (mapContainer.current && !map) {
      const newMap = initMap(mapContainer.current, { center });
      if (markers.length) {
        setMapMarkers(initMakrers(markers, newMap));
      }
      setMap(newMap);
    }
  }, []);

  useEffect(() => {
    if (!!map) {
      map.panTo(center);
    }
  }, [center]);

  useEffect(() => {
    if (markers.length && !!map) {
      // re render markers if markers were changed
      if (markers.length !== mapMarkers.length) {
        mapMarkers.map(m => {
          google.maps.event.clearListeners(m, 'click');
          m.setMap(null);
        });
        setMapMarkers(initMakrers(markers, map));
      } else {
        updateMarker(mapMarkers, markers, highlightMarker);
      }
    }
  }, [markers]);

  useEffect(() => {
    if (mapMarkers.length) {
      mapMarkers.map((m, idx) => {
        const markerConfig: any = {
          icon: whiteOval,
          label: {
            ...m.getLabel(),
            color: 'black',
          },
          zIndex: idx,
        };
        if (idx === highlightMarker) {
          markerConfig.icon = greenOval;
          markerConfig.label = { ...markerConfig.label, color: 'white' };
          markerConfig.zIndex = mapMarkers.length + 5;
        }
        mapMarkers[idx].setOptions(markerConfig);
      });
    }
  }, [highlightMarker]);

  return <div style={{ width, height, ...style }} id={id} ref={mapContainer} />;
};
export default Map;
