import * as React from 'react';
import classnames from 'classnames';
import Button from '../Button/Button';
import AppContext from '../../contexts/AppContext';

interface ModalProps {
  open: boolean;
  header?: React.ReactNode;
  helper?: string;
  footer?: React.ReactNode;
  footerBtnLabel?: string;
  hasDefaultFooter?: boolean;
  fullScreen?: boolean;
  onClose?: () => void;
  onFooterBtnClick?: () => void;
  onBackdropClick?: () => void;
}
const Modal: React.FunctionComponent<ModalProps> = ({
  open,
  children,
  header,
  helper,
  footer,
  onClose,
  onBackdropClick,
  footerBtnLabel = 'Close',
  hasDefaultFooter = false,
  onFooterBtnClick,
  fullScreen = false,
}) => {
  const { setModalOpen } = React.useContext(AppContext);

  React.useEffect(() => {
    setModalOpen(open);
  }, [open]);

  if (!open) {
    return null;
  }

  return (
    <div className={classnames('modal', { 'is-active': open })}>
      <div className="modal-background" onClick={onBackdropClick} />
      <div
        className={classnames('modal-card', { 'is-fullscreen': fullScreen })}
      >
        {!!header && (
          <>
            <header
              className={classnames('modal-card-head has-bg-white', {
                'has-padding-bottom-2': helper,
              })}
            >
              <div className="modal-card-title is-size-5 is-fill-x">
                {header}
                {!!helper && (
                  <>
                    {' '}
                    <br />
                    <span className="has-text-secondary  is-family-primary is-size-6">
                      <small>{helper}</small>
                    </span>
                  </>
                )}
              </div>
              <button onClick={onClose} className="delete" aria-label="close" />
            </header>
          </>
        )}
        <section className="modal-card-body">{children}</section>
        {!!footer && <footer className="modal-card-foot">{footer}</footer>}

        {!footer && hasDefaultFooter && (
          <footer className="modal-card-foot">
            <Button
              color="gradient-2"
              onClick={onFooterBtnClick}
              className="has-margin-left-auto"
            >
              {footerBtnLabel}
            </Button>
          </footer>
        )}
      </div>
      {/* <button onClick={onClose} className="modal-close is-large" aria-label="close" /> */}
    </div>
  );
};

export default Modal;
