import React from 'react';
import Button from '../../../shared-components/Button/Button';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Quote } from '../../../types/quote/quote';
import { Request, RequestStage } from '../../../types/request/request';
import { Stylist } from '../../../types/stylist/stylist';
import { Pricing } from '../../../types/quote/pricing';
import { connect } from 'react-redux';
import { AppStore } from '../../../types/appStore';
import classnames from 'classnames';
import { getRequestStage } from '../../../utils';
import QuotePricing from '../../../shared-components/QuotePricing/QuotePricing';
import { serviceBasedContent } from '../../../static-data/contentMapping';
// import './ProfileNav.scss';

interface ProfileNavProps extends RouteComponentProps {
  quote: Quote;
  tab: 'home' | 'mobile';
  onRightBtnClick?: () => void;
  request?: Request;
  provider?: Stylist;
  price?: Pricing;
  quoteId?: string;
  hasLoaded: boolean;
  type?: string;
}

const ProfileNav: React.FunctionComponent<ProfileNavProps> = props => {
  const { history, quote, tab, request, hasLoaded, price, type = '' } = props;
  const backClick = () => {
    if (quote && quote.shopify_id && tab && !hasLoaded) {
      return history.push('/quote/' + quote.shopify_id + '/' + tab);
    }
    if (!document.referrer) {
      return history.push('/');
    }
    const regex = /flayr|localhost/gi;
    if (!regex.test(document.referrer)) {
      return history.push('/');
    }
    history.goBack();
  };
  return (
    <nav className="is-sticky-top has-padding-x-3 has-padding-y-2 columns is-mobile is-marginless is-vcentered has-background-white has-shadow-bottom">
      {/* left nav */}
      <section className="is-paddingless is-narrow">
        <Button
          isClear
          className={classnames('is-szie-5 has-padding-y-0', {
            'is-invisible':
              (request && request.stage === RequestStage.booked) ||
              quote.rebook,
          })}
          isDisabled={
            (request && request.stage === RequestStage.booked) || quote.rebook
          }
          onClick={backClick}
        >
          <span className="is-flex is-vcentered">
            <i className="far fa-chevron-left" />{' '}
            <span className="is-size-14p has-margin-left-2 has-padding-bottom-1">
              All{' '}
              {serviceBasedContent[type]
                ? serviceBasedContent[type].stylist
                : 'stylist'}
              s
            </span>
          </span>
        </Button>
      </section>
      <section className="column is-paddingless" />
      {/* right nav */}
      {}
      {/* <div className="is-narrow column has-padding-y-2 is-hidden-desktop">
        {!!price &&
          !!quote &&
          getRequestStage(quote, provider) === RequestStage.noRequest && (
            <QuotePricing total={price.cost} deposit={price.take} />
          )}
      </div> */}
    </nav>
  );
};
const mapStateToProps = ({ quote, search }: AppStore) => ({
  quoteId: quote.shopify_id,
  request: search.currentProfile.request,
  price: search.currentProfile.pricing,
  provider: search.currentProfile,
  hasLoaded: search.hasLoaded,
  type: quote.type,
});
export default connect(mapStateToProps)(withRouter(ProfileNav));
