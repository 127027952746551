import React from 'react';
import { format, getMonth, isToday, isSameDay, parse, isPast } from 'date-fns';
import './CalendarBody.scss';
import classnames from 'classnames';
import { hasMatchingDate } from '../../../utils';

interface CalendarBodyProps {
  date: Date;
  days: Date[][];
  selectedDate?: string;
  onDayClick: (date: Date) => void;
}

const CalendarBody: React.FunctionComponent<CalendarBodyProps> = ({
  days,
  onDayClick,
  date,
  selectedDate,
}) => {
  return (
    <div className="CalendarBody__container">
      {days.map((dates, idx) => {
        if (idx > 0 && getMonth(date) !== getMonth(dates[0])) return null;
        return (
          <div className="CalendarBody__row" key={idx}>
            {dates.map(dateItem => {
              return (
                <div
                  className="CalendarBody__column"
                  key={dateItem.toDateString()}
                >
                  <button
                    className={classnames('CalendarBody__date-container', {
                      'has-text-weight-bold is-size-6': isToday(dateItem),
                      'CalendarBody__date-container--is-selected':
                        selectedDate &&
                        isSameDay(dateItem, parse(new Date(selectedDate))),
                    })}
                    onClick={() => onDayClick(dateItem)}
                    disabled={isPast(dateItem) && !isToday(dateItem)}
                  >
                    <span
                      className={classnames('CalendarBody__date-item', {
                        'has-text-grey': getMonth(dateItem) !== getMonth(date),
                      })}
                    >
                      {format(dateItem, 'D')}
                    </span>
                  </button>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
export default CalendarBody;
