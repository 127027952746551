import React, { useState, useEffect } from 'react';
import './Collage.scss';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { optimizeImage, isCached } from '../../utils';

interface CollageProps {
  images: any[];
  imagesPerSlide?: number;
}

const Collage: React.FunctionComponent<CollageProps> = ({
  images,
  imagesPerSlide = 5,
}) => {
  const [gridImages, setGridImages] = useState<any[]>(
    images.slice(0, imagesPerSlide)
  );

  const [lightboxImg, setLightboxImg] = useState({
    src: '',
    alt: '',
    index: undefined,
  });

  useEffect(() => {
    setGridImages(images.slice(0, imagesPerSlide));
  }, [images]);

  function resetLightBox() {
    setLightboxImg({ src: '', alt: '', index: undefined });
  }

  function handleNext() {
    let idx = (lightboxImg.index || 0) + 1;
    if (idx === images.length) {
      idx = 0;
    }
    showMore(idx);
  }

  function handlePrev() {
    let idx = (lightboxImg.index || 0) - 1;
    if (idx === -1) {
      idx = images.length - 1;
    }
    showMore(idx);
  }

  function showMore(index = 0) {
    if (typeof index !== 'number') {
      index = 0;
    }
    setLightboxImg({
      ...images[index],
      index,
    });
  }

  return (
    <div className="Collage">
      <div className="Collage-container" data-count={imagesPerSlide}>
        {gridImages.map((img, key) => (
          <figure
            className="image Collage-item has-covering-img has-pointer "
            key={key}
            onClick={() => showMore(key)}
          >
            <img src={img.src} alt={img.alt} className="is-cropped-top" />
          </figure>
        ))}
      </div>
      <div className="Collage-icon">
        <div className="Collage-icon-left">
          <i className="fal fa-chevron-left" />
        </div>
        <div className="Collage-icon-right">
          <i className="fal fa-chevron-right" />
        </div>
      </div>
      <div className="Collage-show-all has-text-right has-padding-right-4">
        <Button
          color="gradient-2"
          onClick={showMore}
          className="has-padding-x-4 has-padding-y-3"
        >
          <span className="is-size-16p">Show photos ({images.length})</span>
        </Button>
      </div>
      {!!lightboxImg.src && (
        <div className="Collage-lightbox">
          <section className="Collage-lightbox-cover" onClick={resetLightBox} />

          <div className="is-flex is-vcentered is-hcentered has-margin-n-top-5 is-relative">
            <span className="delete is-large" onClick={resetLightBox} />
            <Button isClear className="nav-icon" onClick={handlePrev}>
              <i className="far fa-chevron-left has-text-white" />
            </Button>
            <div className="Collage-lightbox-img-container">
              <LazyLoadImage
                alt={lightboxImg.alt}
                src={optimizeImage(lightboxImg.src, 700)}
                threshold={1200}
                visibleByDefault={isCached(optimizeImage(lightboxImg.src, 700))}
              />
              {/* <img src={lightboxImg.src} alt={lightboxImg.alt} /> */}
            </div>
            <Button isClear className="nav-icon" onClick={handleNext}>
              <i className="far fa-chevron-right has-text-white" />
            </Button>
            <div className="has-text-white Collage-lightbox-count">
              {lightboxImg.index! + 1} of {images.length}
            </div>
          </div>

          <div className="Collage-lightbox-thumbnails">
            <div className="is-flex-fill" />
            {images.map((img, key) => (
              <figure
                className="image is-96x96 has-covering-img has-pointer"
                key={key}
                onClick={() => showMore(key)}
              >
                <LazyLoadImage
                  alt={img.alt}
                  src={optimizeImage(img.src, 200)}
                  threshold={1200}
                  visibleByDefault={isCached(optimizeImage(img.src, 200))}
                />
                {/* <img src={img.src} alt={img.alt} /> */}
              </figure>
            ))}
            <div className="is-flex-fill" />
          </div>
        </div>
      )}
    </div>
  );
};
export default Collage;
