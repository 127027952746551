import {
  STYLIST_SET_INFO,
  SET_STYLISTS,
  SET_TAB,
  SET_CURRENT_PROFILE,
  SET_HAS_LOADED,
  SET_HOME_PAGE,
  SET_MOBILE_PAGE,
  SET_FAQS,
} from './action-types';
import { Stylist } from '../types/stylist/stylist';
import { IFaq } from '../types/faq/faqs';

export function setStylists(
  homeProviders: Stylist[],
  mobileProviders: Stylist[],
  featuredHomeProviders: Stylist[] = [],
  featuredMobileProviders: Stylist[] = []
) {
  return {
    type: SET_STYLISTS,
    payload: {
      homeProviders,
      mobileProviders,
      featuredHomeProviders,
      featuredMobileProviders,
    },
  };
}

export function setTab(tab: 'home' | 'mobile') {
  return {
    type: SET_TAB,
    payload: tab,
  };
}

export function setCurrentProfile(profile: Stylist) {
  return {
    type: SET_CURRENT_PROFILE,
    payload: profile,
  };
}

export function setHasLoaded(hasLoaded: boolean) {
  return {
    type: SET_HAS_LOADED,
    payload: hasLoaded,
  };
}

export function setHomePage(page: number) {
  return {
    type: SET_HOME_PAGE,
    payload: page,
  };
}

export function setMobilePage(page: number) {
  return {
    type: SET_MOBILE_PAGE,
    payload: page,
  };
}

export function setFaqs(faqs: IFaq[]) {
  return {
    type: SET_FAQS,
    payload: faqs,
  };
}
