import React, { useState, useEffect } from 'react';
import {
  serviceMappingHaircut,
  serviceNameMappingHaircut,
  serviceTypeMappingHaircut,
} from '../../../static-data/personServiceMapping';
import Button from '../../Button/Button';
import FormField from '../../FormField/FormField';

interface PeopleFormHaircutProps {
  setPeopleFormMode: (mode: 'select' | 'view') => void;
  onPersonServiceSelect: (personService: string[]) => void;
  onPersonServiceRemove: (idx: number) => void;
  peopleFormMode: 'select' | 'view';
  serviceList: Array<string[]>;
}

const PeopleFormHaircut: React.FC<PeopleFormHaircutProps> = ({
  setPeopleFormMode,
  serviceList,
  onPersonServiceSelect,
  onPersonServiceRemove,
  peopleFormMode,
}) => {
  const [serviceType, setServiceType] = useState('');
  const [service, setService] = useState('');

  useEffect(() => {
    setServiceType('');
    setService('');
  }, [serviceList]);

  function handleChange(e) {
    const { value, name } = e.target;
    if (name === 'serviceType') {
      setServiceType(value);
    } else {
      setService(value);
      onPersonServiceSelect([serviceType, value]);
    }
  }

  function handleAddAnother() {
    setPeopleFormMode('select');
  }

  return (
    <div>
      {peopleFormMode === 'select' && (
        <>
          {serviceList && !!serviceList.length && (
            <Button
              className="has-margin-n-top-4 has-margin-n-left-4"
              isClear
              onClick={() => {
                setPeopleFormMode('view');
              }} //back handler
            >
              <i className="fas fa-arrow-left" />
            </Button>
          )}
          {
            <FormField label="Select what kind of service you are after">
              <div className="select is-fullwidth">
                <select
                  name="serviceType"
                  id="serviceType"
                  className="input"
                  onChange={handleChange}
                  value={serviceType}
                >
                  <option value="" disabled>
                    Select a service
                  </option>
                  {serviceTypeMappingHaircut.map(option => (
                    <option value={option.value} key={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </FormField>
          }
          {!!serviceType && (
            <FormField label="Select what service you require">
              <div className="select is-fullwidth">
                <select
                  name="service"
                  id="service"
                  className="input"
                  onChange={handleChange}
                  value={service}
                >
                  <option value="" disabled>
                    Select a service
                  </option>
                  {serviceMappingHaircut.map(option => (
                    <>
                      {option.visibleFor &&
                        option.visibleFor.includes(serviceType) && (
                          <option value={option.value} key={option.value}>
                            {option.label}
                          </option>
                        )}
                    </>
                  ))}
                </select>
              </div>
            </FormField>
          )}
        </>
      )}

      {peopleFormMode === 'view' && (
        <>
          {serviceList.map((service, key) => (
            <div
              className="columns is-marginless is-vcentered is-mobile has-margin-bottom-4"
              key={key}
            >
              <div className="column is-paddingless is-capitalized">
                <div>
                  <strong>
                    {key + 1}.{' '}
                    {service[0] === 'coloring' ? 'colouring' : service[0]}
                  </strong>
                </div>
                <div className="has-padding-left-3">
                  {serviceNameMappingHaircut[service[1]]}
                </div>
              </div>
              <div className="column is-narrow is-paddingless">
                <Button
                  isCircle
                  size="small"
                  color="gradient-1"
                  isOutlined
                  onClick={() => {
                    onPersonServiceRemove(key);
                  }} //remove from list,
                >
                  <span>✕</span>
                </Button>
              </div>
            </div>
          ))}

          <div className="has-margin-top-4 is-flex is-vcentered">
            <Button
              isCircle
              size="small"
              color="gradient-1"
              isOutlined
              onClick={handleAddAnother} //add another person
            >
              <span>
                <i className="fal fa-plus has-margin-right-1" />
                <i className="far fa-user" />
              </span>
            </Button>
            <div
              className="has-margin-left-4 has-text-weight-bold"
              onClick={() => {}} //add another person
            >
              Add {serviceList.length > 0 ? 'another' : 'a'} person
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PeopleFormHaircut;
