import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { optimizeImage, isMobile } from '../../utils';
import './ProviderCard.scss';
// import Button from '../Button/Button';
import ReadMore from '../ReadMore/ReadMore';
import ProfileIndicator from '../ProfileIndicator/ProfileIndicator';
import { requestedToday } from '../../services/stylist.services';
import classnames from 'classnames';

interface ProviderCardProps {
  name: string;
  rating: number;
  reviews: number;
  type: 'home' | 'mobile';
  price?: number;
  deposit?: number;
  onClick?: () => void;
  profileImg: string;
  coverImg: string;
  about?: string;
  kit?: string;
  bookedOut?: boolean;
  requested?: boolean;
  instantOffer?: boolean;
  startTime?: string;
  endTime?: string;
  bookingConfirmed?: boolean;
  providerId?: string;
  featured?: boolean;
  imgSize?: string;
  featuredListItem?: boolean;
  className?: string;
  distance?: number;
  hideReviews?: boolean;
  location?: string;
  covidTrained?: boolean;
}

const ProviderCard: React.FunctionComponent<ProviderCardProps> = ({
  coverImg,
  name,
  onClick,
  profileImg,
  hideReviews,
  reviews,
  rating,
  price,
  deposit,
  about,
  kit,
  bookedOut,
  requested,
  instantOffer,
  startTime,
  endTime,
  bookingConfirmed,
  providerId,
  featured,
  imgSize = '3by4',
  distance,
  location,
  featuredListItem = false,
  className = '',
  covidTrained,
}) => {
  const [inDemand, setInDemand] = useState(false);

  const getRequestedToday = async (providerId: string) => {
    let data = (await requestedToday(providerId)).data;
    const { num_of_requests } = data;
    if (num_of_requests > 0) {
      setInDemand(true);
    }
  };

  useEffect(() => {
    if (providerId) {
      getRequestedToday(providerId);
    }
  }, []);

  function getIndicatorType(): string {
    if (bookedOut) {
      return 'bookedOut';
    }
    if (requested) {
      return 'requested';
    }
    if (instantOffer) {
      return 'instantOffer';
    }
    if (inDemand) {
      return 'inDemand';
    }
    if (covidTrained) {
      return 'covidTrained';
    }
    return '';
  }

  return (
    <div
      className={classnames('ProviderCard has-pointer', {
        'ProviderCard-featured': featured,
        'ProviderCard-booked-out': bookedOut,
        'ProviderCard-featured-list': featuredListItem,
        [className]: className,
      })}
      onClick={onClick}
    >
      <div className="ProviderCard-cover is-relative">
        <figure
          className={classnames(
            'image has-covering-img has-overlay-bottom',
            `is-${imgSize}`,
            {
              'is-grayscale': bookedOut,
            }
          )}
        >
          <img
            className="is-cropped-top"
            alt={`${name}'s cover image`}
            src={optimizeImage(coverImg, 600)}
          />
          {/* <LazyLoadImage
            className="is-cropped-top"
            alt={`${name} cover image`}
            src={optimizeImage(coverImg, 600)}
            threshold={50}
          /> */}
        </figure>
        <button className="ProviderCard-view-btn">
          View <i className="fal fa-chevron-right" />
        </button>
        {getIndicatorType() && !bookingConfirmed && (
          <ProfileIndicator
            type={getIndicatorType()}
            startTime={startTime}
            endTime={endTime}
          />
        )}
      </div>

      <div className="ProviderCard-provider-info">
        <div className="is-flex has-margin-y-2 is-vcentered">
          <figure
            className={classnames(
              'image has-covering-img is-48x48 has-margin-right-3',
              {
                'is-grayscale': bookedOut,
              }
            )}
          >
            <LazyLoadImage
              className="is-cropped-top is-rounded"
              alt={`${name} profile`}
              src={optimizeImage(profileImg, 100)}
            />
          </figure>
          <div className="has-line-height-3">
            <p>{name}</p>
            {!hideReviews && (
              <p>
                <small>
                  {rating} <span className="star has-text-gradient-1" />{' '}
                  <span className="has-text-dark-50">
                    (
                    {reviews ? (
                      reviews
                    ) : (
                      <span className="has-text-purple">new</span>
                    )}
                    )
                  </span>
                </small>
              </p>
            )}
          </div>
        </div>

        {featured && (
          <div className="has-margin-bottom-2 has-margin-top-2">
            <span className="tag is-primary">
              {' '}
              <i className="fas fa-trophy-alt has-margin-right-2" />{' '}
              <strong>Recommended</strong>
            </span>
          </div>
        )}

        {!!price && (
          <div
            className={classnames(
              'has-margin-top-2 has-padding-x-1 has-line-height-3',
              { 'has-margin-bottom-4': featured }
            )}
          >
            <small className="has-text-weight-medium">${price} Total </small>
            <br />
            <small className="has-text-dark-50">deposit of ${deposit}</small>
            <br />
            <>
              {distance && (
                <small className="has-text-dark-50">
                  <i className="fa-map-marker-alt far" /> {location}
                  {!!distance && <span>, {distance} km</span>}
                </small>
              )}
            </>
          </div>
        )}

        {(!price || featured) && (
          <div className="has-line-height-3 is-size-12p">
            <ReadMore
              text={about || kit || ''}
              max={isMobile() ? 45 : 85}
              hideShowMore
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default ProviderCard;
