import { createStore, applyMiddleware, Store } from 'redux';
import reducer from './reducers';
import { AppStore } from './types/appStore';

export const store: Store<AppStore> = createStore(
  reducer /* preloadedState, */,
  process.env.NODE_ENV === 'development'
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION__({
          trace: true,
          traceLimit: 25,
        })
    : {}
);
