import { AnyAction } from 'redux';
import { SET_QUOTE } from '../actions/action-types';
import { ISearch } from '../types/search/search';
import { Quote } from '../types/quote/quote';

const initialState: Quote = {
  person_service_list: [],
  shopify_id: '',
  state: '',
  suburb: '',
  postcode: '',
  event_date: '',
  event_time: '',
  look_desc: 'Undecided',
  country: 'AU',
  num_of_people: 0,
  type: '',
};

export function quoteReducer(
  state = initialState,
  { type, payload }: AnyAction
) {
  switch (type) {
    case SET_QUOTE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
