import React from 'react';
import { Stylist } from '../../../types/stylist/stylist';
import classnames from 'classnames';
import ProviderCard from '../../../shared-components/ProviderCard/ProviderCard';
import { isMobile } from '../../../utils';
import { RequestStage } from '../../../types/request/request';
import { Quote } from '../../../types/quote/quote';
import ProfileDisplay from '../../../shared-components/ProfileDisplay/ProfileDisplay';

interface ProviderListProps {
  type: 'home' | 'mobile';
  providers: Stylist[];
  quote: Quote;
  quoteId: string;
  onProfileClick: (provider: Stylist) => void;
  onShowPrices: VoidFunction;
}

const ProviderList: React.FunctionComponent<ProviderListProps> = ({
  providers,
  type,
  onProfileClick,
  quote,
  onShowPrices,
  quoteId,
}) => {
  const getProviderLocation = (provider: Stylist, type: 'home' | 'mobile') => {
    if (type === 'home') {
      return provider.suburb;
    } else if (provider.suburb && provider.state) {
      return `${provider.suburb}, ${provider.state}`;
    } else if (quote.suburb && quote.state) {
      return `${quote.suburb}, ${quote.state}`;
    } else {
      return undefined;
    }
  };

  return (
    <>
      {providers.map((provider) => {
        const {
          firstName,
          profileImage,
          coverImage,
          numOfReviews,
          reviewScore,
          covidTrained,
        } = provider;

        console.log(covidTrained);

        return (
          <div
            className={classnames('column is-12-tablet is-paddingless', {
              'is-6-mobile': !provider.isFeatured || provider.bookedOut,
              'is-12-mobile': provider.isFeatured && !provider.bookedOut,
            })}
            key={provider.providerId}
          >
            {isMobile() && (
              <div className="has-padding-3">
                <ProviderCard
                  className="has-margin-bottom-4"
                  name={firstName}
                  rating={reviewScore}
                  reviews={numOfReviews}
                  profileImg={profileImage}
                  coverImg={coverImage}
                  type={type}
                  onClick={() => onProfileClick(provider)}
                  price={provider.pricing ? provider.pricing.cost : undefined}
                  deposit={provider.pricing ? provider.pricing.take : undefined}
                  kit={
                    Array.isArray(provider.makeupBrands)
                      ? provider.makeupBrands.join(', ')
                      : provider.makeupBrands
                  }
                  about={provider.bio}
                  hideReviews={quote.type === 'haircut'}
                  startTime={provider.request && provider.request.startTime}
                  endTime={provider.request && provider.request.finishTime}
                  providerId={provider.providerId}
                  requested={
                    provider.request &&
                    provider.request.stage === RequestStage.requested
                  }
                  bookedOut={
                    provider.bookedOut ||
                    (provider.request &&
                      provider.request.stage === RequestStage.declined)
                  }
                  distance={
                    type === 'home' && provider.distance
                      ? Math.round(provider.distance / 1000)
                      : undefined
                  }
                  location={getProviderLocation(provider, type)}
                  instantOffer={
                    provider.request &&
                    (provider.request.instantOffer ||
                      provider.request.stage === RequestStage.offered)
                  }
                  featured={provider.isFeatured && !provider.bookedOut}
                  covidTrained={covidTrained}
                />
              </div>
            )}

            {!isMobile() && (
              <ProfileDisplay
                profileImg={provider.profileImage}
                coverImg={provider.coverImage}
                name={provider.firstName}
                rating={provider.reviewScore}
                reviews={provider.numOfReviews}
                hideReviews={quote.type === 'haircut'}
                price={provider.pricing ? provider.pricing.cost : undefined}
                deposit={provider.pricing ? provider.pricing.take : undefined}
                kit={
                  Array.isArray(provider.makeupBrands)
                    ? provider.makeupBrands.join(', ')
                    : provider.makeupBrands
                }
                imgSize="48"
                key={provider.providerId}
                onProfileClick={() => {
                  onProfileClick(provider);
                }}
                onShowPrices={onShowPrices}
                instantOffer={
                  provider.request &&
                  (provider.request.instantOffer ||
                    provider.request.stage === RequestStage.offered)
                }
                startTime={provider.request && provider.request.startTime}
                endTime={provider.request && provider.request.finishTime}
                providerId={provider.providerId}
                type={type}
                generic={!quoteId}
                location={getProviderLocation(provider, type)}
                distance={
                  type === 'home' && provider.distance
                    ? Math.round(provider.distance / 1000)
                    : undefined
                }
                requested={
                  provider.request &&
                  provider.request.stage === RequestStage.requested
                }
                bookedOut={
                  provider.bookedOut ||
                  (provider.request &&
                    provider.request.stage === RequestStage.declined)
                }
                bio={provider.bio}
                covidTrained={covidTrained}
              />
            )}
          </div>
        );
      })}
    </>
  );
};
export default ProviderList;
