import React, { useState, useEffect } from 'react';

interface ReadMoreProps {
  max?: number;
  text: string;
  showMoreText?: string;
  showMoreClassName?: string;
  hideShowMore?: boolean;
}

const ReadMore: React.FunctionComponent<ReadMoreProps> = ({
  max = 135,
  text = '',
  hideShowMore,
}) => {
  const [content, setContent] = useState((text || '').slice(0, max));
  const [showFullContent, setShowFullContent] = useState(false);

  useEffect(() => {
    setContent((text || '').slice(0, max));
  }, [text]);

  function toggleContent() {
    if (!showFullContent) {
      setContent(text);
    } else {
      setContent(text.slice(0, max));
    }
    setShowFullContent(!showFullContent);
  }

  return (
    <>
      {content}
      {content.length < text.length && !showFullContent && <span>...</span>}
      {!hideShowMore && !(text.length <= max) && (
        <span className="has-pointer">
          <br />
          <strong onClick={toggleContent}>
            {showFullContent ? 'show less' : 'read more'}
          </strong>
        </span>
      )}
    </>
  );
};
export default ReadMore;
