import React from 'react';
import classnames from 'classnames';
import './ProfileIndicator.scss';
import { getTimeAmPm, isMobile } from '../../utils';

interface ProfileIndicatorProps {
  type: string;
  startTime?: string;
  endTime?: string;
}

const typeDescriptionMapping = {
  inDemand: {
    bg: 'has-background-danger',
    icon: 'fas fa-fire',
    heading: 'In Demand',
    text: 'Requested today!',
  },
  covidTrained: {
    bg: 'has-background-primary',
    icon: 'fal fa-hand-sparkles',
    heading: 'COVID-19 Trained',
    text: '',
  },
  requested: {
    bg: 'has-bg-gradient-blue-blue',
    icon: 'far fa-redo',
    heading: 'Requested',
    text: 'Checking Availability!',
  },
  bookedOut: {
    bg: 'has-background-grey',
    icon: 'fas fa-minus-circle',
    heading: 'Booked Out',
    text: 'Unavailable',
  },
  instantOffer: {
    bg: 'has-bg-gradient-2',
    icon: 'fas fa-bolt',
    heading: 'Instant Book',
    text: '',
  },
  bookingConfirmed: {
    bg: 'has-bg-gradient-2',
    icon: 'far fa-check-circle',
    heading: 'Booked',
    text: '',
  },
};

const ProfileIndicator: React.FunctionComponent<ProfileIndicatorProps> = ({
  type,
  startTime,
  endTime,
}) => {
  return (
    <div
      className={classnames(
        'is-flex has-text-white',
        'has-padding-top-2 has-padding-bottom-1 has-padding-top-1-tablet',
        'has-padding-left-3 has-padding-left-4-tablet has-padding-right-3',
        'ProfileIndicator',
        typeDescriptionMapping[type].bg,
        { 'is-vcentered': !isMobile(), 'is-vend': isMobile() }
      )}
    >
      <i
        className={classnames(
          'is-size-4 is-size-6-mobile has-margin-right-2 has-margin-top-1 has-margin-top-0-tablet',
          typeDescriptionMapping[type].icon
        )}
      />{' '}
      <div className="is-size-14p">
        <span
          className={classnames({
            'has-text-weight-bold': !isMobile(),
            'has-text-weight-medium': isMobile(),
          })}
        >
          {typeDescriptionMapping[type].heading}
        </span>
        <div
          className={classnames(
            'has-margin-n-top-2 has-line-height-3 has-padding-bottom-1 is-hidden-mobile',
            {
              [typeDescriptionMapping[type].textClassName]: [
                typeDescriptionMapping[type].textClassName,
              ],
            }
          )}
        >
          {typeDescriptionMapping[type].text}
          {type === 'instantOffer' && startTime && endTime && (
            <span>
              {getTimeAmPm(startTime)} to {getTimeAmPm(endTime)}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
export default ProfileIndicator;
