import Axios from 'axios';
import { BASE_SERVER_URL } from '../constants';
import { getAuthHeader, getTimeAmPm } from '../utils';

export async function getRequestInfo(requestId) {
  const params = { requestId };
  return Axios.get(`${BASE_SERVER_URL}/v1/requests/info`, {
    params,
    ...getAuthHeader(),
  });
}

export async function purchaseAddon(data: any) {
  return Axios.post(
    `${BASE_SERVER_URL}/v1/payments/add-on-charge`,
    data,
    getAuthHeader()
  );
}


export async function submitTimeChange(reason: string, time: string, timeToChange: string, idOfRequest: string) {
  return Axios.post(`${BASE_SERVER_URL}/request-time-change`, {
    id_of_request: idOfRequest,
    reason_for_change: reason,
    time: getTimeAmPm(time),
    time_to_change: timeToChange
  });
}