import Axios from 'axios';
import { BASE_SERVER_URL } from '../constants';
import { Quote } from '../types/quote/quote';

export async function getGenericStylists() {
  return Axios.get(`${BASE_SERVER_URL}/v2/stylists`);
}

export async function getLocationBasedStylists(state: string, suburb: string) {
  return Axios.get(
    `${BASE_SERVER_URL}/v2/stylists?state=${state}&suburb=${suburb}`
  );
}

export async function quoteSearch(quoteId: string) {
  return Axios.get(`${BASE_SERVER_URL}/v2/stylists/${quoteId}`);
}

export async function createQuoteAPI(quote: Quote) {
  return Axios.post(`${BASE_SERVER_URL}/v2/quote`, quote);
}

export async function patchQuote(
  shopify_id: string,
  name: string,
  email: string,
  mobile: string,
  event_desc: string,
  event_time: string
) {
  return Axios.patch(`${BASE_SERVER_URL}/v2/quote`, {
    shopify_id,
    name,
    mobile,
    email,
    event_desc,
    event_time,
  });
}

export async function getProfileFromRequest(requestId: string) {
  return Axios.get(`${BASE_SERVER_URL}/quoteId-from-requestId/${requestId}`);
}
