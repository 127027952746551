import React from 'react';
import classnames from 'classnames';
import './Navbar.scss';
import Button from '../Button/Button';

interface NavbarProps {
  sticky?: boolean;
}

const Navbar: React.FunctionComponent<NavbarProps> = ({ sticky = false }) => {
  return (
    <nav
      className={classnames('Navbar has-background-white is-flex', {
        'is-sticky-top': sticky,
      })}
    >
      <div className="columns is-mobile is-marginless">
        <div className="column is-narrow has-padding-y-0 is-flex is-vcentered">
          <span className="has-text-gradient-logo is-family-secondary is-size-4">
            Flayr
          </span>
        </div>
        <div className="column has-padding-y-0" />
        <div className="column is-narrow has-padding-y-2 is-flex is-vcentered">
          <div>
            <Button
              type="link"
              href="https://flayr.io/join-us"
              color="gradient-1"
              isOutlined
              isRounded
              outlineSize={2}
              className="has-margin-right-3 is-invisible"
            >
              <span>
                <span className="has-text-gradient-1">Join as an artist</span>
              </span>
            </Button>
          </div>

          {/* <Button
            color="gradient-1"
            isRounded
            outlineSize={2}
            className="has-padding-y-3"
          >
            Save this search
          </Button> */}
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
