import React, { useState } from 'react';
import { serviceBasedContent } from '../../static-data/contentMapping';

interface PricingInclusionProps {
  total: number;
  deposit: number;
  payableOnDay: number;
  type: string;
  paymentOption?: string;
}

const PricingInclusion: React.FunctionComponent<PricingInclusionProps> = ({
  total,
  deposit,
  payableOnDay,
  type,
  paymentOption
}) => {
  const [showInclusion, setShowInclusion] = useState(false);

  function toggleInclusion() {
    setShowInclusion(!showInclusion);
  }
  return (
    <section className="is-size-16p">
      <div className="is-size-small">
        <h4 className="is-size-16p">Pricing & Inclusions</h4>
        <div className="has-padding-left-4 has-padding-3 is-family-secondary">
          <p className="is-size-5 has-text-weight-medium has-text-dark-100">
            ${total} total
          </p>
          <div className="is-size-14p has-text-dark-75 has-margin-bottom-3">
            <p>Book with a deposit of ${deposit}</p>

            {!paymentOption &&
              <p className="has-margin-bottom-3">
                ${payableOnDay} payable on the day</p>
            }
            {paymentOption &&
              <p className="has-margin-bottom-3">
                {paymentOption}</p>
            }


            <span
              className="is-underlined has-pointer"
              onClick={toggleInclusion}
            >
              {showInclusion ? 'Hide' : 'View'} inclusions
            </span>

            {showInclusion && (
              <div className="has-padding-x-3 has-text-dark-75 content is-family-primary">
                <ul>
                  {serviceBasedContent[type] &&
                    serviceBasedContent[type].inclusions.map((inc, key) => (
                      <li key={key}>{inc}</li>
                    ))}
                </ul>
              </div>
            )}
          </div>
          {!!serviceBasedContent[type].bonus && (
            <p className="is-size-14p has-text-weight-bold is-flex is-vcentered">
              <i className="fas fa-check-circle is-size-16p has-margin-right-3" />{' '}
              Bonus! Includes {serviceBasedContent[type].bonus}
            </p>
          )}
        </div>
      </div>
    </section>
  );
};
export default PricingInclusion;
