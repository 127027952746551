import React, { useState, useEffect } from 'react';
import Modal from '../../../shared-components/Modal/Modal';
import { timeMapping } from '../../../static-data/timeMapping';
import FormField from '../../../shared-components/FormField/FormField';
import Button from '../../../shared-components/Button/Button';

interface ChangeTimeModalProps {
  open: boolean;
  onClose: () => void;
  onTimeSubmit: (timeToChange: string, time: string, reason: string) => void;
}

const ChangeTimeModal: React.FunctionComponent<
  ChangeTimeModalProps
> = props => {
  const { open, onClose, onTimeSubmit } = props;
  const [timeToChange, setTimeToChange] = useState<string>('');
  const [time, setTime] = useState<string>('');
  const [reason, setReason] = useState<string>('');

  return (
    <Modal
      open={open}
      onClose={onClose}
      onBackdropClick={onClose}
      header="Request new time"
    >
      <div>
        <FormField label="Would you like to change start time or finish time?" labelClass="has-text-weight-bold is-family-primary is-size-7">
          <div className="select is-fullwidth">
            <select
              value={timeToChange}
              onChange={e => {
                setTimeToChange(e.target.value);
              }}
            >
              <option value="" disabled>
                select
              </option>
              <option value="start">Start Time</option>
              <option value="finish">Finish Time </option>
            </select>
          </div>
        </FormField>

        <>
          <FormField label={`Select new ${timeToChange} time`} labelClass="has-text-weight-bold is-family-primary is-size-7">
            <div className="select is-fullwidth">
              <select
                value={time}
                onChange={e => {
                  setTime(e.target.value);
                }}
              >
                <option value="" disabled>
                  select time
                </option>
                {timeMapping.map(time => (
                  <option value={time.value} key={time.value}>
                    {time.label}
                  </option>
                ))}
              </select>
            </div>
          </FormField>

          <FormField label="Reason for time change" labelClass="has-text-weight-bold is-family-primary is-size-7">
            <div className="select is-fullwidth">
              <select
                value={reason}
                onChange={e => {
                  setReason(e.target.value);
                }}
              >
                <option value="" disabled>
                  select a reason
                </option>
                <option value="selected wrong time">selected wrong time</option>
                <option value="not sure if stylist given enough time">
                  not sure if stylist given enough time
                </option>
                <option value="I can only start at this time">
                  I can only start at this time
                </option>
                <option value="can we get finished faster">
                  can we get finished faster
                </option>
              </select>
            </div>
          </FormField>

          <div className="has-margin-top-5">
            <Button
              isRounded
              isFullwidth
              color="gradient-1"
              onClick={() => {
                onTimeSubmit(timeToChange, time, reason);
              }}
              isDisabled={!(timeToChange && time && reason)}
            >
              Submit time change
            </Button>
          </div>
        </>
      </div>
    </Modal>
  );
};
export default ChangeTimeModal;
