import { User } from "../types/user";
import { AnyAction } from "redux";
import { USER_GET_INFO } from "../actions/action-types";



const initialState: User = {
  mobile: '',
  stripe_customer_id: '',
  bookings: [],
  country: '',
  email: '',
  name: ''
};

export function userReducer(state = initialState, { type, payload }: AnyAction) {
  switch (type) {
    case USER_GET_INFO:
      return { ...state, ...payload }
    default: return state;
  }
}