import { AnyAction } from 'redux';
import { Request } from '../types/request/request';
import { REQUEST_SET_REQUEST_ID, REQUEST_SET_REQUESTS } from '../actions/action-types';


const initialState = {
  requests: [],
  currentRequestId: '',
};

export function requestsReducer(
  state = initialState,
  { type, payload }: AnyAction
) {
  switch (type) {
    case REQUEST_SET_REQUEST_ID:
      return {
        ...state,
        currentRequestId: payload,
      };
    case REQUEST_SET_REQUESTS:
      return { ...state, requests: payload };
    default:
      return state;
  }
}
