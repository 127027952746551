import React from 'react';
import { Spinner } from '../../../shared-components/Spinner/Spinner';
import { Quote } from '../../../types/quote/quote';
import { findProviderInList } from '../../../utils';
import { Stylist } from '../../../types/stylist/stylist';
import Button from '../../../shared-components/Button/Button';
import { withRouter, RouteComponentProps } from 'react-router';
import QuoteDisplay from '../../../shared-components/QuoteDisplay/QuoteDisplay';
import PricingInclusion from '../../../shared-components/PricingInclusion/PricingInclusion';
import { serviceBasedContent } from '../../../static-data/contentMapping';

interface SearchResultsBoxProps extends RouteComponentProps {
  searchLoading: boolean;
  quote: Quote;
  provider: Stylist;
  mobileList: Stylist[];
  onCheckAvailability?: () => void;
}
const SearchResultsBox: React.FunctionComponent<SearchResultsBoxProps> = ({
  searchLoading,
  mobileList,
  provider,
  history,
  quote,
  onCheckAvailability,
}) => {
  const providerExists =
    mobileList &&
    mobileList.some(
      prov => prov.providerId === provider.providerId && !prov.bookedOut
    );
  return (
    <>
      {searchLoading && (
        <>
          <div className="level has-margin-top-5">
            <div className="level-item">
              <Spinner
                message="Calculating Pricing"
                isShowing
                fullScreen={false}
              />
            </div>
          </div>
        </>
      )}

      {!searchLoading && (
        <>
          {providerExists && (
            <>
              <QuoteDisplay quote={quote} type="mobile" provider={provider} />
              <PricingInclusion
                type={quote ? quote.type || 'makeup' : 'makeup'}
                deposit={provider.pricing ? provider.pricing.take : 0}
                total={provider.pricing ? provider.pricing.cost : 0}
                payableOnDay={provider.pricing ? provider.pricing.take : 0}
              />
              <div className="has-margin-top-5 has-padding-x-4">
                <Button
                  isRounded
                  color="gradient-1"
                  isFullwidth
                  onClick={onCheckAvailability}
                >
                  <small>Check Availability</small>
                </Button>
              </div>
            </>
          )}
          {!providerExists && (
            <>
              <p>
                <small>
                  Unfortunately {provider.firstName} is not available for your
                  search.
                </small>
              </p>
              <br />
              <div className="has-text-centered">
                <Button
                  isRounded
                  color="gradient-1"
                  onClick={() => {
                    history.push('/quote/' + quote.shopify_id);
                  }}
                >
                  See other{' '}
                  {serviceBasedContent[quote.type || 'makeup'].stylist}
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default withRouter(SearchResultsBox);
