import getYear from 'date-fns/get_year';
import getMonth from 'date-fns/get_month';
import addDays from 'date-fns/add_days';
import subDays from 'date-fns/sub_days';
import format from 'date-fns/format';
import startOfWeek from 'date-fns/start_of_week';
import isSameDay from 'date-fns/is_same_day';
import { timeMapping } from './static-data/timeMapping';
import { IReview } from './types/stylist/review';
import camelcaseKeys from 'camelcase-keys';
import { Stylist } from './types/stylist/stylist';
import { Request, RequestStage } from './types/request/request';
import { Quote } from './types/quote/quote';

/**
 * Returns an array range from 0 to n
 */
function range(n: number) {
  return [...Array(n).keys()];
}

const rows = range(6);
const cols = range(7);

/**
 * Returns a two-dimensional array with calendar represented dates
 */
export function getCalendarMatrix(
  year = getYear(new Date()),
  month = getMonth(new Date()),
  weekStartsOn = 0
) {
  const matrix: any = [];
  const date = new Date(year, month);
  let curDate = startOfWeek(date, { weekStartsOn });

  rows.forEach((row) => {
    const week: any = [];
    cols.forEach((col) => {
      week.push(curDate);
      curDate = addDays(curDate, 1);
    });
    matrix.push(week);
  });

  return matrix;
}

export function insertRequest(
  request: Request,
  collection: Stylist[]
): Stylist[] {
  let collectionCopy = [...collection];
  for (let c of collectionCopy) {
    if (c.providerId === request.providerId) {
      c.request = { ...request };
    }
  }
  return collectionCopy;
}

export function findProviderInList(
  mobileList: Stylist[],
  providerId: string
): Stylist | undefined {
  let provider: Stylist | undefined = undefined;
  for (let p of mobileList) {
    if (p.providerId === providerId) {
      provider = p;
    }
  }
  return provider;
}

export function hasMatchingDate(array: Date[], date: Date) {
  return array.some((item) => isSameDay(item, date));
}

export function optimizeShopifyImage(src: string) {
  if (/cdn.shopify.com/.test(src)) {
    const imgComponents = src.split('.');
    const toModify = imgComponents[imgComponents.length - 2];
    const modified =
      toModify.slice(0, -3) + Math.round(window.innerWidth * 2) + 'x';
    imgComponents[imgComponents.length - 2] = modified;
    return imgComponents.join('.');
  }
  return src;
}

export function optimizeImage(src: string, size: number) {
  try {
    if (/cdn.shopify.com/.test(src)) {
      let newSrc = src;
      //remove last dot;
      if (newSrc.charAt(newSrc.length - 1) === '.') {
        newSrc = newSrc.substring(0, newSrc.length - 1);
      }

      let urlParts = newSrc.split('.');
      if (
        !urlParts[urlParts.length - 2].includes('grande') &&
        !urlParts[urlParts.length - 2].match(/_[0-9][0-9][0-9]x[0-9][0-9][0-9]/)
      ) {
        urlParts[urlParts.length - 2] =
          urlParts[urlParts.length - 2] + `_${size}x`;
      }
      let newURL = urlParts.join('.');
      return newURL;
    } else {
      /* if (!/dashboard-uploads-2.s3+/.test(src)) {
        return src;
      }
      if (!src) {
        return '';
      }
      const key = src.split('/').reverse()[0];
      const data = {
        bucket: 'dashboard-uploads-2',
        key,
        edits: {
          resize: {
            width: size,
            fit: 'outside',
          },
        },
      };
      const encoded = window.btoa(JSON.stringify(data));
      const url = 'https://d1fr8s0eujep1w.cloudfront.net/' + encoded;
      return url; */
      return src;
    }
  } catch (err) {
    return src;
  }
}

export function isCached(url) {
  var test = document.createElement('img');
  test.src = url;
  return test.complete || test.width + test.height > 0;
}

export function formatReviews(reviewsArr: any) {
  let obj: any = {};
  let summary = camelcaseKeys({ ...reviewsArr.summary });
  let reviews: any = [];
  for (let rev of reviewsArr.reviews) {
    delete rev.review_items;
    delete rev._id;
    reviews.push(camelcaseKeys(rev));
  }

  obj.summary = summary;
  obj.reviews = reviews;
  return obj;
}

export function formatWithCommas(num?: number, prefix = ''): string {
  if (num) {
    const str = `${num}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return `${prefix}${str}`;
  }
  return '';
}

export function getElementsAfter(
  element: string,
  elements: string[]
): string[] {
  const index = elements.indexOf(element);
  const itemsbefore = elements.slice(0, index);
  return [...elements.slice(index + 1)];
}

export function convertTime12to24(time12h: string) {
  if (!time12h) return '';
  const [time, modifier] = time12h.split(' ');

  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (modifier === 'PM') {
    hours = `${parseInt(hours, 10) + 12}`;
  }

  return `${hours}:${minutes}`;
}

export function getAuthHeader(
  header = {}
): { headers: { Authorization?: string; [other: string]: any } } | {} {
  // return authorization header with jwt token
  const item = localStorage.getItem('flayr_user') || '{}';
  const user = JSON.parse(item);

  if (user && user.token) {
    return {
      headers: {
        ...{ 'Content-Type': 'application/json' },
        ...header,
        Authorization: 'Bearer ' + user.token,
      },
    };
  } else {
    return { headers: { ...header } };
  }
}

export function getLocalStorageItem(): any {
  const item = localStorage.getItem('flayr_user') || '{}';
  if (item) {
    return JSON.parse(item) || '';
  }
  return {};
}

export async function setLocalStorageItem(item: any = {}): Promise<any> {
  let currentItem: any = localStorage.getItem('flayr_user');
  currentItem = currentItem ? JSON.parse(currentItem) : {};
  const newItem = { ...currentItem, ...item };
  return await localStorage.setItem('flayr_user', JSON.stringify(newItem));
}

export function formatSecondsToReadable(seconds: number): string {
  if (seconds < 60) {
    return 'less than a minute';
  }

  const minuteCount = 60;
  const hourCount = minuteCount * 60;
  const dayCount = hourCount * 24;

  const days = Math.floor(seconds / dayCount);
  if (days) {
    return addTextToNumber(days, 'day');
  }
  const hours = Math.floor((seconds % dayCount) / hourCount);
  if (hours) {
    return addTextToNumber(hours, 'hour');
  }
  const minutes = Math.floor(((seconds % dayCount) % hourCount) / minuteCount);
  if (minutes) {
    return addTextToNumber(minutes, 'minute');
  }
  return '';
}

function addTextToNumber(number: number, text: string): string {
  return `${number} ${text}${number > 1 ? 's' : ''}`;
}

export function parseQueryString(qs: string) {
  if (qs[0] === '?') {
    qs = qs.slice(1);
  }

  return qs.split('&').reduce((acc, cur) => {
    const [key, value] = cur.split('=');
    acc[key] = value;
    return acc;
  }, {});
}

export async function performXHRAction(fn: (item?: any) => any, data?: any) {
  try {
    return await fn(data);
  } catch (err) {
    console.error(err);
  }
}

export function convertToSeconds(number: number, duration = 'minute') {
  if (duration === 'minute') {
    return number * 60;
  }
  if (duration === 'hour') {
    return number * 60 * 60;
  }
  return number;
}

export function humanize(str) {
  let frags = str.split('-');
  for (let i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
}

export function getTimeAmPm(time: string): string {
  const item = timeMapping.find((el) => el.value === time);
  let returnItem = '';
  if (item) {
    returnItem = item.label || '';
  } else {
    returnItem = time;
  }
  return returnItem;
}

export function isEmailValid(email) {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}

export function isMobile() {
  return window.innerWidth <= 768;
}

function getDeviceType() {
  var check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window['opera']);
  return check ? 'mobile' : 'desktop';
}

function getOperatingSystem() {
  if (getDeviceType() === 'desktop') {
    let OSName = 'Unknown OS';
    if (navigator.appVersion.indexOf('Win') != -1) OSName = 'Windows';
    if (navigator.appVersion.indexOf('Mac') != -1) OSName = 'MacOS';
    if (navigator.appVersion.indexOf('X11') != -1) OSName = 'UNIX';
    if (navigator.appVersion.indexOf('Linux') != -1) OSName = 'Linux';
    return OSName;
  } else {
    let userAgent = navigator.userAgent || navigator.vendor || window['opera'];
    if (
      userAgent.match(/iPad/i) ||
      userAgent.match(/iPhone/i) ||
      userAgent.match(/iPod/i)
    ) {
      return 'iOS';
    } else if (userAgent.match(/Android/i)) {
      return 'Android';
    } else {
      return 'unknown';
    }
  }
}

export function formatSmsLinkForDevice(num: string, body: string): string {
  const OS = getOperatingSystem();
  if (OS === 'MacOS' || OS === 'iOS') {
    return `sms://${num}/&body=${encodeURI(body)}`;
  }
  return `sms://${num}/?body=${encodeURI(body)}`;
}

export function getRequestStage(
  quote: Quote,
  provider?: Stylist
): RequestStage {
  const request = provider
    ? provider.request
    : { stage: 0, offerExpiryDate: null };

  // if booking is confirmed
  const bookedFromRequest = request && request.stage === RequestStage.booked;
  if (quote.shopify_id && bookedFromRequest) {
    return RequestStage.booked;
  }

  // if booked out but the offer is not booked
  if (provider && provider.bookedOut) {
    return RequestStage.declined;
  }

  // if no quoteid then there's no quote
  if (!quote || !quote.shopify_id) {
    return RequestStage.noQuote;
  }

  // if requeststage is 0
  if (!request || (request.stage !== 0 && !request.stage)) {
    return RequestStage.noRequest;
  }
  if (request && request.stage === RequestStage.offered) {
    const expirydate = new Date(request.offerExpiryDate!);
    const isExpired = new Date(Date.now()) > expirydate;
    if (isExpired) {
      return RequestStage.expired;
    }
  }
  if (quote.shopify_id && request && request.stage === 10) {
    return RequestStage.requested;
  } else if (
    quote.shopify_id &&
    request &&
    request.stage === RequestStage.offered
  ) {
    return RequestStage.offered;
  } else if (
    quote.shopify_id &&
    request &&
    request.stage === RequestStage.declined
  ) {
    return RequestStage.declined;
  } else {
    return RequestStage.expired;
  }
}

export function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function parseDate(date) {
  return date ? new Date(date) : new Date();
}
export function getPaymentDate(request) {
  if (!request.paymentOption || !request.quote_details) return '';
  let daysToMinus = request.paymentOption.split('day');
  daysToMinus = parseInt(daysToMinus);
  if (isNaN(daysToMinus)) return '';
  let eventDate: any = new Date(request.quote_details.event_date);
  let subtracted = subDays(eventDate, daysToMinus);
  eventDate = format(subtracted, 'YYYY-MM-DD');
  return eventDate;
}

export function getPaymentWording(request) {
  if (!request.paymentOption || !request.quote_details) return '';
  let amount = request.payment / 2;
  if (!getPaymentDate(request)) return '';
  let paymentDate = new Date(getPaymentDate(request)).toDateString();
  return `$${amount} due by ${paymentDate}, remaining $${amount} on the day`;
}
