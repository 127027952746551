import * as React from 'react';
import classnames from 'classnames';

interface IColumnProps {
  size?: string;
  mobile?: string;
  tablet?: string;
  desktop?: string;
  widescreen?: string;
  fullhd?: string;
  ultrahd?: string;
  className?: string;
  paddingless?: boolean;
  style?: React.CSSProperties;
}
const Column: React.FC<IColumnProps> = ({
  size,
  mobile,
  tablet,
  desktop,
  widescreen,
  fullhd,
  ultrahd,
  paddingless,
  children,
  className,
  style,
}) => {
  return (
    <div
      className={classnames('column', className, {
        [`is-${size}`]: size,
        [`is-${mobile}-mobile`]: mobile,
        [`is-${tablet}-tablet`]: tablet,
        [`is-${desktop}-desktop`]: desktop,
        [`is-${widescreen}-widescreen`]: widescreen,
        [`is-${fullhd}-fullhd`]: fullhd,
        [`is-${ultrahd}-ultrahd`]: ultrahd,
        'is-paddingless': paddingless,
      })}
      style={style}
    >
      {children}
    </div>
  );
};

export default Column;
