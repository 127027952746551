import React, { useState, useEffect } from 'react';
import { Stylist } from '../../../types/stylist/stylist';
import { Formik, ErrorMessage } from 'formik';
import { isEmailValid, getRequestStage } from '../../../utils';
import { Quote } from '../../../types/quote/quote';
import Button from '../../../shared-components/Button/Button';
import CheckLoader from '../../../shared-components/CheckLoader/CheckLoader';
import FormField from '../../../shared-components/FormField/FormField';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { RequestStage } from '../../../types/request/request';
import NoObligation from '../../../shared-components/NoObligation/NoObligation';
import { timeMapping } from '../../../static-data/timeMapping';
import classnames from 'classnames';
import { serviceBasedContent } from '../../../static-data/contentMapping';

interface RequestInfoProps {
  onCheckAvail: (
    name: string,
    mobile: string,
    email: string,
    event: string,
    event_time: string
  ) => void;
  quote: Quote;
  checkAvailLoading: boolean;
  provider: Stylist;
  onBack?: () => void;
}

const RequestInfo: React.FunctionComponent<RequestInfoProps> = ({
  onCheckAvail,
  quote,
  checkAvailLoading,
  provider,
  onBack,
}) => {
  const [values, setValues] = useState({
    name: localStorage.getItem('name') || '',
    mobile: localStorage.getItem('mobile') || '',
    email: localStorage.getItem('email') || '',
    event_time: quote.event_time,
    event: localStorage.getItem('event') || '',
  });

  const [validation, setValidation] = useState({
    name: '',
    mobile: '',
    email: '',
    event_time: '',
    event: '',
  });

  function getLabelFor(field) {
    return serviceBasedContent[quote.type || 'makeup'].requestForm[field].label;
  }

  function getErrorMessage(field, type = 'required') {
    return serviceBasedContent[quote.type || 'makeup'].requestForm[field]
      .errorMessage[type];
  }

  const formFields = [
    {
      name: 'event_time',
      type: 'select',
      placeholder: getLabelFor('event_time'),
      options: timeMapping,
      id: 'eventTime',
    },
    {
      name: 'name',
      type: 'text',
      placeholder: getLabelFor('name'),
      id: 'name',
    },
    {
      name: 'email',
      type: 'email',
      placeholder: getLabelFor('email'),
      id: 'email',
    },
    {
      name: 'event',
      type: 'text',
      placeholder: getLabelFor('event'),
      id: 'event',
    },
    {
      name: 'mobile',
      type: 'mobileInput',
      placeholder: getLabelFor('mobile'),
      id: 'mobile',
    },
  ];

  useEffect(() => {
    localStorage.setItem('name', values.name);
    localStorage.setItem('mobile', values.mobile);
    localStorage.setItem('email', values.email);
    localStorage.setItem('event', values.event);
  }, [values]);

  const [formTouched, setFormTouched] = useState<boolean>(
    !!(
      localStorage.getItem('name') &&
      localStorage.getItem('mobile') &&
      localStorage.getItem('email') &&
      quote.event_time &&
      quote.event_desc
    )
  );

  const onSubmit = e => {
    e.preventDefault();
    if (Object.values(validateForm(values, true)).some(val => !!val)) {
      return;
    }
    const { name, email, mobile, event, event_time = '' } = values;
    localStorage.setItem('name', name || '');
    localStorage.setItem('email', email || '');
    localStorage.setItem('mobile', mobile || '');
    localStorage.setItem('event', event || '');
    onCheckAvail(name, mobile, email, event, event_time);
  };

  function validateForm(val, validateOnly = false) {
    let errors: {
      name: string;
      email: string;
      mobile: string;
      event_time: string;
      event: string;
    } = { name: '', email: '', mobile: '', event: '', event_time: '' };

    if (!validateOnly) {
      setValues({ ...val });
    }
    if (!formTouched) {
      setFormTouched(true);
    }
    Object.keys(val).map(key => {
      if (!val[key]) {
        errors[key] = getErrorMessage(key);
        return;
      }
      if (key === 'name' && !/^[a-zA-Z\s]+$/.test(val.name)) {
        return (errors.name = 'Number or symbols are not allowed');
      }
      if (key === 'email' && !isEmailValid(val.email)) {
        return (errors.email = 'Enter valid email');
      }
      if (key === 'mobile' && !isValidPhoneNumber(val.mobile)) {
        return (errors.mobile = 'Enter valid mobile');
      }
    });
    setValidation(errors);
    return errors;
  }

  function handleInputChange({ target }) {
    const { name, value } = target;
    const newValue = { ...values, ...{ [name]: value } };
    validateForm(newValue);
  }

  function handleBlur() {
    validateForm(values, true);
  }

  return (
    <>
      {(checkAvailLoading ||
        !provider.request ||
        getRequestStage(quote, provider) === RequestStage.expired) && (
        <>
          <div className="has-margin-bottom-4">
            <NoObligation />
          </div>
          {/* <p>
            <b>Your contact details</b>
          </p>
          <div className="has-margin-bottom-5">
            <small>so we can get back to you with an appointment time</small>
          </div> */}

          <form onSubmit={onSubmit}>
            {formFields.map(field => (
              <FormField
                hasError={
                  validation[field.name] || // if all the fields are present except for event_time, highlight it
                  (field.name === 'event_time' &&
                    !values.event_time &&
                    Object.keys(values).some(key => !!values[key]))
                }
                key={field.name}
                message={validation[field.name]}
              >
                <div className="is-flex is-vend">
                  <div className="is-flex-fill">
                    {(field.type === 'text' || field.type === 'email') && (
                      <input
                        placeholder={field.placeholder}
                        className="input"
                        type={field.type}
                        name={field.name}
                        value={values[field.name]}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        id={field.id}
                      />
                    )}

                    {field.type === 'select' && (
                      <div className="select is-fullwidth">
                        <select
                          name={field.name}
                          id={field.name}
                          value={values[field.name]}
                          onChange={handleInputChange}
                          onBlur={handleBlur}
                          className={classnames({
                            'has-text-grey-light': !values[field.name],
                          })}
                        >
                          <option value="" disabled>
                            {field.placeholder}
                          </option>
                          {field.options &&
                            field.options.map(opt => (
                              <option value={opt.value} key={opt.value}>
                                {opt.label}
                              </option>
                            ))}
                        </select>
                      </div>
                    )}

                    {field.type === 'mobileInput' && (
                      <>
                        <small className="is-block has-margin-top-4 has-line-height-3">
                          Your mobile is only used to notify you if the{' '}
                          {serviceBasedContent[quote.type || 'makeup'].stylist}{' '}
                          is available.
                        </small>
                        <PhoneInput
                          placeholder={field.placeholder}
                          value={values[field.name]}
                          onChange={ph =>
                            handleInputChange({
                              target: { name: field.name, value: ph },
                            })
                          }
                          country="AU"
                          countries={['AU']}
                          id="phoneInput"
                        />
                      </>
                    )}
                  </div>
                  <div
                    className={classnames(
                      'has-margin-left-3 has-padding-bottom-2',
                      {
                        'is-invisible':
                          !values[field.name] || !!validation[field.name],
                      }
                    )}
                  >
                    <i className="far fa-check has-text-success has-margin-bottom-3" />
                  </div>
                </div>
              </FormField>
            ))}
            <Button
              color="gradient-1"
              isLoading={checkAvailLoading}
              isRounded
              isFullwidth
              type="submit"
            >
              Check Availability
            </Button>
          </form>
          {/* <Formik
            initialValues={{
              name: values.name,
              mobile: values.mobile,
              email: values.email,
              event: values.event,
              event_time: values.event_time,
            }}
            validate={validateForm}
            onSubmit={onSubmit}
          >
            {({ errors, values, handleChange, handleBlur, touched }) => (
              <form onSubmit={onSubmit}>
                {formFields.map(field => (
                  <FormField
                    hasError={
                      (touched[field.name] && errors[field.name]) ||
                      (field.name === 'mobile' && errors.mobile) ||
                      // if all the fields are present except for event_time, highlight it
                      (field.name === 'event_time' &&
                        !values.event_time &&
                        Object.keys(values).some(key => !!values[key]))
                    }
                    key={field.name}
                    message={
                      (touched[field.name] && errors[field.name]) ||
                      (field.name === 'mobile' && errors.mobile)
                    }
                  >
                    {(field.type === 'text' || field.type === 'email') && (
                      <input
                        placeholder={field.placeholder}
                        className="input"
                        type={field.type}
                        name={field.name}
                        value={values[field.name]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id={field.id}
                      />
                    )}

                    {field.type === 'select' && (
                      <div className="select is-fullwidth">
                        <select
                          name={field.name}
                          id={field.name}
                          value={values[field.name]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="" disabled>
                            {field.placeholder}
                          </option>
                          {field.options &&
                            field.options.map(opt => (
                              <option value={opt.value} key={opt.value}>
                                {opt.label}
                              </option>
                            ))}
                        </select>
                      </div>
                    )}

                    {field.type === 'mobileInput' && (
                      <>
                        <PhoneInput
                          placeholder={field.placeholder}
                          value={values[field.name]}
                          onChange={ph =>
                            handleChange({
                              target: { name: field.name, value: ph },
                            })
                          }
                          country="AU"
                          countries={['AU']}
                        />
                      </>
                    )}
                  </FormField>
                ))}
                <div className="has-margin-bottom-3">
                  <small className="is-block has-margin-top-4 has-line-height-3">
                    Your mobile is only used to notify you if the stylist is
                    available.
                  </small>
                </div>
                <Button
                  color="gradient-1"
                  isLoading={checkAvailLoading}
                  isRounded
                  isFullwidth
                  type="submit"
                >
                  Check Availability
                </Button>
              </form>
            )}
          </Formik> */}
        </>
      )}
      {!checkAvailLoading &&
        provider.request &&
        provider.request.stage === RequestStage.requested && (
          <div className="level has-margin-top-5">
            <div className="is-flex level-item is-column">
              <div className="has-margin-bottom-4">
                <CheckLoader />
              </div>
              <div className="has-text-centered has-margin-bottom-5">
                Availability check in progress. We will email and text you once
                the artist responds.
              </div>

              <div className="has-text-centered">
                <p className="has-margin-bottom-3 has-text-dark-50">
                  <small>
                    most customers request 3 additional{' '}
                    {serviceBasedContent[quote.type || 'makeup'].stylist}s.
                  </small>
                </p>
                <Button
                  isRounded
                  color="gradient-1"
                  onClick={onBack}
                  className="is-capitalized"
                >
                  Request More{' '}
                  {serviceBasedContent[quote.type || 'makeup'].stylist}s
                </Button>
              </div>
            </div>
          </div>
        )}
    </>
  );
};
export default RequestInfo;
