import React from 'react';
import { personService } from '../../../types/quote/personServiceMapping';
import { serviceNameMappingHaircut } from '../../../static-data/personServiceMapping';

interface PersonServiceDisplayProps {
  type: string;
  services: Array<string[]>;
}

const PersonServiceDisplay: React.FC<PersonServiceDisplayProps> = ({
  type,
  services,
}) => {
  return (
    <>
      {(type === 'makeup' || !type) &&
        services.map(([person, service], idx) => (
          <div
            className="columns is-marginless is-mobile is-size-14p"
            key={person + idx}
          >
            <div className="column is-1 is-paddingless has-margin-right-4" />
            <div className="is-lowercase column is-paddingless">
              <span className="has-text-weight-medium">
                {personService[person]}{' '}
              </span>
              <span>{personService[service]}</span>
            </div>
          </div>
        ))}
      {type === 'haircut' &&
        services.map(([name, service], idx) => (
          <div
            className="columns is-marginless is-mobile is-size-14p"
            key={name + idx}
          >
            <div className="column is-1 is-paddingless has-margin-right-4" />
            <div className="is-lowercase column is-paddingless is-capitalized">
              {/* <span className="has-text-weight-medium is-capitalized">
                {name}{' '}
              </span> */}
              <span className="is-capitalized">
                {serviceNameMappingHaircut[service]}
              </span>
            </div>
          </div>
        ))}
    </>
  );
};

export default PersonServiceDisplay;
