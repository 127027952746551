interface IServiceBasedContent {
  stylist: string;
  service: string;
  bonus: string;
  navbarLabel: string;
  inclusions: string[];
  requestForm: any;
}
export const serviceBasedContent: {
  [service: string]: IServiceBasedContent;
} = {
  makeup: {
    stylist: 'stylist',
    service: 'makeup',
    bonus: 'free lashes',
    navbarLabel: 'all stylists',
    inclusions: [
      'The price shown covers everyone in the quote.',
      'Includes travel if you have requested a mobile service. No travel charges on the day.',
      'Parking is not included for mobile services, please arrange parking or reimburse the stylist on the day',
    ],
    requestForm: {
      event_time: {
        label: 'To be Ready by',
        errorMessage: {
          required: 'Ready by time is required',
        },
      },
      name: {
        label: 'Your Name*',
        errorMessage: {
          required: 'Name is required',
        },
      },
      email: {
        label: 'Your Email*',
        errorMessage: {
          required: 'Email is required',
        },
      },
      event: {
        label: 'Event*',
        errorMessage: {
          required: 'Event is required',
        },
      },
      mobile: {
        label: 'Your Mobile*',
        errorMessage: {
          required: 'Mobile is required',
        },
      },
    },
  },
  haircut: {
    stylist: 'hairdresser',
    service: 'hair cutting & colouring',
    bonus: '',
    navbarLabel: 'all hairdressers',
    inclusions: [
      'The price shown covers everyone in the quote.',
      'Includes travel if you have requested a mobile service. No travel charges on the day.',
      'Parking is not included for mobile services, please arrange parking or reimburse the hairdresser on the day',
    ],
    requestForm: {
      event_time: {
        label: 'Start Time',
        errorMessage: {
          required: 'Start time is required',
        },
      },
      name: {
        label: 'Your Name*',
        errorMessage: {
          required: 'Name is required',
        },
      },
      email: {
        label: 'Your Email*',
        errorMessage: {
          required: 'Email is required',
        },
      },
      event: {
        label: 'Length of Hair',
        errorMessage: {
          required: 'Length of hair is required',
        },
      },
      mobile: {
        label: 'Your Mobile*',
        errorMessage: {
          required: 'Mobile is required',
        },
      },
    },
  },
};
