import React from 'react';
import classnames from 'classnames';

interface ReasonsToBookProps {
  mode?: 'content' | 'sidebar';
  type?: string | undefined
}

const reasons: any[] = [
  {
    title: 'Deposit now, rest later',
    desc: 'Book with just a deposit with the rest payable on the day',
  },
  {
    title: 'Verified Reviews',
    desc: 'Read reviews from other customers',
  },
  {
    title: 'Fixed Pricing',
    desc: 'Price shown covers everyone in the search',
  },
];

const haircutReasons: any[] = [
  {
    title: 'Book a specific hairdresser',
    desc: 'See the work of the hairdresser and book them in',
  },
  {
    title: 'Verified Reviews',
    desc: 'Read reviews from other customers',
  },
  {
    title: 'Fixed Pricing',
    desc: 'Price shown covers everyone in the search',
  },
]


const ReasonsToBook: React.FunctionComponent<ReasonsToBookProps> = ({
  mode = 'sidebar',
  type
}) => {

  const renderReasons = (reasons: any[]) => {
    return reasons.map((reason, key) => (
      <div
        key={key}
        className={classnames(
          'columns is-marginless has-margin-bottom-3 is-size-14p',
          {
            'column is-paddingless': mode === 'content',
          }
        )}
      >
        <div className="has-margin-right-3 column is-paddingless is-narrow">
          <i className="far fa-check has-text-cyan" />
          {/* <span className="fa-stack">
      <i className="fas fa-circle fa-stack-2x has-text-" />
    </span> */}
        </div>
        <div
          className={classnames('column is-paddingless', {
            'has-border-bottom-25 has-padding-bottom-4':
              key + 1 !== reasons.length && mode === 'sidebar',
            'has-padding-right-4':
              key + 1 !== reasons.length && mode === 'content',
          })}
        >
          <div className="has-text-weight-medium is-capitalized">
            {reason.title}
          </div>
          <p className="is-size-14p has-line-height-3">{reason.desc}</p>
        </div>
      </div>
    ))
  }

  return (
    <div className="has-padding-y-4 has-padding-x-4">
      <header className="is-size-6 has-margin-bottom-4 has-has-line-height-2 has-text-weight-medium">
        Great reasons to book with Flayr
      </header>

      <div
        className={classnames({
          'columns has-margin-top-1 has-margin-bottom-3': mode === 'content',
        })}
      >
        {(!type || type === 'makeup') &&
          renderReasons(reasons)
        }
        {type === 'haircut' &&
          renderReasons(haircutReasons)
        }
      </div>
    </div>
  );
};
export default ReasonsToBook;
