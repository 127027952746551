import { REQUEST_SET_REQUESTS, REQUEST_SET_REQUEST_ID, SET_STYLIST_REQUEST, SET_TIME_CHANGE } from "./action-types";
import { Request, TimeChange } from '../types/request/request';

export function setRequests(requests: Request[]) {
  return {
    type: REQUEST_SET_REQUESTS,
    payload: requests,
  };
}

export function setCurrentRequestId(payload: string) {
  return {
    type: REQUEST_SET_REQUEST_ID,
    payload,
  };
}


export function setStylistRequest(payload: Request) {
  return {
    type: SET_STYLIST_REQUEST,
    payload,
  }
}

export function setTimeChange(payload: TimeChange) {
  return {
    type: SET_TIME_CHANGE,
    payload
  }
}