import React, { useState } from 'react';
import ProfileContent from '../../../shared-components/ProfileContent/ProfileContent';
import ReadMore from '../../../shared-components/ReadMore/ReadMore';
import Accordion from '../../../shared-components/Accordion/Accordion';
import AccordionItem from '../../../shared-components/Accordion/AccordionItem/AccordionItem';
import { isMobile } from '../../../utils';

interface KitProps {
  kit: string;
  open?: boolean;
  onCollapseChange?: (e: any) => void;
}

const Kit: React.FunctionComponent<KitProps> = ({
  kit,
  open,
  onCollapseChange,
}) => {
  if (!kit) {
    return null;
  }
  return (
    <Accordion name="kit" onChange={onCollapseChange} open={open}>
      <AccordionItem heading="My Kit" id="kit">
        <ReadMore text={kit} max={isMobile() ? 130 : 360} />
      </AccordionItem>
    </Accordion>
  );
};
export default Kit;
