import React, { RefObject } from 'react';
import * as ReactDom from 'react-dom';

interface PortalProps {}

const Portal: React.FunctionComponent<PortalProps> = ({ children }) => {
  const [element, setElement] = React.useState<any>();

  React.useEffect(() => {
    const el: HTMLDivElement = document.createElement('div');
    el.classList.add('Portal');
    document.body.appendChild(el);
    setElement(el);
    return () => {
      document.body.removeChild(el);
    };
  }, []);

  if (!element) {
    return null;
  }
  return ReactDom.createPortal(children, element);
};
export default Portal;
