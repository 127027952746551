import React, { useState } from 'react';
import { Quote } from '../../types/quote/quote';
import { format, parse } from 'date-fns';
import { getTimeAmPm } from '../../utils';
import { Stylist } from '../../types/stylist/stylist';
import { RequestStage, TimeChange } from '../../types/request/request';
import Button from '../Button/Button';
import ChangeTimeModal from '../../pages/Profile/ChangeTimeModal/ChangeTimeModal';
import CheckLoader from '../CheckLoader/CheckLoader';
import { Spinner } from '../Spinner/Spinner';
import { serviceBasedContent } from '../../static-data/contentMapping';
import PersonServiceDisplay from './PersonServiceDisplay/PersonServiceDisplay';

interface QuoteDisplayProps {
  quote: Quote;
  type?: string;
  provider: Stylist;
  requestStage?: RequestStage;
  onTimeSubmit?: (timeToChange: string, time: string, reason: string) => void;
  timeChange?: TimeChange;
  timeChangeLoading?: boolean;
  timeChangeModalOpen?: boolean;
  setTimeChangeModalOpen?: (open: boolean) => void;
  serviceType?: string;
}

const QuoteDisplay: React.FunctionComponent<QuoteDisplayProps> = ({
  quote,
  type,
  provider,
  requestStage,
  onTimeSubmit,
  timeChange,
  timeChangeLoading,
  timeChangeModalOpen,
  setTimeChangeModalOpen,
  serviceType,
}) => {
  const onNewTimeClick = () => {
    if (setTimeChangeModalOpen) setTimeChangeModalOpen(true);
  };
  return (
    <section>
      <h4 className="is-size-16p">
        {requestStage === RequestStage.offered ? 'Appointment' : 'Quote'}{' '}
        Details
      </h4>

      <div className="has-padding-3 is-size-16p is-family-secondary">
        {/* type */}

        {type === 'mobile' && (
          <div className="columns is-mobile is-marginless is-vcentered">
            <span className="column is-1 is-paddingless has-text-dark-50 has-margin-right-4 has-text-centered">
              <i className={`fal ${'fa-car-alt has-margin-top-2 is-size-5'}`} />
            </span>
            <div className="column is-paddingless">
              <span>
                Mobile.{' '}
                <span className="is-capitalized">
                  {serviceBasedContent[quote.type || 'makeup'].stylist}
                </span>{' '}
                comes to you
              </span>
            </div>
          </div>
        )}

        {/* location */}
        {type === 'home' && (
          <div className="columns is-mobile is-marginless is-vcentered has-margin-n-top-1 has-margin-bottom-3">
            <span className="column is-1 is-paddingless has-text-dark-50 has-margin-right-4 has-text-centered">
              <i className="fal fa-store-alt" />
            </span>
            <div className="column is-paddingless">
              {type === 'home' && (
                <span>
                  Home Studio. You visit the{' '}
                  {serviceBasedContent[quote.type || 'makeup'].stylist} in{' '}
                  {provider.homeStudioAddress
                    ? provider.homeStudioAddress.suburb
                    : provider.suburb}
                  ,{' '}
                  {provider.homeStudioAddress
                    ? provider.homeStudioAddress.state
                    : provider.state}{' '}
                  {provider.distance &&
                    ` - ${Math.round(provider.distance / 1000)}km`}
                </span>
              )}
            </div>
          </div>
        )}

        {/* location */}
        {type === 'mobile' && (
          <div className="columns is-mobile is-marginless is-vcentered has-margin-n-top-1 has-margin-bottom-3">
            <span className="column is-1 is-paddingless has-text-dark-50 has-margin-right-4 has-text-centered">
              <i className="fal fa-map-marker-alt" />
            </span>
            <div className="column is-paddingless">
              {type === 'mobile' && (
                <span>
                  {quote.display_suburb || quote.suburb}, {quote.state}
                </span>
              )}
            </div>
          </div>
        )}

        {/* date  */}
        <div className="columns is-mobile is-marginless is-vcentered has-margin-n-top-2">
          <span className="column is-1 is-paddingless has-text-dark-50 has-margin-right-4 has-text-centered">
            <i className="fal fa-calendar-day" />
          </span>
          <div className="column is-paddingless">
            {format(
              quote.event_date ? new Date(quote.event_date) : new Date(),
              'ddd DD MMM YYYY'
            )}
          </div>
        </div>

        {/* time  */}
        {!!quote.event_time && (
          <>
            <div className="columns is-mobile is-marginless is-vcentered has-margin-top-2">
              <span className="column is-1 is-paddingless has-text-dark-50 has-margin-right-4 has-text-centered">
                <i className="fal fa-clock" />
              </span>

              {!!(
                (requestStage && requestStage <= RequestStage.requested) ||
                !provider.request
              ) && (
                  <div className="column is-paddingless">
                    <>
                      {(serviceType === 'makeup' || !serviceType) && (
                        <>To be ready by </>
                      )}
                      {serviceType === 'haircut' && <>Appointment Time: </>}

                      {quote.event_time ? getTimeAmPm(quote.event_time) : ''}
                    </>
                  </div>
                )}
              {!!(
                requestStage &&
                requestStage > RequestStage.requested &&
                provider.request
              ) && (
                  <div className="column is-paddingless">
                    Start Time: {provider.request.startTime} <br />
                    Finish Time: {provider.request.finishTime}
                  </div>
                )}
            </div>
          </>
        )}
        {provider.request &&
          provider.request.hasAdditionalTimeSpots &&
          provider.isActive !== false && (
            <div className="columns is-mobile is-marginless is-vcentered has-margin-n-top-2">
              <span className="column is-1 is-paddingless has-text-dark-50 has-margin-right-4 has-text-centered" />

              <div className="has-margin-right-4 has-margin-top-3">
                {!!(
                  requestStage &&
                  requestStage === RequestStage.offered &&
                  provider.request.hasAdditionalTimeSpots &&
                  !timeChange &&
                  !timeChangeLoading
                ) && (
                    <Button
                      isRounded
                      size="small"
                      onClick={onNewTimeClick}
                      isOutlined
                      color="gradient-1"
                      outlineSize={2}
                    >
                      <span>
                        <span className="has-text-gradient-1-inverted">
                          Request new time
                      </span>
                      </span>
                    </Button>
                  )}
                {!!(
                  requestStage &&
                  requestStage === RequestStage.offered &&
                  timeChange &&
                  !timeChangeLoading
                ) && (
                    <div className="level has-margin-y-4">
                      <div className="level-item">
                        <div className="is-flex is-column is-vcentered">
                          <CheckLoader size={45} />
                          <span className="has-text-dark-100 has-text-weight-medium">
                            <small>Time change requested</small>
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                {timeChangeLoading && (
                  <Spinner
                    isShowing={true}
                    color="black"
                    size={30}
                    message="Requesting new time"
                  />
                )}
              </div>
            </div>
          )}

        {provider.request &&
          !provider.request.hasAdditionalTimeSpots &&
          provider.request.stage === RequestStage.offered && (
            <div className="columns is-mobile is-marginless is-vcentered has-margin-n-top-2">
              <span className="column is-1 is-paddingless has-text-dark-50 has-margin-right-4 has-text-centered" />
              <small className="has-text-weight-bold is-family-primary is-size-7 has-text-danger">
                This stylist only has this time slot.
              </small>
            </div>
          )}

        {/* number of people */}
        <div className="columns is-mobile is-marginless is-vcentered">
          <span className="column is-1 is-paddingless has-text-dark-50 has-margin-right-4 has-text-centered">
            {quote.num_of_people === 1 && <i className="fal fa-user" />}
            {quote.num_of_people === 2 && <i className="far fa-user-friends" />}
            {quote.num_of_people && quote.num_of_people > 2 && (
              <i className="fal fa-users" />
            )}
          </span>
          <div className="column is-paddingless">
            {quote.num_of_people}{' '}
            {quote.num_of_people && quote.num_of_people > 1
              ? 'people'
              : 'person'}
          </div>
        </div>
        <PersonServiceDisplay
          type={quote.type || ''}
          services={quote.person_service_list}
        />
      </div>
      <ChangeTimeModal
        open={timeChangeModalOpen || false}
        onClose={() => {
          if (setTimeChangeModalOpen) setTimeChangeModalOpen(false);
        }}
        onTimeSubmit={onTimeSubmit ? onTimeSubmit : () => { }}
      />
    </section>
  );
};
export default QuoteDisplay;
