import React from 'react';
import Calendar from '../../Calender/Calendar';
import DateTimePicker from '../DateTimePicker.tsx/DateTimePicker';
import FormField from '../../FormField/FormField';
import moment from 'moment';
interface CalendarTimeFormProps {
  dateString: string;
  timeString: string;
  onCalendarDateSelect: (date: string) => void;
  calendarMode: 'time' | 'date';
  setCalendarMode: (mode: 'date' | 'time') => void;
  onTimeChange: (time: string) => void;
}

const CalendarTimeForm: React.FunctionComponent<CalendarTimeFormProps> = ({
  calendarMode,
  onCalendarDateSelect,
  dateString,
  setCalendarMode,
  timeString,
  onTimeChange,
}) => {
  function handleDateSelect(date) {
    let isoDate = moment(new Date(date).toISOString()).format('YYYY-MM-DD');
    onCalendarDateSelect(isoDate);
  }

  return (
    <div>
      {calendarMode === 'date' && (
        <FormField labelClass="has-text-weight-bold is-family-primary is-size-7">
          <Calendar date={dateString} onSelect={handleDateSelect} />
        </FormField>
      )}

      {calendarMode === 'time' && (
        <DateTimePicker
          timeString={timeString}
          setCalendarMode={setCalendarMode}
          dateString={dateString}
          onTimeChange={onTimeChange}
        />
      )}
    </div>
  );
};

export default CalendarTimeForm;
