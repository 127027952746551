import { Person, Service } from '../quote/person-service';
import { AddOn } from '../add-on';

export interface RequestItem {
  requestWorth: number;
  average_response_time: number;
  requests: Request[];
}

export interface AppRequests {
  requests: Array<Request>;
  currentRequestId: string;
}

export interface Request {
  id?: string;
  requestKey?: string;
  searchId: string;
  requestId: string;
  providerId: string;
  cost: number;
  payment: number;
  take: number;
  stage: RequestStage; //0 declined, 10 requested, 20 offered, 30 booked,
  dateCreated: string;
  quoteDetails?: QuoteDetails;
  gigId?: string;
  forHomeStudio?: boolean;
  hasAdditionalTimeSpots?: boolean;
  timeChange?: TimeChange;
  startTime?: string;
  finishTime?: string;
  instantOffer?: boolean;
  addonsOffered: AddOn[];
  offerExpiryDate?: string;
}

export interface TimeChange {
  id_of_request: string;
  time_to_change: 'start' | 'finish';
  time: string;
  reason_for_change: string;
}
export interface QuoteDetails {
  state: string;
  suburb: string;
  postcode: string;
  event_date: string;
  event_time: string;
  event_desc: string;
  num_of_people: number;
  person_service_list: [[Person, Service]];
  look_desc: string;
  gig_id: string;
  country: string;
}

export enum RequestStage {
  declined = 0,
  requested = 10,
  offered = 20,
  booked = 30,
  expired = 40,
  noQuote = 'noQuote',
  noRequest = 'noRequest',
}

export interface ResponseItem {
  type: 'accept' | 'decline';
  reason?: string;
  startTime?: string;
  endTime?: string;
  hasOtherSpots?: boolean;
}
