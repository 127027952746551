import 'dotenv/config';

function isProduction(): boolean {
  return !(!process.env.NODE_ENV || process.env.NODE_ENV === 'development');
}
const BASE_SERVER_URL = process.env.REACT_APP_API_URL;
const CHECKOUT_URL = process.env.REACT_APP_CHECKOUT_URL;
const THANK_YOU_URL = process.env.REACT_APP_THANK_YOU_URL;
const AUTH0_DOMAIN = 'login.flayr.io';
const AUTH0_CLIENT_ID = 'DIVYhHP5RQiV5j8DGHjE7rAkDz4s6ZPa';
const AUTH0_AUDIENCE = 'https://flayr.au.auth0.com/userinfo';
const AUTH0_REDIRECT_URL = isProduction()
  ? 'https://me.flayr.io/callback'
  : 'http://localhost:3000/callback';
const INSTAGRAM_CLIENT_ID = 'a7acd2cd95ba4f63804ef62c803227a2';
const FLAYR_LIFE_API_URL = process.env.REACT_APP_FLAYR_LIFE_API_URL;
const FLAYR_LIFE_SPA_URL = process.env.REACT_APP_FLAYR_LIFE_SPA_URL;

export {
  BASE_SERVER_URL,
  AUTH0_DOMAIN,
  AUTH0_CLIENT_ID,
  AUTH0_AUDIENCE,
  AUTH0_REDIRECT_URL,
  INSTAGRAM_CLIENT_ID,
  CHECKOUT_URL,
  THANK_YOU_URL,
  FLAYR_LIFE_SPA_URL,
  FLAYR_LIFE_API_URL,
};
