import React from 'react';
import './CalendarNav.scss';

interface CalendarNavProps {
  month: string;
  year: string;
  onPrev: () => void;
  onNext: () => void;
}
const CalendarNav: React.FunctionComponent<CalendarNavProps> = props => {
  const { month, year, onPrev, onNext } = props;
  return (
    <nav className="CalendarNav__container">
      <button className="CalendarNav__nav-btn" onClick={onPrev}>
        <div className="fas fa-arrow-left" />
      </button>

      <span className="has-text-secondary has-text-weight-medium has-text-grey-dark">
        {month} {year}
      </span>

      <button className="CalendarNav__nav-btn" onClick={onNext}>
        <i className="fas fa-arrow-right" />
      </button>
    </nav>
  );
};

export default CalendarNav;
