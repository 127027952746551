import React from 'react';
import { IReviewItem, Stylist } from '../../../types/stylist/stylist';
import Avatar, { ConfigProvider } from 'react-avatar';
import { parse, format } from 'date-fns';
import ReadMore from '../../../shared-components/ReadMore/ReadMore';
import { AppStore } from '../../../types/appStore';
import { connect, Provider } from 'react-redux';
import { parseDate } from '../../../utils';

interface CustomerReviewProps {
  review: IReviewItem;
  provider?: Stylist;
}

const CustomerReview: React.FunctionComponent<CustomerReviewProps> = ({
  review,
  provider,
}) => {
  return (
    <div className="has-margin-bottom-4">
      {/* avatar, customer name and rating */}
      <div className="columns is-mobile is-vcentered is-marginless is-family-secondary has-margin-bottom-2">
        <div className="column is-paddingless is-narrow">
          <ConfigProvider
            colors={[
              'var(--gradient-1)',
              'var(--gradient-2)',
              'var(--gradient-blue-blue)',
            ]}
          >
            <Avatar name={review.customer} round size="40" />
          </ConfigProvider>
        </div>
        <div className="column is-paddingless has-margin-left-3">
          <p className="has-text-weight-medium has-line-height-3">
            {(review.customer || '').split(' ')[0]}
          </p>
          <div className="has-text-dark-75 has-line-height-3 has-text-14p">
            {format(parseDate(review.reviewDate), 'MMMM, YYYY')}
          </div>
        </div>
        <div className="column is-paddingless is-narrow is-size-16p is-flex">
          <span className="has-text-weight-bold">{review.totalRating}</span>{' '}
          <span className="star has-text-purple" />
        </div>
      </div>

      {/* comments */}
      <p className="has-padding-left-3 has-line-height-4">
        <ReadMore text={review.extraComments} max={120} />
      </p>

      {/* stylist reply if any */}
      {!!review.reply && (
        <div className="has-padding-left-5 has-margin-top-3">
          <div className="is-flex is-vcentered has-margin-bottom-3">
            <Avatar
              size="40"
              src={provider!.profileImage}
              name={provider!.firstName}
              round
            />
            <div className="has-margin-left-3 is-family-secondary">
              {provider!.firstName}'s Response
            </div>
          </div>

          <p className="has-line-height-6">
            <ReadMore max={100} text={review.reply} />
          </p>
        </div>
      )}

      {/* flayr reply if any */}
      {!!review.flayrReply && (
        <div className="has-padding-left-5 has-margin-top-3">
          <div className="is-flex is-vcentered has-margin-bottom-3">
            <Avatar size="40" name={'Flayr'} round initials="F" />
            <div className="has-margin-left-3 is-family-secondary">Flayr</div>
          </div>

          <p className="has-line-height-6">
            <ReadMore max={100} text={review.flayrReply} />
          </p>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ search }: AppStore) => ({
  provider: search.currentProfile,
});
export default connect(mapStateToProps)(CustomerReview);
