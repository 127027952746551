import React, { useState } from 'react';
import CalendarNav from './CalendarNav/CalendarNav';
import {
  format,
  addMonths,
  subMonths,
  getYear,
  getMonth,
  getDay,
  startOfWeek,
  parse,
  isSameDay,
} from 'date-fns';
import CalendarHeader from './CalendarHeader/CalendarHeader';
import CalendarBody from './CalendarBody/CalendarBody';
import { getCalendarMatrix } from '../../utils';

interface CalendarProps {
  date?: string;
  onSelect?: (date: string) => any;
}
const Calendar: React.FunctionComponent<CalendarProps> = ({
  date,
  onSelect,
}) => {
  const [currentDate, setCurrentDate] = useState(
    date ? parse(new Date(date)) : new Date()
  );

  const calendarMatrix = getCalendarMatrix(
    getYear(currentDate),
    getMonth(currentDate),
    getDay(startOfWeek(currentDate))
  );

  function handleDayClick(date: Date) {
    onSelect && onSelect(date.toString());
  }

  return (
    <section className="calendar__container has-background-white">
      <CalendarNav
        month={format(currentDate, 'MMM')}
        year={format(currentDate, 'YYYY')}
        onPrev={() => setCurrentDate(subMonths(currentDate, 1))}
        onNext={() => setCurrentDate(addMonths(currentDate, 1))}
      />
      <CalendarHeader />
      <CalendarBody
        days={calendarMatrix}
        onDayClick={handleDayClick}
        date={currentDate}
        selectedDate={date}
      />
    </section>
  );
};

export default Calendar;
