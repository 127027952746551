import * as React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import classnames from 'classnames';
import Button from '../Button/Button';
import { getTimeAmPm, optimizeImage, isCached } from '../../utils';
import StarRating from '../StarRating/StarRating';
import useToggle from '../../hooks/useToggle';
import Portal from '../Portal/Portal';
import Modal from '../Modal/Modal';
import Badge from '../Badge/Badge';

interface IProfileInfoProps {
  imgSize?: string;
  name?: React.ReactNode;
  rating?: number;
  reviews?: number;
  location?: string;
  generic?: boolean;
  img?: string;
  onClick?: () => void;
  onShowPrices?: () => void;
  forProfilePage?: boolean;
  price?: number;
  deposit?: number;
  type?: string;
  instantOffer?: boolean;
  startTime?: string;
  endTime?: string;
  distance?: number;
  bookedOut?: boolean;
  className?: string;
  hideReviews?: boolean;
  shortRatingDisabled?: boolean;
  hideReviewsLong?: boolean;
  hideInlineStarCount?: boolean;
  showInlineReviews?: boolean;
  covidTrained?: boolean;
}
const ProfileInfo: React.SFC<IProfileInfoProps> = ({
  imgSize = '56',
  name,
  rating,
  reviews,
  location,
  generic,
  img = '',
  onClick,
  onShowPrices,
  forProfilePage,
  hideReviews,
  price,
  deposit,
  type,
  instantOffer,
  startTime,
  endTime,
  distance,
  bookedOut,
  className = '',
  hideReviewsLong,
  shortRatingDisabled = false,
  hideInlineStarCount,
  showInlineReviews,
  covidTrained,
}) => {
  function handleProfileClick() {
    if (!forProfilePage && onClick) {
      onClick();
    }
  }

  const modal = useToggle();

  return (
    <section className={'has-padding-3 ' + className}>
      <div className="columns is-mobile is-marginless is-vcentered">
        <div
          className="column is-narrow is-paddingless has-pointer"
          onClick={handleProfileClick}
        >
          <figure
            className={classnames(`image is-${imgSize}x${imgSize}`, {
              'is-grayscale': bookedOut,
            })}
          >
            <LazyLoadImage
              className="is-rounded"
              src={optimizeImage(img, 100)}
              alt={name}
              threshold={600}
              visibleByDefault={isCached(optimizeImage(img, 100))}
            />
          </figure>
        </div>
        <div className="column has-text-dark-100 is-paddingless has-padding-left-3">
          <div className="columns is-mobile is-marginless">
            <div className="column is-paddingless has-padding-right-3 is-family-secondary is-flex is-vcentered">
              <div onClick={handleProfileClick} className="has-pointer">
                <p className="has-line-height-2 has-text-weight-medium is-size-16p">
                  <span>{name}</span>
                  {covidTrained && (
                    <>
                      <span
                        className="inline-block has-margin-left-4 has-pointer"
                        onClick={modal.toggle}
                      >
                        <Badge
                          icon="fal fa-hand-sparkles is-size-5-desktop"
                          text="COVID-19 Trained"
                          textClassName="is-size-7 is-size-6-desktop"
                        />
                      </span>
                      <Portal>
                        <Modal
                          open={modal.on}
                          header={
                            <div className="p3 title is-5">Covid Trained</div>
                          }
                          onClose={modal.toggle}
                          onBackdropClick={modal.toggle}
                          footerBtnLabel="Close"
                          hasDefaultFooter
                          onFooterBtnClick={modal.toggle}
                        >
                          Artist has completed the Infection Control Training -
                          COVID-19 by the Department of Health
                        </Modal>
                      </Portal>
                    </>
                  )}
                </p>
                {!hideReviews && (
                  <>
                    {!shortRatingDisabled && (
                      <div className="has-line-height-4 is-flex is-vend">
                        <span className="has-text-dark-100 has-margin-right-1 has-margin-top-1">
                          {rating ? (
                            <span>
                              {rating} <span className="star has-text-purple" />
                            </span>
                          ) : (
                            ''
                          )}
                        </span>
                        <span className="has-text-dark-50 is-size-14p has-margin-top-1">
                          {reviews ? `(${reviews})` : 'New Artist'}
                        </span>
                      </div>
                    )}
                    {shortRatingDisabled && (
                      <>
                        <div className="has-line-height-4 is-flex is-vend">
                          <span className="has-text-dark-100 has-margin-right-1 has-margin-top-1">
                            {rating ? (
                              <span className="is-flex">
                                <StarRating rating={rating} />
                                {!hideInlineStarCount && (
                                  <small className="has-margin-left-2">
                                    ({rating})
                                  </small>
                                )}
                              </span>
                            ) : (
                              ''
                            )}
                          </span>

                          {showInlineReviews && (
                            <span className="has-text-dark-50 is-size-14p has-margin-top-1">
                              {reviews ? `(${reviews})` : 'New Artist'}
                            </span>
                          )}
                        </div>

                        {!hideReviewsLong && (
                          <div className="has-text-dark-50 is-size-14p has-margin-top-1 has-text-weight-medium has-line-height-4">
                            {reviews ? `${reviews} Reviews` : 'New Artist'}
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}

                {!!location && (
                  <div className="has-line-height-1 is-family-primary has-text-dark-50 is-size-14p">
                    <i
                      className={classnames('far', {
                        'fa-map-marker-alt': type === 'home',
                        'fa-car': type === 'mobile',
                      })}
                    />{' '}
                    {type === 'home' && (
                      <span>
                        {location}
                        {!!distance && <span>, {distance} km</span>}
                      </span>
                    )}
                    {type === 'mobile' && <span>Comes to you</span>}
                  </div>
                )}
              </div>
            </div>
            <div className="column is-narrow is-paddingless is-flex is-vcentered">
              {!!(price && deposit && !forProfilePage) && (
                <div
                  className="has-text-right is-flex is-vcentered has-pointer"
                  onClick={handleProfileClick}
                >
                  <div className="has-line-height-2">
                    <p>${price} total</p>

                    {!bookedOut && (
                      <p className="has-text-dark-50">
                        <small>deposit of ${deposit}</small>
                      </p>
                    )}

                    {bookedOut && (
                      <p className="has-text-dark-50">Booked Out</p>
                    )}
                    {instantOffer && (
                      <p className="has-text-success is-size-14p">
                        <i className="far fa-clock" /> {getTimeAmPm(startTime!)}{' '}
                        to {getTimeAmPm(endTime!)}
                      </p>
                    )}
                  </div>

                  {!forProfilePage && (
                    <div className="is-size-6 has-margin-left-2">
                      <i className="is-icon-chevron-right has-text-purple" />
                    </div>
                  )}
                </div>
              )}

              {!price && !deposit && !forProfilePage && (
                <Button
                  isRounded
                  isFullwidth
                  color="gradient-1"
                  className="is-size-7 has-padding-x-4"
                  onClick={onShowPrices}
                >
                  Instant quote
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ProfileInfo;
