import { Auth } from '../types/auth';
import { AnyAction } from 'redux';
import {
  AUTH_MOBILE_REQUEST,
  AUTH_MOBILE_SUCCESS,
  AUTH_MOBILE_FAILURE,
  AUTH_CODE_FAILURE,
  AUTH_CODE_REQUEST,
  AUTH_CODE_SUCCESS,
  AUTH_GO_BACK,
} from '../actions/action-types';
import { getLocalStorageItem } from '../utils';

const initialState: Auth = {
  userExists: false,
  mobile: getLocalStorageItem() ? getLocalStorageItem().mobile : '',
  token: getLocalStorageItem() ? getLocalStorageItem().token : '',
  isLoading: false,
  message: '',
};

export function authReducer(
  state = initialState,
  { type, payload }: AnyAction
) {
  switch (type) {
    case AUTH_GO_BACK:
      return { ...state, message: '' }
    case AUTH_MOBILE_REQUEST:
      return { ...state, mobile: payload, isLoading: true };
    case AUTH_MOBILE_SUCCESS:
      return {
        ...state,
        token: payload.token,
        userExists: true,
        isLoading: false,
        message: '',
      };
    case AUTH_MOBILE_FAILURE:
      return {
        ...state,
        mobile: '',
        userExists: false,
        isLoading: false,
        message: payload,
      };
    case AUTH_CODE_REQUEST:
      return { ...state, isLoading: true };
    case AUTH_CODE_SUCCESS:
      return { ...state, isLoading: false, token: payload.token, message: '' };
    case AUTH_CODE_FAILURE:
      return { ...state, isLoading: false, message: payload };
    default:
      return state;
  }
}
