import React from 'react';
import Button from '../../../shared-components/Button/Button';
import { RequestStage } from '../../../types/request/request';
import classnames from 'classnames';
import CheckLoader from '../../../shared-components/CheckLoader/CheckLoader';
import NoObligation from '../../../shared-components/NoObligation/NoObligation';
import { THANK_YOU_URL, CHECKOUT_URL } from '../../../constants';
interface CallToActionProps {
  stage?: any;
  onClick?: () => void;
  requestId?: string;
  providerId?: string;
  deposit?: number;
}

const labelMapping: any = {
  [RequestStage.noRequest]: 'Check Availability',
  [RequestStage.noQuote]: 'Instant pricing',
  [RequestStage.declined]: 'Stylist unavailable',
  [RequestStage.offered]: 'Book now',
  [RequestStage.booked]: 'Get Stylist Details',
  [RequestStage.requested]: 'Checking availability',
  [RequestStage.expired]: 'Offer expired. Request again',
};
const CallToAction: React.FunctionComponent<CallToActionProps> = ({
  stage,
  requestId = '',
  providerId = '',
  onClick,
  deposit,
}) => {
  let link = '';

  if (stage === RequestStage.offered) {
    link = `${CHECKOUT_URL}/book/${requestId}`;
  } else if (stage === RequestStage.booked) {
    link = `${THANK_YOU_URL}/thank-you/${requestId}/${providerId}`;
  }

  function handleBtnClick() {
    if (
      stage !== RequestStage.declined &&
      stage !== RequestStage.requested &&
      stage !== RequestStage.booked
    ) {
      onClick && onClick();
    }
  }

  return (
    <>
      <Button
        type={
          stage === RequestStage.offered || stage === RequestStage.booked
            ? 'link'
            : 'button'
        }
        href={link}
        isRounded
        color={
          stage === RequestStage.requested ? 'gradient-blue-blue' : 'gradient-1'
        }
        isOutlined={stage === RequestStage.requested}
        isFullwidth
        onClick={handleBtnClick}
      >
        <span className="is-flex is-vcentered is-hcentered">
          {stage === RequestStage.requested && (
            <span className="has-text-gradient-blue-blue-inverted has-margin-right-3">
              <CheckLoader size={22} color={'var(--cyan)'} />
            </span>
          )}
          <span
            className={classnames({
              'has-text-gradient-blue-blue': stage === RequestStage.requested,
              'is-inline-flex is-vcentered': stage === RequestStage.offered,
            })}
          >
            {labelMapping[`${stage}`]}{' '}
            {stage === RequestStage.offered && !!deposit && (
              <>
                <i
                  className="has-margin-x-3 fas fa-circle"
                  style={{ fontSize: 5 }}
                />
                <span>${deposit}</span>
              </>
            )}
          </span>
        </span>
      </Button>
      {stage === RequestStage.noRequest && (
        <div className="has-margin-top-2 is-flex is-hcentered">
          <NoObligation />
        </div>
      )}
    </>
  );
};
export default CallToAction;
