import React, { useState, useEffect, useRef, ReactElement } from 'react';
import ProfileDisplay from '../../shared-components/ProfileDisplay/ProfileDisplay';
import Tab from '../../shared-components/Tab/Tab';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { AppStore } from '../../types/appStore';
import { Dispatch } from 'redux';
import {
  setStylists,
  setTab,
  setCurrentProfile,
  setHomePage,
  setMobilePage,
} from '../../actions/search-actions';
import { Stylist } from '../../types/stylist/stylist';
import {
  getGenericStylists,
  getLocationBasedStylists,
  quoteSearch,
} from '../../services/search.services';
import { QuoteResult } from '../../types/quote/quote-result';
import { Quote } from '../../types/quote/quote';
import { setQuote } from '../../actions/quote-actions';
import { humanize, isMobile } from '../../utils';
import { Request, RequestStage } from '../../types/request/request';
import { setRequests } from '../../actions/request-actions';
import QuoteForm from '../../shared-components/QuoteForm/QuoteForm';
import { Spinner } from '../../shared-components/Spinner/Spinner';
import NeedHelp from '../../shared-components/NeedHelp/NeedHelp';
// import Jobs from '../../shared-components/Jobs/Jobs';
import FilterGroup from '../../shared-components/FilterGroup/FilterGroup';
import { isArray } from 'util';
import './SearchResult.scss';
import Filter from '../../shared-components/Filter/Filter';
import { sortStylists, filterStylists } from '../../actions/stylist-actions';
import { IFilterBy, ESortBy } from '../../types/search/search';
import InfiniteScroll from 'react-infinite-scroller';
import Navbar from '../../shared-components/Navbar/Navbar';
import classnames from 'classnames';
import ReasonsToBook from '../../shared-components/ReasonsToBook/ResonsToBook';
import queryString from 'query-string';
import ConditionalWrapper from '../../shared-components/ConditionalWrapper/ConditionalWrapper';
import ProgressTracker from '../../shared-components/ProgressTracker/ProgressTracker';
import ProviderCard from '../../shared-components/ProviderCard/ProviderCard';
import FeaturedList from './FeaturedList/FeaturedList';
import AvailabilityAlert from '../../shared-components/AvailabilityAlert/AvailabilityAlert';
import OffersReceived from '../../shared-components/OffersReceived/OffersReceived';
import ReactGA from 'react-ga';
import HomeStudioMap from '../../shared-components/HomeStudioMap/HomeStudioMap';
import ProviderList from './ProviderList/ProviderList';
import Row from '../../shared-components/Row/Row';
import Column from '../../shared-components/Column/Column';
import FreeCancellation from '../../shared-components/FreeCancellation/FreeCancellation';
import useToggle from '../../hooks/useToggle';
ReactGA.initialize('UA-127854499-2');

interface SearchResultProps
  extends RouteComponentProps<{
    quoteId: string;
    state: string;
    suburb: string;
    postcode: string;
    type: string;
  }> {
  key?: any;
  setStylistsData: (
    homeStylists: Stylist[],
    mobileStylists: Stylist[],
    featuredHome: Stylist[],
    featuredMobile: Stylist[]
  ) => void;
  setQuoteData: (quote: Quote) => void;
  setRequestsData: (requests: Request[]) => void;
  setSelectedTab: (tab: 'home' | 'mobile') => void;
  setCurrentProfileData: (profile: Stylist) => void;
  sortProviders: (type: 'price' | 'reviews') => any;
  filterProviders: (type: IFilterBy) => any;
  filterBy: IFilterBy;
  sortBy?: ESortBy;
  setHomePageNum: (page: number) => any;
  setMobilePageNum: (page: number) => any;
  homePage: number;
  mobilePage: number;
  mobileProviders: Stylist[];
  homeProviders: Stylist[];
  notifications?: { home: number; mobile: number };
  hasLoaded: boolean;
  tabSelected: 'home' | 'mobile';
  quoteId: string;
  quote: Quote;
}

const SearchResult: React.FunctionComponent<SearchResultProps> = (props) => {
  const [searchLoading, setSearchLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [locationModalOpen, setLocationModalOpen] = useState(false);

  const [selectedFilterOption, setFilterOption] = useState('search');

  let pageTopRef: any = useRef();
  const [isAtTop, setAtTop] = useState(true);

  let lastScrollTop = 0;
  const cancellationModal = useToggle();

  const {
    setStylistsData,
    setHomePageNum,
    setMobilePageNum,
    homePage,
    mobilePage,
    mobileProviders,
    homeProviders,
    match,
    location,
    setQuoteData,
    history,
    setRequestsData,
    tabSelected,
    hasLoaded,
    setSelectedTab,
    quoteId,
    quote,
    setCurrentProfileData,
    sortProviders,
    filterProviders,
    notifications,
    sortBy,
    filterBy,
  } = props;

  useEffect(() => {
    let { quoteId, suburb, state } = match.params;

    let query = queryString.parse(location.search);

    // if we have ?service in the param
    const service = query.service || '';
    if (service) {
      // only set service type if it matches the services we support to avoid errors
      if (service === 'makeup' || service === 'haircut') {
        const quoteData = { ...quote, type: service };
        setQuoteData(quoteData);
      }
    }

    // open prompt based on query string
    if (query.prompt === 'true') {
      setLocationModalOpen(true);
    }

    // select filterBy
    // if (query.instant_book) {
    //   filterProviders({
    //     ...filterBy,
    //     showInstantBooks: true,
    //   });
    // }

    //check if for quote
    if (quoteId) {
      if (!hasLoaded) getQuoteData(quoteId);
      setTabPath();
    } else {
      if (!hasLoaded) getInitialData(state, suburb);
    }

    //if for quote history push
    if (!quoteId && quote.shopify_id) {
      history.replace('/quote/' + quote.shopify_id);
    }

    if (isMobile()) {
      // for showing and hiding filter
      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  function handleScroll() {
    if (reachedTop()) {
      setAtTop(true);
      setFilterOption('search');
    }
    if (!isUpscroll()) {
      if (isAtTop) {
        setAtTop(false);
      }
      setFilterOption('');
    }
  }

  function handleFilterGroupChange(type) {
    if (type === 'search') {
      scrollToTop();
      if (!quote || !quoteId) {
        promptQuoteForm();
      }
    }

    if (type === selectedFilterOption) {
      setFilterOption('');
    } else {
      setFilterOption(type);
    }
  }

  function scrollToTop(behavior: 'smooth' | 'auto' = 'auto') {
    window.scrollTo({ top: 0, left: 0, behavior });
  }

  function reachedTop() {
    if (!pageTopRef) {
      return false;
    }
    const bounding = pageTopRef.current.getBoundingClientRect();
    return bounding.top >= 0;
  }

  function isUpscroll() {
    const st = window.pageYOffset || document.documentElement.scrollTop;
    const down = st > lastScrollTop;
    lastScrollTop = st <= 0 ? 0 : st;
    return !down;
  }

  //set url to home or mobile if its a quote
  const setTabPath = () => {
    let { type, quoteId } = match.params;
    // http://localhost:3001/quote/v8txj2b43T?instant_book=true&uid=
    if (!(type === 'home' || type === 'mobile')) {
      const query = location.search;
      const qs = history.replace(`/quote/${quoteId}/${tabSelected}${query}`);
    } else if (type === 'home') {
      setSelectedTab('home');
    } else {
      setSelectedTab('mobile');
    }
  };

  const getProviderLocation = (provider: Stylist, type: 'home' | 'mobile') => {
    if (type === 'home') {
      return provider.suburb;
    } else if (provider.suburb && provider.state) {
      return `${provider.suburb}, ${provider.state}`;
    } else if (quote.suburb && quote.state) {
      return `${quote.suburb}, ${quote.state}`;
    } else {
      return undefined;
    }
  };

  //for when there is no quote id
  const getInitialData = async (state?: string, suburb?: string) => {
    try {
      setSearchLoading(true);
      let stylists: any;
      if (state && suburb) {
        stylists = (await getLocationBasedStylists(state, suburb)).data;
        if (stylists) {
          // setQuoteData({
          //   state: state.toUpperCase(),
          //   suburb: humanize(suburb),
          //   postcode: stylists.postcode,
          //   shopify_id: '',
          //   person_service_list: quote.person_service_list,
          // });
        }
      } else {
        stylists = (await getGenericStylists()).data;
      }
      if (!stylists) {
        //redirect to 404 page here.
        setNotFound(true);
      } else {
        setNotFound(false);
        setStylistsData(
          stylists.home,
          stylists.mobile,
          stylists.featuredHom || [],
          stylists.featuredMobil || []
        );
      }
      setSearchLoading(false);
    } catch (err) {
      setSearchLoading(false);
      console.error(err.response);
      console.error(err);
    }
  };

  const promptQuoteForm = () => {
    setLocationModalOpen(true);
  };

  const exists = (arr, search) => {
    return arr.some((row) => row.includes(search));
  };
  //switch tab logic based on types
  const switchHomeMobile = (data: QuoteResult) => {
    if (data.quote && data.quote.type === 'haircut') {
      console.log(data.mobile.length, data.quote.person_service_list);
      if (
        data.mobile.length &&
        data.quote &&
        exists(data.quote.person_service_list, 'styling')
      )
        return changeSelectedTab('mobile', data.quote.shopify_id);
      if (data.home.length)
        return changeSelectedTab('mobile', data.quote.shopify_id);
      if (data.mobile.length)
        return changeSelectedTab('mobile', data.quote.shopify_id);
    } else {
      if (data.mobile.length)
        return changeSelectedTab('mobile', data.quote.shopify_id);
      if (data.home.length)
        return changeSelectedTab('home', data.quote.shopify_id);
    }
  };

  const changeSelectedTab = (tab: 'home' | 'mobile', quoteId: string) => {
    setSelectedTab(tab);
    history.replace(`/quote/${quoteId}/${tab}`);
  };
  //search for quote;
  const getQuoteData = async (quoteId: string) => {
    try {
      setSearchLoading(true);
      let data: QuoteResult = (await quoteSearch(quoteId)).data;
      setQuoteData(data.quote);

      if (!data || (!data.mobile.length && !data.home.length)) {
        setNotFound(true);
      } else {
        setNotFound(false);
        switchHomeMobile(data);
        //check if there are no stylists. send event to GA + Heap
        sendNoProvidersAnalytics(data.home, data.mobile);
        setStylistsData(
          data.home,
          data.mobile,
          data.featuredHome,
          data.featuredMobile
        );
        setRequestsData(data.requests);
      }
      scrollToTop('auto'); //scroll back to the top
      setSearchLoading(false);
    } catch (err) {
      setSearchLoading(false);
      console.error(err);
    }
  };

  const sendNoProvidersAnalytics = (homeList: any[], mobileList: any[]) => {
    if (homeList.length || mobileList.length) {
      ReactGA.pageview(location.pathname + '?hasProviders=true');
      if (window['heap']) {
        window['heap'].track('hasProviders=true');
      }
    } else {
      ReactGA.pageview(location.pathname + '?hasProviders=false');
      if (window['heap']) {
        window['heap'].track('hasProviders=false');
      }
    }
  };

  const onTabChange = (tab: 'home' | 'mobile') => {
    //routing for tab change
    let { quoteId } = match.params;
    if (quoteId) {
      window.scrollTo(0, 0);
      history.replace(`/quote/${quoteId}/${tab}`);
    }
    filterProviders({
      hideBooked: false,
      hideNew: false,
      showInstantBooks: false,
    });
    setSelectedTab(tab);
  };

  const handleProfileClick = (
    type: 'home' | 'mobile',
    providerId: string,
    quoteId,
    profile: Stylist,
    isCarousel = false
  ) => {
    setCurrentProfileData(profile);

    let qs: any = {
      recommended: !!profile.isFeatured,
      position: isCarousel ? 'carousel' : 'list',
    };

    qs = Object.keys(qs)
      .map((key) => `${key}=${qs[key]}`)
      .join('&');
    if (quote.shopify_id) {
      history.push(`/quote/${quoteId}/${type}/${providerId}?${qs}`);
    } else {
      history.push(`/profile/${providerId}?${qs}`);
    }
  };

  function handleFilterchange(e) {
    const { name, checked } = e.target;
    filterProviders({
      ...filterBy,
      [name]: checked,
    });
  }

  function handleSort(type) {
    sortProviders(type);
  }

  function sortFilterItems(collection: Stylist[], sortFilterEnabled) {
    collection = [...collection];
    if (sortFilterEnabled) {
      // filter
      if (filterBy.hideBooked) {
        collection = collection.filter((stylist) => !stylist.bookedOut);
      }
      if (filterBy.hideNew) {
        collection = collection.filter((stylist) => !!stylist.numOfReviews);
      }
      if (filterBy.showInstantBooks) {
        collection = collection.filter(
          (stylist) =>
            stylist.request &&
            (stylist.request.stage === 20 ||
              stylist.request.stage == RequestStage.requested)
        );
      }
      // then sort
      if (sortBy === ESortBy.price) {
        collection = collection.sort(
          (prev, next) => prev.pricing!.cost - next.pricing!.cost
        );
      }
      if (sortBy === ESortBy.reviews) {
        collection = collection.sort(
          (prev, next) => next.numOfReviews - prev.numOfReviews
        );
      }
      if (sortBy === ESortBy.distance) {
        collection = collection.sort(
          (prev: any, next: any) => prev.distance - next.distance
        );
      }
    }
    return collection;
  }

  const getNext10 = (collection: any[], page: number) => {
    return collection.slice(0, page * 10);
  };

  function getBookedStylist() {
    let type: 'home' | 'mobile' = 'mobile';
    let provider: Stylist | undefined = mobileProviders.find(
      (stylist) =>
        !!(stylist.request && stylist.request.stage === RequestStage.booked)
    );

    if (!provider) {
      provider = homeProviders.find(
        (stylist) =>
          !!(stylist.request && stylist.request.stage === RequestStage.booked)
      );
      type = 'home';
    }
    return { provider, type };
  }

  function renderBookedStylist() {
    const { provider, type } = getBookedStylist();

    if (provider) {
      return (
        <div className="columns is-marginless">
          <div className="column is-4-tablet is-4-desktop is-3-fullhd">
            <ProfileDisplay
              profileImg={provider.profileImage}
              coverImg={provider.coverImage}
              name={provider.firstName}
              rating={provider.reviewScore}
              reviews={provider.numOfReviews}
              price={provider.pricing ? provider.pricing.cost : undefined}
              deposit={provider.pricing ? provider.pricing.take : undefined}
              hideReviews={quote.type === 'haircut'}
              kit={
                isArray(provider.makeupBrands)
                  ? provider.makeupBrands.join(', ')
                  : provider.makeupBrands
              }
              imgSize="56"
              key={provider.providerId}
              onProfileClick={() => {
                handleProfileClick(
                  type,
                  provider!.providerId,
                  quoteId,
                  provider!
                );
              }}
              onShowPrices={promptQuoteForm}
              instantOffer={
                provider.request &&
                (provider.request.instantOffer ||
                  provider.request.stage === RequestStage.offered)
              }
              startTime={provider.request && provider.request.startTime}
              endTime={provider.request && provider.request.finishTime}
              providerId={provider.providerId}
              type={type}
              generic={!quoteId}
              location={getProviderLocation(provider, type)}
              distance={
                type === 'home' && provider.distance
                  ? Math.round(provider.distance / 1000)
                  : undefined
              }
              requested={
                provider.request &&
                provider.request.stage === RequestStage.requested
              }
              bookedOut={
                provider.bookedOut ||
                (provider.request &&
                  provider.request.stage === RequestStage.declined)
              }
              bio={provider.bio}
              bookingConfirmed
            />
          </div>
        </div>
      );
    }
    return null;
  }

  function isRequestedorOffered() {
    let type = match.params.type === 'home' ? 'home' : 'mobile';
    let providers = type === 'home' ? homeProviders : mobileProviders;
    return providers.some((stylist) => {
      return !!(
        stylist.request &&
        (stylist.request.stage === 10 || stylist.request.stage === 20)
      );
    });
  }

  const headers = [
    mobileProviders.length
      ? {
          id: 'mobile',
          label: 'Mobile',
          notifications: notifications ? notifications.mobile : 0,
        }
      : null,
    homeProviders.length
      ? {
          id: 'home',
          label: 'Studio',
          notifications: notifications ? notifications.home : 0,
        }
      : null,
  ];

  function hasOffers(type) {
    const providers = type === 'mobile' ? mobileProviders : homeProviders;
    return providers.some(
      (provider) =>
        !!provider.request && provider.request.stage === RequestStage.offered
    );
  }

  return (
    <section className="SearchResult" ref={pageTopRef}>
      <div className="is-hidden-mobile is-sticky-top">
        <Navbar />
      </div>
      <div className="SearchResult-container">
        <ConditionalWrapper wrap={!isMobile()}>
          {quote && quote.status === 40 && getBookedStylist().provider && (
            <section className="SearchResult-list">
              <h3 className="has-margin-y-5 has-padding-x-3">
                Congrats on your booking with{' '}
                {getBookedStylist().provider!.firstName}
              </h3>
              <div className="SearchResult-item">
                <div>{renderBookedStylist()}</div>
              </div>
            </section>
          )}

          {/* search filter-sort group */}
          <section className="SearchResult-filter-group">
            <div
              className={classnames('columns is-marginless is-vcentered', {
                'has-border-bottom-25': !isMobile(),
                'is-hidden': quote.status === 40,
              })}
            >
              {/* availability alert for desktop, is duplicated */}
              <div className="column is-paddingless has-margin-y-3 is-size-5 is-hidden-mobile">
                <div className="is-hidden-mobile is-hidden-tablet-only">
                  <AvailabilityAlert
                    date={quote.event_date}
                    loading={searchLoading}
                  />
                </div>
              </div>
              <div className="column is-narrow is-paddingless">
                <FilterGroup
                  show
                  selectedTab={selectedFilterOption}
                  onTabSelect={handleFilterGroupChange}
                  filtered={Object.values(filterBy).some((fil) => fil)}
                  filterTab={
                    <Filter
                      selection={filterBy}
                      onChange={handleFilterchange}
                      enableHideBooked
                      enableOfferedRequested={isRequestedorOffered()}
                    />
                  }
                  homeTab={tabSelected === 'home' ? true : false}
                  sortBy={sortBy}
                  onSort={handleSort}
                  enableSearch={!isAtTop && quote && quote.status !== 40}
                  enableFilter={
                    !!quoteId &&
                    !notFound &&
                    !searchLoading &&
                    quote &&
                    quote.status !== 40
                  }
                  enableSort={
                    !!quoteId &&
                    !notFound &&
                    !searchLoading &&
                    quote &&
                    quote.status !== 40
                  }
                />
              </div>
            </div>
          </section>
          {/* search filter sort group end */}
          {/* load if not booked */}
          {(!quote || quote.status !== 40) && (
            <>
              {/* search stylists list */}
              <section className="SearchResult-list">
                {notFound && !searchLoading && (
                  <div className="has-text-centered has-margin-top-5">
                    {quote.type === 'makeup' && (
                      <>
                        Sorry, we currently don't have a stylist available in
                        your area for your package.
                        <br />
                        <br />
                        You might want to do another search just for Makeup
                        Only, to see if we have more stylists available.
                      </>
                    )}
                    {quote.type === 'haircut' && (
                      <>Hairdressers in your area coming soon</>
                    )}
                  </div>
                )}
                {searchLoading && (
                  <>
                    <div className="level has-margin-top-5">
                      <div className="level-item has-padding-x-4">
                        <Spinner
                          fullScreen={false}
                          isShowing={!match.params.quoteId}
                          message="Loading Stylists"
                        />
                        {!!match.params.quoteId && (
                          <ProgressTracker
                            items={[
                              {
                                title: 'Searching stylists',
                                icon: 'fal fa-search',
                              },

                              {
                                title: 'calculating prices',
                                icon: 'fal fa-calculator',
                              },
                            ]}
                            color="gradient-1"
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}

                {!searchLoading && (
                  <div>
                    <div className="is-hidden-desktop has-padding-left-4"></div>
                    <div
                      className="has-padding-4 has-background-success has-text-white is-size-5-desktop has-pointer has-text-centered has-margin-n-left-4"
                      onClick={cancellationModal.toggle}
                    >
                      <Row marginless hCentered>
                        <Column paddingless desktop="9" fullhd="10">
                          COVID cancellation policy.{' '}
                          <span className="is-underlined"> Learn more</span>
                        </Column>
                      </Row>
                    </div>
                  </div>
                )}
                <FreeCancellation
                  open={cancellationModal.on}
                  onClose={cancellationModal.toggle}
                />

                <div className="is-flex is-vcentered has-padding-4 is-hcentered">
                  <i className="fal fa-exclamation-triangle is-size-5 has-margin-right-3 has-text-danger" />
                  <div className="is-size-7">
                    <strong>
                      Check restrictions before requesting a booking.
                    </strong>
                    <br />
                    Please review and follow government guidelines.{' '}
                    <a
                      className="is-underlined"
                      href="https://www.health.gov.au/"
                      target="_blank"
                    >
                      Learn more
                    </a>
                  </div>
                </div>

                {/* availability alert for mobile, is duplicated */}
                <div className="is-hidden-tablet has-margin-top-4 has-padding-3">
                  <AvailabilityAlert
                    date={quote.event_date}
                    loading={searchLoading}
                    quoteType={quote.type}
                  />
                </div>
                {!searchLoading && !notFound && (
                  <div className="has-background-white is-relative">
                    <div className="has-margin-top-2 is-hidden-mobile" />
                    <>
                      <Tab
                        activeTab={tabSelected}
                        onSelect={onTabChange}
                        headers={headers}
                        hideHeader={!quoteId} // hide header when no quote
                      >
                        {['mobile', 'home'].map((type: any) => (
                          <section id={type} key={type}>
                            {type === 'home' && (
                              <HomeStudioMap
                                stylists={sortFilterItems(homeProviders, true)}
                                onCardClick={(s) => {
                                  handleProfileClick(
                                    type,
                                    s.providerId,
                                    quoteId,
                                    s
                                  );
                                }}
                                quote={quote}
                              />
                            )}
                            <div className="has-margin-top-5 is-hidden-mobile" />
                            <section className="SearchResult-recommended">
                              {!hasOffers(type) && (
                                <FeaturedList
                                  type={type}
                                  onProfileClick={(provider) => {
                                    handleProfileClick(
                                      type,
                                      provider.providerId,
                                      quoteId,
                                      provider!,
                                      true
                                    );
                                  }}
                                />
                              )}

                              {hasOffers(type) && (
                                <div className="has-margin-y-4">
                                  <div>
                                    <OffersReceived
                                      type={type}
                                      providers={
                                        type === 'mobile'
                                          ? mobileProviders
                                          : homeProviders
                                      }
                                      onProfileClick={(p) =>
                                        handleProfileClick(
                                          type,
                                          p.providerId,
                                          quoteId,
                                          p,
                                          p.isFeatured
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="has-margin-top-4 has-padding-x-4">
                                    <div className="is-size-4 has-text-weight-medium">
                                      All Stylists
                                    </div>
                                  </div>
                                </div>
                              )}
                            </section>
                            <div className="SearchResult-item">
                              {/* if type is mobile, show if mobileProviders.length */}
                              {/* if type is home, show if homeProviders.length */}
                              {((type === 'mobile' &&
                                mobileProviders &&
                                !!mobileProviders.length) ||
                                (type === 'home' &&
                                  homeProviders &&
                                  !!homeProviders.length)) && (
                                <>
                                  <InfiniteScroll
                                    pageStart={0}
                                    loadMore={() => {
                                      type === 'mobile'
                                        ? setMobilePageNum(mobilePage + 1)
                                        : setHomePageNum(homePage + 1);
                                    }}
                                    hasMore={
                                      getNext10(
                                        sortFilterItems(
                                          type === 'mobile'
                                            ? mobileProviders
                                            : homeProviders,
                                          true
                                        ),
                                        type === 'mobile'
                                          ? mobilePage
                                          : homePage
                                      ).length <
                                      sortFilterItems(
                                        type === 'mobile'
                                          ? mobileProviders
                                          : homeProviders,
                                        true
                                      ).length
                                    }
                                    loader={<div className="loader" key={0} />}
                                  >
                                    <div className="has-padding-3">
                                      <div className="columns is-mobile is-multiline is-marginless provider-card-container">
                                        <ProviderList
                                          type={type}
                                          quote={quote}
                                          quoteId={quoteId}
                                          providers={getNext10(
                                            sortFilterItems(
                                              type === 'mobile'
                                                ? mobileProviders
                                                : homeProviders,
                                              true
                                            ),
                                            type === 'mobile'
                                              ? mobilePage
                                              : homePage
                                          )}
                                          onProfileClick={(provider) =>
                                            handleProfileClick(
                                              type,
                                              provider!.providerId,
                                              quoteId,
                                              provider!
                                            )
                                          }
                                          onShowPrices={promptQuoteForm}
                                        />
                                      </div>
                                    </div>
                                  </InfiniteScroll>
                                </>
                              )}
                            </div>
                          </section>
                        ))}
                      </Tab>
                    </>
                  </div>
                )}
                <div className="has-margin-bottom-4 is-hidden-mobile" />
              </section>
              {/* <section className="SearchResult-jobs"><Jobs /></section> */}
            </>
          )}
        </ConditionalWrapper>

        <ConditionalWrapper wrap={!isMobile()} className="aside">
          <div
            className={classnames('has-padding-top-2 SearchResult-quote-form', {
              'is-invisible': isAtTop && selectedFilterOption === 'filter',
            })}
          >
            <div className="has-padding-4">
              <QuoteForm
                getQuoteData={(quoteId: string) => getQuoteData(quoteId)}
                getInitialData={(suburb: string, state: string) =>
                  getInitialData(state, suburb)
                }
                locationModalOpen={locationModalOpen}
                setLocationModalOpen={setLocationModalOpen}
              />
            </div>
          </div>
          <section className="is-hidden-mobile SearchResult-reasons">
            <ReasonsToBook type={quote.type || undefined} />
          </section>
          {/* show spinner when booked quote */}
          {searchLoading && !!quote && quote.status === 40 && (
            <div className="level has-margin-top-5 SearchResult-help">
              <div className="level-item">
                <Spinner
                  fullScreen={false}
                  isShowing
                  message="Loading Stylist"
                />
              </div>
            </div>
          )}
          <section className="has-border-top-5 SearchResult-help">
            <NeedHelp requestId={quoteId} />
          </section>
        </ConditionalWrapper>
      </div>
    </section>
  );
};

const mapStateToProps = (store: AppStore) => {
  const { search, quote, tab } = store;
  return {
    mobileProviders: search.mobileProviders,
    homeProviders: search.homeProviders,
    homePage: search.homePage,
    mobilePage: search.mobilePage,
    hasLoaded: search.hasLoaded,
    tabSelected: tab.selected,
    quoteId: quote.shopify_id,
    quote: quote,
    notifications: search.notifications,
    filterBy: search.filterBy,
    sortBy: search.sortBy,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setStylistsData: (
      homeStylists: Stylist[],
      mobileStylists: Stylist[],
      featuredHome,
      featuredMobile
    ) => {
      dispatch(
        setStylists(homeStylists, mobileStylists, featuredHome, featuredMobile)
      );
    },
    setQuoteData: (quote: Quote) => dispatch(setQuote(quote)),
    setRequestsData: (requests: Request[]) => dispatch(setRequests(requests)),
    setSelectedTab: (tab: 'home' | 'mobile') => dispatch(setTab(tab)),
    setCurrentProfileData: (profile: Stylist) =>
      dispatch(setCurrentProfile(profile)),
    sortProviders: (type: 'price' | 'reviews') => dispatch(sortStylists(type)),
    filterProviders: (filterBy: IFilterBy) =>
      dispatch(filterStylists(filterBy)),
    setHomePageNum: (page: number) => dispatch(setHomePage(page)),
    setMobilePageNum: (page: number) => dispatch(setMobilePage(page)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SearchResult));
