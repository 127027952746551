// authentication actions
export const AUTH_MOBILE_REQUEST = 'AUTH_MOBILE_REQUEST';
export const AUTH_MOBILE_SUCCESS = 'AUTH_MOBILE_SUCCESS';
export const AUTH_MOBILE_FAILURE = 'AUTH_MOBILE_FAILURE';
export const AUTH_CODE_REQUEST = 'AUTH_CODE_REQUEST';
export const AUTH_CODE_SUCCESS = 'AUTH_CODE_SUCCESS';
export const AUTH_CODE_FAILURE = 'AUTH_CODE_FAILURE';
export const AUTH_GO_BACK = 'AUTH_GO_BACK';

export const LOGOUT = 'LOGOUT';

export const USER_GET_INFO = 'USER_GET_INFO';
export const USER_SET_MOBILE = 'USER_SET_MOBILE';

export const REQUEST_SET_REQUESTS = 'REQUEST_SET_REQUESTS';
export const REQUEST_SET_REQUEST_ID = 'REQUEST_SET_REQUEST_ID';

export const STYLIST_SET_INFO = 'STYLIST_SET_INFO';

export const SET_STYLISTS = 'SET_STYLISTS';
export const SORT_STYLISTS = 'SORT_STYLISTS';
export const FILTER_STYLISTS = 'FILTER_STYLISTS';

export const SET_QUOTE = 'SET_QUOTE';

export const SET_HAS_LOADED = 'SET_HAS_LOADED';

export const SET_TAB = 'SET_TAB';

export const SET_CURRENT_PROFILE = 'SET_CURRENT_PROFILE';

export const SET_MOBILE_PAGE = 'SET_MOBILE_PAGE';
export const SET_HOME_PAGE = 'SET_HOME_PAGE';

export const SET_REVIEWS = 'SET_REVIEWS';
export const SET_FAQS = 'SET_FAQS';

export const SET_PROFILE_PRICING = 'SET_PROFILE_PRICING';

export const SET_STYLIST_REQUEST = 'SET_STYLIST_REQUEST';

export const SET_TIME_CHANGE = 'SET_TIME_CHANGE';