import Axios from 'axios';
import { BASE_SERVER_URL } from '../constants';

export async function getInitialBookingInfo(requestId, providerId) {
  const params = { providerId, requestId };
  return Axios.get(`${BASE_SERVER_URL}/v1/stylist/initial-booking-info`, {
    params,
  });
}

export async function getProfilePageData(
  quoteId: string,
  providerId: string,
  type: 'home' | 'mobile'
) {
  return Axios.get(
    `${BASE_SERVER_URL}/v2/profile-page-data/${quoteId}/${providerId}?type=${type}`
  );
}

export async function getGenericProfile(providerId: string) {
  return Axios.get(`${BASE_SERVER_URL}/v2/generic-profile/${providerId}`);
}

export async function requestedToday(providerId: string) {
  return Axios.get(`${BASE_SERVER_URL}/stylist-requests/${providerId}`);
}

export async function getStylistReviews(
  providerId: string,
  type: string = 'makeup'
) {
  const params = { providerId, type };
  return Axios.get(`${BASE_SERVER_URL}/get-reviews`, { params });
}

export async function getFaqs(funnelStage: string = 'quote') {
  return Axios.get(`${BASE_SERVER_URL}/faqs/${funnelStage}`);
}


export async function requestProvider(
  provider_id: string,
  quote_id: string,
  home_studio: boolean
) {
  return Axios.post(`${BASE_SERVER_URL}/request-stylist`, {
    provider_id,
    quote_id,
    home_studio,
  });
}
