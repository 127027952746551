import React, { useState, useEffect } from 'react';
import Button from '../Button/Button';
import './ProfileDisplay.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { optimizeImage, isCached, isMobile } from '../../utils';
import classnames from 'classnames';
import { requestedToday } from '../../services/stylist.services';
import ProfileInfo from '../ProfileInfo/ProfileInfo';
import Carousel from '../Carousel/Carousel';
import ProfileIndicator from '../ProfileIndicator/ProfileIndicator';
import Collage from '../Collage/Collage';

interface ProfileDisplayProps {
  providerId: string;
  profileImg: string;
  coverImg: string;
  name: string;
  location?: string;
  distance?: number;
  imgSize?: string;
  rating?: number;
  reviews?: number;
  price?: number;
  deposit?: number;
  priceVisible?: boolean;
  quoteId?: string;
  hideReviews?: boolean;
  kit?: string;
  bookedOut?: boolean;
  instantOffer?: boolean;
  startTime?: string;
  endTime?: string;
  onProfileClick?: () => void;
  onShowPrices?: () => void;
  style?: any;
  forProfilePage?: boolean;
  requested?: boolean;
  type?: string;
  generic?: boolean;
  galleryImages?: any[];
  bio?: string;
  className?: string;
  bookingConfirmed?: boolean;
  covidTrained?: boolean;
}

const ProfileDisplay: React.FunctionComponent<ProfileDisplayProps> = ({
  profileImg,
  name,
  imgSize,
  location,
  distance,
  rating,
  reviews,
  price,
  hideReviews,
  deposit,
  priceVisible = false,
  coverImg,
  onProfileClick,
  onShowPrices,
  quoteId,
  style,
  kit,
  bookedOut,
  instantOffer,
  startTime,
  endTime,
  providerId,
  forProfilePage,
  requested = false,
  type,
  generic = false,
  galleryImages = [],
  bio,
  className = 'undefined',
  bookingConfirmed,
  covidTrained,
}) => {
  const [inDemand, setInDemand] = useState(false);

  useEffect(() => {
    if (providerId) {
      getRequestedToday(providerId);
    }
  }, []);

  const getRequestedToday = async (providerId: string) => {
    let data = (await requestedToday(providerId)).data;
    const { num_of_requests } = data;
    if (num_of_requests > 0) {
      setInDemand(true);
    }
  };

  function handleProfileClick() {
    if (onProfileClick) {
      onProfileClick();
    }
  }

  function getIndicatorType(): string {
    if (bookedOut) {
      return 'bookedOut';
    }
    if (requested) {
      return 'requested';
    }
    if (instantOffer) {
      return 'instantOffer';
    }
    if (inDemand) {
      return 'inDemand';
    }
    if (covidTrained) {
      return 'covidTrained';
    }
    return '';
  }

  return (
    <div
      className={classnames('ProfileDisplay', className, {
        'ProfileDisplay-booked-out': bookedOut,
        'ProfileDisplay-profile-page': forProfilePage,
      })}
    >
      {/* profile pic and name */}
      {!forProfilePage && (
        <ProfileInfo
          imgSize={imgSize}
          name={name}
          rating={rating}
          reviews={reviews}
          hideReviews={hideReviews}
          location={location}
          generic={generic}
          img={profileImg}
          onClick={handleProfileClick}
          onShowPrices={onShowPrices}
          forProfilePage={forProfilePage}
          price={price}
          deposit={deposit}
          type={type}
          instantOffer={!bookedOut && instantOffer}
          startTime={startTime}
          endTime={endTime}
          distance={distance}
          bookedOut={bookedOut}
          className="ProfileDisplay-info"
        />
      )}

      <div className="ProfileDisplay-kit">
        {kit && (
          <div className="has-padding-y-3 has-padding-x-4">
            <p className="has-line-height-4">
              {/* <span className="has-padding-top-5 is-hidden-mobile is-block" /> */}
              <span className="has-text-dark-100 is-family-secondary has-line-height-1 has-text-weight-medium is-block-desktop has-padding-right-2">
                {name}'s Kit
              </span>

              {/* <span className="has-margin-top-2 is-hidden-mobile is-block" /> */}
              <small className="has-text-dark-50 is-inline">
                {kit.length > 80 ? `${kit.slice(0, 80)}...` : kit}
              </small>
              <br />
              <br />
            </p>
          </div>
        )}
        {!kit && !forProfilePage && (
          <p className="has-line-height-1 is-size-7 is-hidden-desktop">
            <br />
            <br />
          </p>
        )}
      </div>
      {/* <div className="has-margin-top-4 is-hidden-mobile has-padding-4">
          {bio}
        </div> */}

      {(!forProfilePage || !galleryImages.length) && (
        <section
          className="ProfileDisplay-cover-image has-pointer"
          onClick={handleProfileClick}
        >
          <figure
            className={classnames('image has-covering-img is-square', {
              'is-grayscale': bookedOut,
            })}
          >
            <LazyLoadImage
              alt={`${name} cover image`}
              src={optimizeImage(coverImg, 700)}
              threshold={1200}
              visibleByDefault={isCached(optimizeImage(coverImg, 700))}
            />
          </figure>
          <div className="ProfileDisplay-overlay">
            {!forProfilePage && (
              <button onClick={handleProfileClick}>
                View <i className="fal fa-chevron-right" />
              </button>
            )}
            {getIndicatorType() && !bookingConfirmed && (
              <ProfileIndicator
                type={getIndicatorType()}
                startTime={startTime}
                endTime={endTime}
              />
            )}
          </div>
        </section>
      )}

      {forProfilePage && !!galleryImages.length && isMobile() && (
        <>
          <Carousel images={galleryImages} />
        </>
      )}
      {forProfilePage && !!galleryImages.length && !isMobile() && (
        <>
          <Collage images={galleryImages} imagesPerSlide={4} />
        </>
      )}
    </div>
  );
};

export default ProfileDisplay;
