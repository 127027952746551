import * as React from 'react';
import './Spinner.scss';
import classnames from 'classnames';

interface SpinnerProps {
  isShowing?: boolean;
  fullScreen?: boolean;
  message?: React.ReactNode;
  size?: number;
  color?: string;
}

export const Spinner: React.FC<SpinnerProps> = ({
  isShowing = false,
  fullScreen = true,
  message = '',
  color = 'black',
  size = 45,
}) => {
  if (isShowing) {
    return (
      <div
        className={classnames({ Spinner__container_fullScreen: fullScreen })}
      >
        <div className="loader has-margin-x-auto" style={{ fontSize: size, color: color }} />
        <div className="has-margin-top-3 has-text-centered has-text-secondary">
          {message || 'Loading...'}
        </div>
      </div>
    );
  }
  return null;
};
