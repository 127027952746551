import * as React from 'react';
import './Badge.scss';
import ShowIf from '../ShowIf/ShowIf';

interface IBadgeProps {
  icon?: string;
  text?: string;
  type?: 'default' | 'expansion';
  textClassName?: string;
}
const Badge: React.FC<IBadgeProps> = ({
  icon,
  text,
  type = 'default',
  textClassName,
}) => {
  return (
    <div className="Badge is-inline-block">
      <ShowIf
        condition={type === 'expansion'}
        elseTemplate={
          <span className="is-inline-block">
            <i className={icon} />
            <span className={'is-inline-block ml3 ' + textClassName}>
              {text}
            </span>
          </span>
        }
      >
        <input type="checkbox" />
      </ShowIf>
    </div>
  );
};

export default Badge;
