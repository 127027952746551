import React, { PureComponent } from 'react';
import Countdown from 'react-countdown-now';
import { RequestStage } from '../../../types/request/request';
import classnames from 'classnames';
import Modal from '../../../shared-components/Modal/Modal';
import FreeCancellation from '../../../shared-components/FreeCancellation/FreeCancellation';
import Portal from '../../../shared-components/Portal/Portal';

interface IProviderAlertProps {
  requestStage?: any;
  offerDuration?: string;
  isOfferExpired?: boolean;
  onClick?: (e?: any) => void;
}

const labelMapping = {
  [RequestStage.noQuote]: {
    heading: 'COVID cancellation policy ',
    subHeading: 'Learn More',
    className: 'has-background-success',
  },
  [RequestStage.noRequest]: {
    heading: 'COVID cancellation policy ',
    subHeading: 'Learn More',
    className: 'has-background-success',
  },
  // [RequestStage.noQuote]: {
  //   heading: 'This is not a reservation',
  //   subHeading: 'Prices are likely to rise. Lock in your price today.',
  //   className: 'has-background-danger',
  // },
  // [RequestStage.noRequest]: {
  //   heading: 'This is not a reservation',
  //   subHeading: 'Prices are likely to rise. Lock in your price today.',
  //   className: 'has-background-danger',
  // },
  [RequestStage.declined]: {
    heading: 'Stylist Unavailable',
    subHeading: 'Look for other stylists',
    className: 'has-bg-dark-75',
  },
  [RequestStage.requested]: {
    heading: 'Availability Check in Progress',
    subHeading: 'We will email & text you once the artist responds',
    className: 'has-bg-gradient-blue-blue',
  },
  [RequestStage.offered]: {
    heading: '',
    subHeading: (
      <span>
        COVID cancellation policy.{' '}
        <span className="is-underlined">Learn more</span>
      </span>
    ),
    className: 'has-background-danger',
  },
  [RequestStage.expired]: {
    heading: 'Offer Expired',
    subHeading: 'Request again',
    className: 'has-background-danger',
  },
  [RequestStage.booked]: {
    heading: 'Congrats on your booking',
    subHeading: 'the booking confirmation email has been sent.',
    className: 'has-bg-gradient-2',
  },
};

const ProviderAlert: React.FC<IProviderAlertProps> = ({
  requestStage,
  offerDuration,
  isOfferExpired,
  onClick,
}) => {
  const [modalOpen, setModalOpen] = React.useState<any>(false);

  function toggle() {
    setModalOpen(!modalOpen);
  }

  function handleClick() {
    if (
      requestStage === RequestStage.noRequest ||
      requestStage === RequestStage.noQuote ||
      +requestStage === RequestStage.offered
    ) {
      toggle();
    }
  }

  return (
    <>
      <div
        className={classnames(
          'has-padding-x-5 has-padding-y-3 has-padding-y-2-desktop has-text-white has-line-height-3',
          'has-text-centered-desktop has-margin-bottom-4-desktop',
          labelMapping[requestStage].className,
          {
            'has-pointer':
              requestStage === RequestStage.noRequest ||
              requestStage === RequestStage.noQuote ||
              +requestStage === RequestStage.offered,
          }
        )}
        onClick={handleClick}
      >
        {requestStage === RequestStage.offered.toString() &&
          !isOfferExpired &&
          offerDuration && (
            <span className="has-text-weight-bold">
              offer expires in{' '}
              <span>
                <Countdown
                  renderer={({ hours, minutes, seconds }) => (
                    <span>
                      {hours}h {minutes}m {seconds}s
                    </span>
                  )}
                  date={offerDuration}
                />
              </span>
            </span>
          )}
        <strong>{labelMapping[requestStage].heading}</strong>
        {!!labelMapping[requestStage].subHeading && (
          <div className="has-text-weight-medium has-line-height-4">
            <small>{labelMapping[requestStage].subHeading}</small>
          </div>
        )}
      </div>
      <Portal>
        <FreeCancellation open={modalOpen} onClose={toggle} />
      </Portal>
    </>
  );
};

export default ProviderAlert;
