import React, { useState, useEffect } from 'react';
import FormField from '../../FormField/FormField';
import Button from '../../Button/Button';

interface ServiceFormProps {
  service?: string;
  onChange?: (e: any) => void;
}

const serviceNameMapping = {
  makeup: 'Makeup & hair',
  haircut: 'Haircutting/colouring/treatments',
};

const ServiceForm: React.FC<ServiceFormProps> = ({
  service = '',
  onChange,
}) => {
  const [readOnly, setReadOnly] = useState(!!service);

  useEffect(() => {
    setReadOnly(!!service);
    return () => {
      setReadOnly(!!service);
    };
  }, [service]);

  function toggleReadOnly() {
    setReadOnly(!readOnly);
  }

  return (
    <>
      {readOnly && (
        <div className="columns is-mobile is-marginless is-vcentered is-size-6">
          <div className="column is-narrow is-paddingless">
            <small className="has-text-weight-medium is-capitalized">
              {serviceNameMapping[service]}
            </small>
          </div>
          <div className="column is-paddingless is-narrow has-margin-left-3">
            <Button
              size="small"
              color="gradient-1"
              onClick={toggleReadOnly}
              className="has-margin-top-2 has-padding-x-3"
            >
              Change
            </Button>
          </div>
        </div>
      )}
      {!readOnly && (
        <div>
          <FormField className="has-margin-bottom-0 has-margin-right-3 is-inline-block">
            <div className="select is-fullwidth">
              <select
                name="service"
                value={service}
                id="service"
                className="input"
                onChange={onChange}
              >
                <option value="" disabled>
                  Select Service
                </option>
                <option value="makeup">Makeup & Hair</option>
                <option value="haircut">
                  Haircutting/colouring/treatments
                </option>
              </select>
            </div>
          </FormField>
          {!!service && (
            <Button
              size="small"
              color="gradient-4"
              onClick={toggleReadOnly}
              className="has-margin-top-3 has-padding-x-3"
            >
              Cancel
            </Button>
          )}
        </div>
      )}
    </>
  );
};

export default ServiceForm;
