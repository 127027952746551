import React, { useState, useEffect } from 'react';
import './Reviews.scss';
import ProfileContent from '../../../shared-components/ProfileContent/ProfileContent';
import { EReview, IReviewItem } from '../../../types/stylist/stylist';
import classnames from 'classnames';
import CustomerReview from '../CustomerReview/CustomerReview';
import Button from '../../../shared-components/Button/Button';

interface ReviewsProps {
  summary: {
    [EReview.friendliness]: number;
    [EReview.nailedTheLook]: number;
    [EReview.onTime]: number;
    [EReview.wouldRecommend]: number;
  };
  reviews: IReviewItem[];
}

const reviewItems: any[] = [
  {
    label: 'Nailed The Look',
    value: EReview.nailedTheLook,
  },
  {
    label: 'Friendliness',
    value: EReview.friendliness,
  },
  {
    label: 'On Time',
    value: EReview.onTime,
  },
  {
    label: 'Would Recommend',
    value: EReview.wouldRecommend,
  },
];
const Reviews: React.FunctionComponent<ReviewsProps> = ({
  summary,
  reviews = [],
}) => {
  const [paginatedReviews, setPaginatedReviews] = useState<IReviewItem[]>([]);

  useEffect(() => {
    setPaginatedReviews(reviews.slice(0, 2));
  }, [reviews]);

  function handleShowMore() {
    setPaginatedReviews([
      ...paginatedReviews,
      ...reviews.slice(paginatedReviews.length, paginatedReviews.length + 5),
    ]);
  }

  return (
    <section className="Reviews">
      <div className="is-size-16p has-margin-top-3 Reviews-summary">
        {reviewItems.map(rev => (
          <div
            className="columns is-marginless is-mobile has-text-dark-100 is-family-secondary has-margin-bottom-2"
            key={rev.label}
          >
            <div className="column is-paddingless is-7">{rev.label}</div>
            <div className="column is-paddingless is-narrow has-text-purple">
              {[1, 2, 3, 4, 5].map(num => (
                <span
                  className={classnames({
                    star: Math.ceil(summary[rev.value] || 0) >= num,
                    'star-outlined': Math.ceil(summary[rev.value] || 0) < num,
                  })}
                  key={num}
                />
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className="has-margin-top-4 Reviews-details">
        {paginatedReviews.map((review, key) => (
          <CustomerReview review={review} key={key} />
        ))}

        {paginatedReviews.length < reviews.length && (
          <div className="has-text-centered has-margin-y-4">
            <Button
              isRounded
              isOutlined
              color="gradient-logo"
              onClick={handleShowMore}
            >
              <span className="has-text-weight-normal">
                Show More ({reviews.length - paginatedReviews.length})
              </span>
            </Button>
          </div>
        )}
      </div>
    </section>
  );
};
export default Reviews;
