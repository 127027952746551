import React from 'react';
import './ProvidersSlide.scss';
import { Stylist } from '../../types/stylist/stylist';
import ScrollPanel from '../ScrollPanel/ScrollPanel';
import ProviderCard from '../ProviderCard/ProviderCard';
import { RequestStage } from '../../types/request/request';

interface ProvidersSlideProps {
  providers?: Stylist[];
  type: 'home' | 'mobile';
  onProfileClick?: (p: Stylist) => void;
  heading?: string;
  subHeading?: string;
}

const ProvidersSlide: React.FunctionComponent<ProvidersSlideProps> = ({
  providers = [],
  type,
  onProfileClick,
  heading = '',
  subHeading = '',
}) => {
  return (
    <div className="has-padding-x-4 ProviderSlide">
      <div className="is-size-4 has-text-weight-medium">{heading}</div>
      <div className="is-size-6 has-text-dark-50">{subHeading}</div>
      <div className="has-scroll-x-hidden has-scroll-y-hidden has-margin-top-4">
        <div className="has-padding-x-3">
          <ScrollPanel>
            <div className="columns is-mobile ProviderSlide-container">
              {providers!.map((provider) => (
                <React.Fragment key={provider.providerId}>
                  {provider.bookedOut && null}

                  {!provider.bookedOut && (
                    <div className="column is-8-mobile is-5-tablet is-4-desktop is-3-widescreen ProvidersSlide-item has-margin-right-3-fullhd is-flex">
                      <div className="has-margin-left-3-desktop is-flex-fill is-flex is-flex-column">
                        <ProviderCard
                          className="has-margin-bottom-0 is-flex-fill"
                          imgSize="4by5"
                          name={provider.firstName}
                          rating={provider.reviewScore}
                          reviews={provider.numOfReviews}
                          profileImg={provider.profileImage}
                          coverImg={provider.coverImage}
                          type={type}
                          onClick={() => {
                            onProfileClick && onProfileClick(provider);
                          }}
                          price={
                            provider.pricing ? provider.pricing.cost : undefined
                          }
                          deposit={
                            provider.pricing ? provider.pricing.take : undefined
                          }
                          kit={
                            Array.isArray(provider.makeupBrands)
                              ? provider.makeupBrands.join(', ')
                              : provider.makeupBrands
                          }
                          about={provider.bio}
                          startTime={
                            provider.request && provider.request.startTime
                          }
                          endTime={
                            provider.request && provider.request.finishTime
                          }
                          providerId={provider.providerId}
                          requested={
                            provider.request &&
                            provider.request.stage === RequestStage.requested
                          }
                          bookedOut={
                            provider.bookedOut ||
                            (provider.request &&
                              provider.request.stage === RequestStage.declined)
                          }
                          instantOffer={
                            provider.request &&
                            (provider.request.instantOffer ||
                              provider.request.stage === RequestStage.offered)
                          }
                          featuredListItem
                          covidTrained={provider.covidTrained}
                        />
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </ScrollPanel>
        </div>
      </div>
    </div>
  );
};
export default ProvidersSlide;
