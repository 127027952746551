import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import './global-styles.scss';
import { store } from './store';
import { Switch, Route, Redirect } from 'react-router';
import { Spinner } from './shared-components/Spinner/Spinner';
import AppContext from './contexts/AppContext';
import SearchResult from './pages/SearchResult/SearchResult';
import Profile from './pages/Profile/Profile';

const App: React.FC = () => {
  const [loader, setLoader] = useState<any>({ isLoading: false, message: '' });
  const [modalOpen, setModalOpen] = useState(false);

  /*  useEffect(() => {
     document.body.classList.toggle('has-modal-open', modalOpen);
     document
       .querySelector('html')!
       .classList.toggle('has-modal-open', modalOpen);
   }, [modalOpen]); */

  return (
    <Provider store={store}>
      <AppContext.Provider
        value={{
          loader,
          setLoader,
          modalOpen,
          setModalOpen,
        }}
      >
        <Switch>
          <Route exact path="/" component={SearchResult} />
          <Route exact path="/404" component={() => <div>404 page</div>} />
          <Route exact path="/quote/:quoteId" component={SearchResult} />
          <Route exact path="/quote/:quoteId/:type" component={SearchResult} />
          <Route
            exact
            path="/quote/:quoteId/:type/:providerId"
            component={Profile}
          />
          <Route exact path="/profile/:providerId" component={Profile} />
          <Route exact path="/profile/:providerId/:type" component={Profile} />
          <Route exact path="/offer/:requestId" component={Profile} />
          <Route exact path="/:state/:suburb" component={SearchResult} />
          <Route exact path="/:state" component={SearchResult} />
          <Redirect to="/404" />
        </Switch>
        <Spinner
          fullScreen
          message={loader.message}
          isShowing={loader.isLoading}
        />
      </AppContext.Provider>
    </Provider>
  );
};

export default App;
