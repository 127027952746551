import React, { useState } from 'react';
import FormField from '../../FormField/FormField';
import {
  peopleMapping,
  serviceMapping,
  personServiceNameMapping,
} from '../../../static-data/personServiceMapping';
import Button from '../../Button/Button';

interface PeopleFormProps {
  setPeopleFormMode: (mode: 'select' | 'view') => void;
  onPersonServiceSelect: (personService: string[]) => void;
  onPersonServiceRemove: (idx: number) => void;
  peopleFormMode: 'select' | 'view';
  serviceList: string[];
}

const PeopleForm: React.FunctionComponent<PeopleFormProps> = props => {
  const {
    peopleFormMode,
    serviceList,
    onPersonServiceSelect,
    setPeopleFormMode,
    onPersonServiceRemove,
  } = props;

  const [person, setPerson] = useState('');
  const [service, setService] = useState('');

  const handlePersonChange = event => {
    setPerson(event.target.value);
  };

  const handleServiceChange = event => {
    setService(event.target.value);

    //if person was selected as well, create a person+service
    if (person && event.target.value) {
      onPersonServiceSelect([person, event.target.value]);
    }
  };

  const handleAddAnother = () => {
    setPerson('');
    setService('');
    setPeopleFormMode('select');
  };

  return (
    <>
      {peopleFormMode === 'select' && (
        <>
          {serviceList && !!serviceList.length && (
            <Button
              className="has-margin-n-top-4 has-margin-n-left-4"
              isClear
              onClick={() => {
                setPeopleFormMode('view');
              }} //back handler
            >
              <i className="fas fa-arrow-left" />
            </Button>
          )}
          <FormField
            label="Select who would like the service"
            labelClass="has-text-weight-bold is-family-primary is-size-7"
          >
            <div className="select is-fullwidth">
              <select
                name="person"
                value={person}
                onChange={handlePersonChange} //person select change
              >
                <option value="">select</option>
                {peopleMapping.map(person => (
                  <option key={person.value} value={person.value}>
                    {person.label}
                  </option>
                ))}
              </select>
            </div>
          </FormField>

          {!!person && (
            <FormField
              label="What Service would you like?"
              labelClass="has-text-weight-bold is-family-primary is-size-7"
            >
              <div className="select is-fullwidth">
                <select
                  name="service"
                  value={service}
                  onChange={handleServiceChange} //service select change
                >
                  <option value="">select</option>
                  {serviceMapping.map(service => {
                    if (service.visibleFor!.includes(person)) {
                      return (
                        <option key={service.value} value={service.value}>
                          {service.label}
                        </option>
                      );
                    }
                    return null;
                  })}
                </select>
              </div>
            </FormField>
          )}
        </>
      )}

      {peopleFormMode === 'view' && (
        <>
          {!!serviceList &&
            !!serviceList.length &&
            serviceList.map((personService, idx) => {
              return (
                <div key={idx} className="is-flex is-vcentered has-margin-y-3">
                  <div className="column is-paddingless">
                    <div className="is-flex">
                      <strong>{idx + 1}.</strong>
                      <div className="has-padding-left-3">
                        <strong>
                          {personServiceNameMapping[personService[0]]}
                        </strong>
                        <p>{personServiceNameMapping[personService[1]]}</p>
                      </div>
                    </div>
                  </div>
                  <div className="has-margin-left-auto is-flex is-vcentered">
                    <Button
                      isCircle
                      size="small"
                      color="gradient-1"
                      isOutlined
                      onClick={() => {
                        onPersonServiceRemove(idx);
                      }} //remove from list,
                    >
                      <span>✕</span>
                    </Button>
                  </div>
                </div>
              );
            })}
          <div className="has-margin-top-4 is-flex is-vcentered">
            <Button
              isCircle
              size="small"
              color="gradient-1"
              isOutlined
              onClick={handleAddAnother} //add another person
            >
              <span>
                <i className="fal fa-plus has-margin-right-1" />
                <i className="far fa-user" />
              </span>
            </Button>
            <div
              className="has-margin-left-4 has-text-weight-bold"
              onClick={() => {}} //add another person
            >
              Add {serviceList.length > 0 ? 'another' : 'a'} person
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PeopleForm;
