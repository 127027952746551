import { SET_QUOTE } from "./action-types";
import { Quote } from "../types/quote/quote";

export function setQuote(payload: Quote) {
  return {
    type: SET_QUOTE,
    payload
  };
}


