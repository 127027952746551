import React from 'react';
import Modal from '../Modal/Modal';

interface FreeCancellationProps {
  open?: boolean;
  onClose?: (e?: any) => void;
}

const FreeCancellation: React.FunctionComponent<FreeCancellationProps> = ({
  open = false,
  onClose,
}) => {
  return (
    <div>
      <Modal
        open={open}
        header="COVID cancellation policy "
        onClose={onClose}
        onBackdropClick={onClose}
        footerBtnLabel="Close"
        hasDefaultFooter
        onFooterBtnClick={onClose}
      >
        If your event gets cancelled or rescheduled as a result of corona virus
        (covid-19), we can apply the deposit paid as a credit to your FLAYR
        account, so that you can use it on a deposit for a future booking within
        the next 6 months.
      </Modal>
    </div>
  );
};
export default FreeCancellation;
