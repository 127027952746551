import React from 'react';
import './ProgressTracker.scss';

export interface IProgressTrackerItem {
  title: string;
  icon?: string;
  duration?: number;
}

interface ProgressTrackerProps {
  items?: IProgressTrackerItem[];
  color?: string;
}

const ProgressTracker: React.FunctionComponent<ProgressTrackerProps> = ({
  items = [],
  color = 'green',
}) => {
  const progressStyle = {
    '--progress-bg': `var(--${color})`,
  } as React.CSSProperties;
  return (
    <div className="ProgressTracker has-margin-bottom-5">
      <span className="ProgressTracker-progressbar" style={progressStyle} />
      <div className="ProgressTracker-item-container ProgressTracker-item-container-icon">
        {items.map((itm, key) => (
          <div className="ProgressTracker-item" key={key}>
            <span className="ProgressTracker-item-icon">
              <i className={itm.icon || ''} />
            </span>
          </div>
        ))}
      </div>
      <div className="ProgressTracker-item-container ProgressTracker-item-container-title">
        {items.map((itm, key) => (
          <div className="ProgressTracker-item" key={key}>
            <span className="ProgressTracker-title is-size-7">{itm.title}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
export default ProgressTracker;
