import * as React from 'react';
import classnames from 'classnames';

interface IRowProps {
  mobile?: boolean;
  marginless?: boolean;
  className?: string;
  gapless?: boolean;
  gap?: string;
  gapMobile?: string;
  gapTablet?: string;
  gapDesktop?: string;
  gapWidescreeen?: string;
  gapFullhd?: string;
  vCentered?: boolean;
  hCentered?: boolean;
  multiline?: boolean;
}
const Row: React.FC<IRowProps> = ({
  mobile,
  marginless,
  className = '',
  children,
  gapless,
  gap,
  vCentered,
  hCentered,
  multiline,
  gapMobile,
  gapTablet,
  gapDesktop,
  gapWidescreeen,
  gapFullhd,
}) => {
  return (
    <div
      className={classnames('columns', className, {
        'is-mobile': mobile,
        'is-marginless': marginless,
        'is-gapless': gapless,
        [`is-${gap} is-variable`]: gap,
        [`is-${gapMobile}-mobile is-variable`]: gapMobile,
        [`is-${gapTablet}-tablet is-variable`]: gapTablet,
        [`is-${gapDesktop}-desktop is-variable`]: gapDesktop,
        [`is-${gapWidescreeen}-widescreen is-variable`]: gapWidescreeen,
        [`is-${gapFullhd}-fullhd is-variable`]: gapFullhd,
        'is-vcentered': vCentered,
        'is-centered': hCentered,
        'is-multiline': multiline,
      })}
    >
      {children}
    </div>
  );
};

export default Row;
