import React, { useState, useEffect } from 'react';
import Switch from '../Switch/Switch';
import { IFilterBy } from '../../types/search/search';
import ProgressBar from '../ProgressBar/ProgressBar';
import classnames from 'classnames';
import { isMobile } from '../../utils';
interface FilterProps {
  selection: IFilterBy;
  enableHideBooked?: boolean;
  enableHideNew?: boolean;
  enableOfferedRequested?: boolean;
  onChange?: (e: any) => void;
}

const filterOptions: any[] = [
  {
    label: 'hide booked',
    value: 'hideBooked',
    visibleFor: 'enableHideBooked',
  },
  {
    label: 'hide new',
    value: 'hideNew',
    visibleFor: 'enableHideNew',
  },
  {
    label: 'only offers & requests',
    value: 'showInstantBooks',
    visibleFor: 'enableOfferedRequested',
  },
];

const Filter: React.FunctionComponent<FilterProps> = props => {
  const { onChange, selection } = props;
  const [showProgress, setShowProgress] = useState(false);

  useEffect(() => {
    let cancelled = false;
    if (!cancelled) {
      setShowProgress(true);
      setTimeout(() => {
        if (!cancelled) {
          setShowProgress(false);
        }
      }, 500);
    }
    return () => {
      cancelled = true;
    };
  }, [selection]);

  return (
    <div>
      <div className="has-padding-x-4 has-padding-top-4 has-padding-bottom-3 is-flex-desktop">
        {filterOptions.map(opt => (
          <React.Fragment key={opt.value}>
            {props[opt.visibleFor] && (
              <div
                className={classnames(
                  'columns is-mobile is-marginless is-vcentered has-margin-bottom-3',
                  {
                    'has-margin-right-4': !isMobile(),
                  }
                )}
              >
                <div className="column is-narrow is-paddingless is-flex is-vcentered has-margin-right-3">
                  <Switch
                    checked={!!selection[opt.value!]}
                    onChange={onChange}
                    name={opt.value}
                    id={opt.value}
                  />
                </div>
                <div className="column is-paddingless has-margin-top-1 is-capitalized">
                  <label htmlFor={opt.value}>
                    <small>{opt.label}</small>
                  </label>
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
      {showProgress && (
        <div
          style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}
        >
          <ProgressBar animate progressClassName="has-bg-gradient-logo" />
        </div>
      )}
    </div>
  );
};
export default Filter;
