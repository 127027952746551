import React, { useEffect } from 'react';
import FormField from '../../FormField/FormField';
import { timeMapping } from '../../../static-data/timeMapping';
import Button from '../../Button/Button';
import { parse, format } from 'date-fns';
import { parseDate } from '../../../utils';

interface DateTimePickerProps {
  dateString: string;
  timeString: string;
  setCalendarMode: (mode: 'date' | 'time') => void;
  onTimeChange: (time: string) => void;
}

const DateTimePicker: React.FunctionComponent<DateTimePickerProps> = props => {
  const { dateString, setCalendarMode, timeString, onTimeChange } = props;

  function handleSelect(e) {
    const { value } = e.target;
  }
  return (
    <>
      <FormField
        className="has-margin-bottom-5"
        labelClass="has-text-weight-bold is-family-primary is-size-7"
      >
        <Button
          className="has-text-left"
          isFullwidth
          hasIcon
          icon="far fa-calendar-alt"
          onClick={() => {
            setCalendarMode && setCalendarMode('date');
          }}
        >
          <span className="is-flex is-fullwidth">
            {!dateString && 'Pick a date'}

            {!!dateString && (
              <span>{format(parseDate(dateString), 'ddd, MMM DD YYYY')}</span>
            )}
          </span>
        </Button>
      </FormField>

      {/*  <FormField
        label="What time do you need to be ready by?"
        labelClass="has-text-weight-bold is-family-primary is-size-7"
      >
        <div className="select is-fullwidth">
          <select
            name="time"
            value={timeString}
            onChange={e => {
              onTimeChange(e.target.value);
            }}
          >
            <option value="">Pick a time</option>
            {timeMapping.map(time => (
              <option value={time.value} key={time.value}>
                {time.label}
              </option>
            ))}
          </select>
        </div>
      </FormField> */}
    </>
  );
};

export default DateTimePicker;
