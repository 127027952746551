import React from 'react';
import './Switch.scss';
import classnames from 'classnames';

interface SwitchProps {
  name?: string;
  checked?: boolean;
  id?: string;
  className?: string;
  onChange?: (evt: any) => void;
  disabled?: boolean;
}

const Switch: React.FunctionComponent<SwitchProps> = props => {
  const { name, checked, onChange, id, className = '', disabled } = props;

  return (
    <label
      htmlFor={id}
      className={classnames(`Switch ${className}`, {
        'Switch-disabled': disabled,
      })}
    >
      <input
        id={id}
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
        className="Switch-checkbox"
        disabled={disabled}
      />
      <span />
    </label>
    // <span
    //   className={`Switch__container ${className} ${
    //     disabled ? 'Switch__container--disabled' : ''
    //   }`}
    // >
    //   <input
    //     id={htmlFor}
    //     type="checkbox"
    //     name={name}
    //     checked={checked}
    //     onChange={onChange}
    //     className="Switch__checkbox"
    //     disabled={disabled}
    //   />
    //   <label htmlFor={htmlFor} />
    // </span>
  );
};

export default Switch;
