import React from 'react';
import Button from '../../../shared-components/Button/Button';
import { AppStore } from '../../../types/appStore';
import { connect } from 'react-redux';
import { Request, RequestStage } from '../../../types/request/request';
import { Stylist } from '../../../types/stylist/stylist';
import CheckLoader from '../../../shared-components/CheckLoader/CheckLoader';
import classnames from 'classnames';
import NoObligation from '../../../shared-components/NoObligation/NoObligation';
import './ProfileFooter.scss';
import ProfileInfo from '../../../shared-components/ProfileInfo/ProfileInfo';
import { getRequestStage } from '../../../utils';
import { Quote } from '../../../types/quote/quote';
import { CHECKOUT_URL, THANK_YOU_URL } from '../../../constants';

interface ProfileFooterProps {
  onBtnClick?: () => void;
  quote?: Quote;
  quoteId?: string;
  request?: Request;
  provider?: Stylist;
  price?: any;
  stage?: RequestStage;
  onCancelBooking?: () => void;
  className?: string;
  onLeftSectionClick?: (e?: any) => void;
}

const ProfileFooter: React.FunctionComponent<ProfileFooterProps> = ({
  quote,
  onBtnClick,
  quoteId,
  request,
  price,
  provider,
  onCancelBooking,
  className = '',
  stage,
  onLeftSectionClick,
}) => {
  return (
    <footer
      className={classnames(
        'is-sticky-bottom has-background-white has-padding-x-2 ',
        'columns is-marginless is-mobile has-shadow-top  is-vcentered',
        'ProfileFooter',
        className
      )}
    >
      <div className="column is-narrow is-size-14p is-max-width-50 has-line-height-4 has-padding-left-3 has-padding-right-0">
        {/* when expired */}
        {stage === RequestStage.expired && <p>Offer Expired</p>}

        {/* when not expired */}
        {stage !== RequestStage.expired && (
          <>
            {/* if no quote and pricing */}
            {(!quoteId || (quoteId && provider && !provider.pricing)) && (
              <>
                <p className="is-family-secondary has-text-dark-100">
                  <small>Compare pricing</small>
                </p>
                <span className="has-text-dark-50">
                  <small>from 1,500+ makeup artists</small>
                </span>
              </>
            )}

            {/* if quote and price but not booked out */}
            {!!price && !(quoteId && provider && provider.bookedOut) && (
              <>
                {/* when not checked availability */}
                {stage === RequestStage.noRequest && (
                  <div className="is-size-14p">
                    <small>
                      <NoObligation breakLines hideIcon />
                    </small>
                  </div>
                )}

                {/* when checked availability */}
                {request && request.stage === RequestStage.requested && (
                  <div>
                    Request more stylists. <br /> its free
                  </div>
                )}

                {/* when offered */}
                {stage === RequestStage.offered && !!provider && (
                  <div
                    className="has-margin-n-left-3 has-pointer"
                    onClick={onLeftSectionClick}
                  >
                    <div className="has-padding-left-3">
                      COVID cancellation policy
                      <div className="is-underlined">learn more</div>
                    </div>

                    {/* <ProfileInfo
                      img={provider.profileImage}
                      name={<small>{provider.firstName}</small>}
                      rating={provider.reviewScore}
                      reviews={provider.numOfReviews}
                      price={
                        provider.pricing ? provider.pricing.cost : undefined
                      }
                      deposit={
                        provider.pricing ? provider.pricing.take : undefined
                      }
                      imgSize="32"
                      forProfilePage
                      shortRatingDisabled
                      hideReviewsLong
                      hideInlineStarCount
                      showInlineReviews
                    /> */}
                  </div>
                  // <QuotePricing total={price.cost} deposit={price.take} />
                )}
              </>
            )}
          </>
        )}

        {request && request.stage === RequestStage.booked && (
          <a
            href={`mailto:hey@flayr.io?subject=booking cancellation for ${request.requestId}`}
          >
            <span className="has-text-danger">cancel booking</span>
          </a>
        )}
      </div>

      <div className="column is-narrow has-margin-left-auto ProfileFooter-cta has-padding-left-0 has-padding-right-3">
        {!(quoteId && provider && provider.bookedOut) &&
          provider!.isActive !== false && (
            <>
              {/* Offered State */}
              {quoteId && request && stage === RequestStage.offered && (
                <div>
                  <Button
                    type="link"
                    href={`${CHECKOUT_URL}/book/${request.requestId}`}
                    color="gradient-1"
                    isRounded
                  >
                    <span className="is-flex is-vcentered">
                      Book Now{' '}
                      <span className="has-margin-x-2 has-line-height-1">
                        <small style={{ fontSize: '5px' }}>
                          <i className="fas fa-circle" />
                        </small>
                      </span>{' '}
                      {price && price.take && <span>${price.take}</span>}
                    </span>
                  </Button>
                </div>
              )}

              {stage === RequestStage.expired && (
                <Button
                  isRounded
                  color="gradient-1"
                  className="has-padding-x-4"
                  onClick={onBtnClick}
                >
                  Request again
                </Button>
              )}

              {/* Requested state */}
              {quoteId && request && request.stage === RequestStage.requested && (
                <span className="is-flex is-vcentered has-text-weight-medium">
                  <span className="has-text-gradient-blue-blue-inverted has-margin-right-3">
                    <CheckLoader size={22} color={'var(--cyan)'} />
                    {/* <i className="far fa-check has-margin-right-3 is-size-4 is-inline" /> */}
                  </span>
                  <span className="has-text-centered has-line-height-4">
                    <span className="is-block">
                      <span className="has-text-gradient-blue-blue-inverted">
                        Checking
                      </span>
                    </span>
                    <span className="has-text-gradient-blue-blue-inverted">
                      Availability
                    </span>
                  </span>
                </span>
              )}

              {/* Requested state */}
              {quoteId &&
                request &&
                request.stage === RequestStage.declined && (
                  <div className="has-text-danger">Stylist Unavailable </div>
                )}

              {/* No Quote or No Request State */}
              {(!quoteId || (quoteId && !request)) && (
                <Button
                  color="gradient-1"
                  isRounded
                  className={classnames('has-padding-x-4', {
                    'is-invisible': (!provider || !provider.pricing) && quoteId,
                    'check-availability': !request && price,
                  })}
                  onClick={onBtnClick}
                >
                  <span className="is-size-16p">
                    {(!quoteId || (quoteId && provider && !provider.pricing)) &&
                      'Instant Quotes'}
                    {quoteId && (
                      <>{!request && price && 'Check Availability'}</>
                    )}
                  </span>
                </Button>
              )}
            </>
          )}
        {request && request.stage === RequestStage.booked && (
          <Button
            type="link"
            isRounded
            color="gradient-1"
            onClick={onBtnClick}
            className="has-padding-x-4"
            href={`${THANK_YOU_URL}/thank-you/${request.requestId}/${
              provider!.providerId
            }`}
          >
            Get Stylist Details
          </Button>
        )}

        {
          // if the request stage is delclined, or booked out,
          ((quote &&
            getRequestStage(quote, provider) === RequestStage.declined) ||
            // or if the provider is inactive
            !(provider && provider.isActive)) &&
            quote &&
            quote.shopify_id &&
            getRequestStage(quote, provider) !== RequestStage.booked && (
              <div className="has-text-danger">Booked Out</div>
            )
        }
      </div>
    </footer>
  );
};

const mapStateToProps = ({ quote, search }: AppStore) => ({
  quote,
  quoteId: quote.shopify_id,
  request: search.currentProfile.request,
  price: search.currentProfile.pricing,
  provider: search.currentProfile,
});
export default connect(mapStateToProps)(ProfileFooter);
