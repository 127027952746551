import React from 'react';
import { IFaq } from '../../../types/faq/faqs';

interface FaqsProps {
  faqs: IFaq[];
}

const Faqs: React.FunctionComponent<FaqsProps> = ({ faqs }) => {
  return (
    <div className="has-margin-top-3">
      {faqs.map((faq, key) => (
        <div className="is-size-16p is-flex has-margin-bottom-4" key={key}>
          <i className="far fa-angle-right has-margin-top-2" />
          <div className="has-margin-left-4">
            <div className="has-text-dark-100 has-line-height-5 has-margin-bottom-2 is-family-secondary">
              {faq.question}
            </div>
            <div className="has-line-height-7">{faq.answer}</div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default Faqs;
