import * as React from 'react';
import { Address } from '../../types/address';
import Autocomplete from 'react-google-autocomplete';
import './LocationInput.scss';

interface LocationInputProps {
  address?: Address;
  className?: string;
  name?: string;
  app?: any;
  placeholder?: string;
  id?: string;
  onChange?: (adress: Address) => void;
}
const google = window['google'];
class LocationInput extends React.Component<LocationInputProps> {
  state = {
    location: this.props.address ? this.props.address.formattedAddress : '',
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.address &&
      prevProps.address.formattedAddress !== this.props.address.formattedAddress
    ) {
      this.setState({
        location: this.props.address ? this.props.address.formattedAddress : '',
      });
    }
  }

  geocodeLatLng = (lat, lng): any => {
    const geocoder = new google.maps.Geocoder();
    return new Promise((resolve, reject) => {
      geocoder.geocode(
        { location: { lat: lat, lng: lng } },
        (results, status) => {
          if (results && results.length >= 1) {
            let bestResults = results[0];

            for (let r of results) {
              if (
                r.address_components.length >
                bestResults.address_components.length
              ) {
                bestResults = r;
              }
            }
            resolve(bestResults);
          } else {
            reject(status);
          }
        }
      );
    });
  };

  handlePlaceSelect = async addressObject => {
    if (!addressObject.geometry) return;
    // Extract City From Address Object
    const lat = addressObject.geometry.location.lat();
    const lng = addressObject.geometry.location.lng();

    let results = await this.geocodeLatLng(lat, lng);
    let postcode;
    let state;
    let streetNo = '';
    let street = '';
    let suburb;
    let country;

    for (let a of results.address_components) {
      if (a.types.includes('country')) {
        country = a.long_name;
      }
      if (a.types.includes('street_number')) {
        streetNo = a.short_name;
      }
      if (a.types.includes('route')) {
        street = a.short_name;
      }
      if (a.types.includes('postal_code')) {
        postcode = a.short_name;
      }
      if (a.types.includes('administrative_area_level_1')) {
        state = a.short_name;
      }
      if (a.types.includes('locality')) {
        suburb = a.short_name;
      }
      if (a.types.includes('neighborhood')) {
        suburb = a.short_name;
      }
    }

    const newAddress: Address = {
      name: addressObject.name,
      street: `${streetNo} ${street}`.trim(),
      suburb,
      state,
      postcode,
      country,
      formattedAddress: addressObject.formatted_address
        .split(',')
        .slice(0, -1)
        .join(', '),
      lat: addressObject.geometry.location.lat(),
      lng: addressObject.geometry.location.lng(),
    };
    if (!newAddress.state && newAddress.country) {
      newAddress.state = newAddress.country;
    }

    this.props.onChange && this.props.onChange(newAddress);
  };

  handleInputChange = e => {
    this.setState({ location: e.target.value });
  };

  handleClear = () => {
    this.setState({ location: '' });
    this.props.onChange &&
      this.props.onChange({
        street: '',
        suburb: '',
        state: '',
        postcode: '',
        country: '',
        formattedAddress: '',
        lat: undefined,
        lng: undefined,
      });
  };

  render() {
    return (
      <>
        <Autocomplete
          onPlaceSelected={this.handlePlaceSelect}
          autoComplete="off"
          id={this.props.id}
          fields={[
            'address_components',
            'geometry',
            'name',
            'formatted_address',
          ]}
          name={this.props.id}
          type="text"
          className={this.props.className || ''}
          placeholder={this.props.placeholder}
          types={[]}
          value={this.state.location}
          onChange={this.handleInputChange}
          componentRestrictions={{
            country: 'AU',
          }}
        />
        {!!this.state.location && (
          <a className="delete is-small" onClick={this.handleClear} />
        )}
      </>
    );
  }
}
export default LocationInput;
