import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import './FilterGroup.scss';
import Button from '../Button/Button';
import { ESortBy } from '../../types/search/search';
import { isMobile } from '../../utils';

interface FilterGroupProps {
  show: boolean;
  sortBy?: string;
  onSort?: (e: any) => void;
  searchTab?: React.ReactNode;
  filterTab?: React.ReactNode;
  selectedTab?: string;
  filtered?: boolean;
  onTabSelect?: (tab: string) => void;
  enableSearch?: boolean;
  enableSort?: boolean;
  enableFilter?: boolean;
  homeTab?: boolean;
}

const filterGroupBtns = [
  {
    name: 'search',
    icon: 'far fa-search',
  },
  {
    name: 'sort',
    icon: 'far fa-sort-amount-up',
  },
  {
    name: 'filter',
    icon: 'far fa-filter',
  },
];

const FilterGroup: React.FunctionComponent<FilterGroupProps> = ({
  show,
  selectedTab = 'search',
  onTabSelect,
  searchTab,
  filterTab,
  filtered,
  sortBy,
  onSort,
  enableSearch = false,
  enableFilter = false,
  enableSort = false,
  homeTab
}) => {
  function handleBtnClick(type) {
    onTabSelect && onTabSelect(type);
  }

  function handleSortChange(e) {
    onSort && onSort(e.target.value);
  }

  return (
    <>
      <div
        className={classnames('FilterGroup', {
          'FilterGroup-shown': show,
        })}
      >
        <div className="columns is-mobile is-marginless has-padding-x-3 has-padding-y-2 has-background-white">
          <div className="column is-paddingless is-relative is-flex is-vcentered is-narrow has-margin-right-3 is-hidden-tablet">
            <span>
              <span className="is-size-5 is-family-secondary is-line-height-1  has-margin-right-2 has-text-gradient-logo has-text-weight-medium">
                Flayr
              </span>
            </span>
          </div>
          {filterGroupBtns.map(btn => {
            // if (
            //   (btn.name === 'search' && !enableSearch) ||
            //   (btn.name === 'sort' && !enableFilter) ||
            //   (btn.name === 'filter' && !enableFilter)
            // ) {
            //   return (
            //     <div className="column is-paddingless" key={btn.name}>
            //       <Button isClear />
            //     </div>
            //   );
            // }
            return (
              <div
                key={btn.name}
                className={classnames('column is-paddingless is-relative', {
                  'is-hidden-tablet': btn.name !== 'sort',
                  'has-margin-right-3': isMobile(),
                  'is-invisible':
                    (btn.name === 'search' && !enableSearch) ||
                    (btn.name === 'sort' && !enableFilter) ||
                    (btn.name === 'filter' && !enableFilter),
                })}
              >
                <Button
                  isClear
                  isFullwidth
                  className="has-padding-x-1 is-capitalized has-text-dark-50"
                  onClick={() => handleBtnClick(btn.name)}
                >
                  <span
                    className={classnames('is-inline-block has-padding-y-1', {
                      'has-text-purple':
                        (btn.name === 'sort' && !!sortBy) ||
                        (btn.name === 'filter' && filtered),
                    })}
                  >
                    <i className={btn.icon} /> {btn.name}
                  </span>
                </Button>
                {btn.name === 'sort' && (
                  <select
                    name="sort"
                    id="sort"
                    className="FilterGroup-sort-select is-size-16p"
                    value={sortBy}
                    onChange={handleSortChange}
                    disabled={!enableSort}
                  >
                    <option value={ESortBy.price}>Price</option>
                    <option value={ESortBy.reviews}>Reviews</option>
                    {homeTab &&
                      <option value={ESortBy.distance}>Distance</option>
                    }
                  </select>
                )}
              </div>
            );
          })}
        </div>
        <section
          className={classnames('has-padding-top-2', {
            'has-bg-gradient-1': isMobile(),
          })}
        >
          <div
            className={classnames({
              'FilterGroup-item': true,
              'FilterGroup-item-hidden': isMobile() && !selectedTab,
            })}
          >
            {/* {selectedTab == 'search' && searchTab} */}
            {(!isMobile() || selectedTab === 'filter') &&
              enableFilter &&
              filterTab}
          </div>
        </section>
      </div>
    </>
  );
};
export default FilterGroup;
