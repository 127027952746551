import * as React from 'react';
import classnames from 'classnames';

interface ButtonProps {
  type?: 'button' | 'submit' | 'reset' | 'link';
  className?: string;
  color?:
    | ''
    | 'default'
    | 'white'
    | 'light'
    | 'dark'
    | 'black'
    | 'text'
    | 'primary'
    | 'link'
    | 'info'
    | 'success'
    | 'warning'
    | 'danger'
    | 'gradient-1'
    | 'gradient-2'
    | 'gradient-3'
    | 'gradient-4'
    | 'gradient-logo'
    | 'gradient-dark'
    | 'gradient-blue-blue'
    | 'gradient-blue-blue-inverted';
  size?: 'small' | 'normal' | 'medium' | 'large';
  isFullwidth?: boolean;
  isOutlined?: boolean;
  isInverted?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  isRounded?: boolean;
  isCircle?: boolean;
  hasIcon?: boolean;
  icon?: string;
  href?: string;
  isClear?: boolean;
  outlineSize?: number;
  onClick?: (e: any) => void;
}
const Button: React.SFC<ButtonProps> = props => {
  const {
    type = 'button',
    children,
    className = '',
    color = '',
    size = '',
    isOutlined,
    isFullwidth,
    isInverted,
    isDisabled,
    isRounded,
    isCircle = false,
    isLoading,
    hasIcon,
    icon = 'fas fa-arrow-right',
    href = '',
    isClear = false,
    onClick,
    outlineSize = 1,
  } = props;
  const btnClassNames = {
    [className]: className,
    [`is-${color}`]: !!color,
    [`is-${size}`]: !!size,
    'is-outlined': isOutlined,
    'is-fullwidth': isFullwidth,
    'is-inverted': isInverted,
    'is-rounded': isRounded,
  };
  const style = {
    '--outline-size': `${outlineSize}px`,
  } as React.CSSProperties;
  if (type !== 'link') {
    return (
      <button
        type={type}
        className={classnames(
          'button',
          btnClassNames,
          { 'has-icon': hasIcon },
          { 'is-circle': isCircle },
          { 'is-clear': isClear },
          { 'is-loading': isLoading }
        )}
        disabled={isDisabled}
        onClick={onClick}
        style={style}
      >
        {children}
        {hasIcon && (
          <span className="icon">
            <i className={icon} />
          </span>
        )}
      </button>
    );
  } else {
    return (
      <a
        href={href}
        className={classnames('button', btnClassNames, { 'has-icon': hasIcon })}
        style={style}
      >
        {children}
        {hasIcon && (
          <span className="icon">
            <i className="fas fa-arrow-right" />
          </span>
        )}
      </a>
    );
  }
};

export default Button;
