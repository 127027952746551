import React from 'react';

export default function useToggle(open = false) {
  const [value, setValue] = React.useState({
    on: open,
    off: !open,
  });

  function toggle() {
    setValue({
      on: !value.on,
      off: !value.off,
    });
  }

  return {
    on: value.on,
    off: value.off,
    toggle,
  };
}
