import { Option } from '../types/option';

export const peopleMapping: Option[] = [
  {
    label: 'Non Bridal',
    value: 'nonbridal',
  },
  {
    label: 'Bride',
    value: 'bride',
  },
  {
    label: 'Mother of Bride/Groom',
    value: 'mother',
  },
  {
    label: 'Bridesmaid',
    value: 'bridesmaid',
  },
  {
    label: 'Flower-girl',
    value: 'flowergirl',
  },
];

export const serviceMapping: Option[] = [
  {
    label: 'Hair only',
    value: '_hair_only',
    visibleFor: ['bride', 'mother', 'bridesmaid'],
  },
  {
    label: 'Makeup only',
    value: '_makeup_only',
    visibleFor: ['bride', 'mother', 'bridesmaid', 'nonbridal'],
  },
  {
    label: 'Makeup & Hair',
    value: '_makeup_and_hair',
    visibleFor: ['bride', 'mother', 'bridesmaid', 'flowergirl'],
  },
  {
    label: 'Makeup & Hair up',
    value: '_makeup_and_hair_up',
    visibleFor: ['nonbridal'],
  },
  {
    label: 'Makeup & Hair down',
    value: '_makeup_and_hair_down',
    visibleFor: ['nonbridal'],
  },
  {
    label: 'Hair up',
    value: '_hair_up',
    visibleFor: ['nonbridal'],
  },
  {
    label: 'Hair down',
    value: '_hair_down',
    visibleFor: ['nonbridal'],
  },
];

export const personServiceNameMapping: any = {
  nonbridal: 'Non Bridal',
  bride: 'Bride',
  mother: 'Mother of Bride/Groom',
  bridesmaid: 'Bridesmaid',
  flowergirl: 'Flower-girl',
  _hair_only: 'Hair only',
  _makeup_only: 'Makeup only',
  _makeup_and_hair: 'Makeup & Hair',
  _makeup_and_hair_up: 'Makeup & Hair up',
  _makeup_and_hair_down: 'Makeup & Hair down',
  _hair_up: 'Hair up',
  _hair_down: 'Hair down',
};

export const serviceMappingHaircut: Option[] = [
  { value: 'haircut_women', label: `women's haircut`, visibleFor: ['haircut'] },
  {
    value: 'haircut_women_blowdry',
    label: `women's haircut + blowdry`,
    visibleFor: ['haircut'],
  },
  { value: 'haircut_men', label: `men's haircut`, visibleFor: ['haircut'] },
  { value: 'haircut_kids', label: `kids' haircut`, visibleFor: ['haircut'] },
  {
    value: 'coloring_full_foil',
    label: `hair colouring full foil`,
    visibleFor: ['coloring'],
  },
  {
    value: 'coloring_half_foil',
    label: `hair colouring half foil`,
    visibleFor: ['coloring'],
  },
  {
    value: 'coloring_balayage',
    label: `hair colouring balayage`,
    visibleFor: ['coloring'],
  },
  {
    value: 'coloring_full_head',
    label: `full head hair colouring`,
    visibleFor: ['coloring'],
  },
  {
    value: 'coloring_regrowth',
    label: `hair regrowth colouring`,
    visibleFor: ['coloring'],
  },
  {
    value: 'treatment_keratin',
    label: `keratin treatment`,
    visibleFor: ['treatment'],
  },
  {
    value: 'treatment_perm',
    label: `perm treatment`,
    visibleFor: ['treatment'],
  },
  {
    value: 'styling_hair_up',
    label: `hair up styling`,
    visibleFor: ['styling'],
  },
  {
    value: 'styling_hair_down',
    label: `hair down styling`,
    visibleFor: ['styling'],
  },
];

export const serviceTypeMappingHaircut: Option[] = [
  { value: 'haircut', label: 'haircut' },
  { value: 'coloring', label: 'colouring' },
  { value: 'treatment', label: 'treatment' },
  { value: 'styling', label: 'styling' },
];

export const serviceNameMappingHaircut = {
  haircut_women: `women's haircut`,
  haircut_women_blowdry: `women's haircut + blowdry`,
  haircut_men: `men's haircut`,
  haircut_kids: `kids' haircut`,
  coloring_full_foil: `hair colouring full foil`,
  coloring_half_foil: `hair colouring half foil`,
  coloring_balayage: `hair colouring balayage`,
  coloring_full_head: `full head hair colouring`,
  coloring_regrowth: `hair regrowth colouring`,
  treatment_keratin: `keratin treatment`,
  treatment_perm: `perm treatment`,
  styling_hair_up: `hair up styling`,
  styling_hair_down: `hair down styling`,
};
