import React from 'react';
import { getRandomNumber } from '../../utils';

interface TimeSpotAlertProps {}

const TimeSpotAlert: React.FunctionComponent<TimeSpotAlertProps> = props => {
  const number = getRandomNumber(1, 5);
  return (
    <div className="has-text-danger is-flex is-vcentered has-padding-4">
      <div className="is-size-4 has-margin-right-3">
        <i className="far fa-exclamation-triangle" />
      </div>
      <div className="has-line-height-4">
        <small>
          {number} other {number === 1 ? 'person' : 'people'} looking at this
          time spot. Book now to secure the time.
        </small>
      </div>
    </div>
  );
};
export default TimeSpotAlert;
