import React from 'react';

interface NoObligationProps {
  breakLines?: boolean;
  hideIcon?: boolean;
}

const NoObligation: React.FunctionComponent<NoObligationProps> = ({
  breakLines,
  hideIcon,
}) => {
  return (
    <div className="has-text-purple is-flex is-vcentered">
      {!hideIcon && (
        <span className="has-margin-right-3">
          <i className="far fa-check-circle" />
        </span>
      )}
      <div>
        <span>No credit card required, </span>
        {breakLines && <br />}
        <span>no obligation</span>
      </div>
    </div>
  );
};
export default NoObligation;
