import React from 'react';
import { Stylist } from '../../../types/stylist/stylist';
import { AppStore } from '../../../types/appStore';
import { connect } from 'react-redux';
import ProviderCard from '../../../shared-components/ProviderCard/ProviderCard';
import { isArray } from 'util';
import { RequestStage } from '../../../types/request/request';
import ScrollPanel from '../../../shared-components/ScrollPanel/ScrollPanel';
import { searchReducer } from '../../../reducers/search';
import { IFilterBy } from '../../../types/search/search';
import './FeaturedList.scss';
import ProvidersSlide from '../../../shared-components/ProvidersSlide/ProvidersSlide';
import { serviceBasedContent } from '../../../static-data/contentMapping';

interface FeaturedListProps {
  featuredHomeProviders?: Stylist[];
  featuredMobileProviders?: Stylist[];
  type: string;
  onProfileClick?: (p: Stylist) => void;
  filterBy?: IFilterBy;
  service?: string;
}

const FeaturedList: React.FunctionComponent<FeaturedListProps> = ({
  featuredHomeProviders,
  featuredMobileProviders = [],
  type,
  onProfileClick,
  filterBy,
  service = '',
}) => {
  if (type === 'home') {
    return null;
  }
  if (
    type === 'mobile' &&
    (!featuredMobileProviders || !featuredMobileProviders.length)
  ) {
    return null;
  }

  if (filterBy && filterBy.showInstantBooks) {
    return null;
  }

  return (
    <div>
      <ProvidersSlide
        providers={type === 'mobile' ? featuredMobileProviders : []}
        type="mobile"
        heading="Flayr recommended"
        subHeading={`Top ${
          serviceBasedContent[service].stylist
        }s with amazing reviews`}
        onProfileClick={onProfileClick}
      />
      <div className="is-size-4 has-text-weight-medium has-margin-top-3 has-margin-bottom-3-desktop has-padding-x-4">
        All {serviceBasedContent[service].stylist}s
      </div>
    </div>
  );
};

const mapStateToProps = ({ search, quote }: AppStore) => {
  let featuredMobileProviders: Stylist[] = [];
  if (search.mobileProviders && search.mobileProviders.length) {
    featuredMobileProviders = search.mobileProviders.filter(
      provider => provider.isFeatured && !provider.bookedOut
    );
  }
  return {
    featuredMobileProviders,
    filterBy: search.filterBy,
    service: quote.type,
  };
};
export default connect(mapStateToProps)(FeaturedList);
