export const personService: { [key: string]: string } = {
  _makeup_only: 'Makeup Only',
  _hair_only: 'Hair Only',
  _makeup_and_hair: 'Makeup & Hair',
  _makeup_and_hair_up: 'Makeup & Hair Up',
  _makeup_and_hair_down: 'Makeup & Hair Down',
  _hair_down: 'Hair Down',
  _hair_up: 'Hair Up',

  bride: 'Bride',
  bridesmaid: 'Bridesmaid',
  nonbridal: 'Non-Bridal',
  mother: 'Mother Of Bride/Groom',
  flowergirl: 'Flower Girl',
};
