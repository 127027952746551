import React, { useState, useEffect } from 'react';
import './QuoteForm.scss';
import Button from '../Button/Button';
import LocationInput from '../LocationInput/LocationInput';
import { Address } from '../../types/address';
import CalendarTimeForm from './CalendarTimeForm/CalendarTimeForm';
import Modal from '../Modal/Modal';
import { parse, format } from 'date-fns';
import PeopleForm from './PeopleForm/PeopleForm';
import classnames from 'classnames';
import { Quote } from '../../types/quote/quote';
import { createQuoteAPI } from '../../services/search.services';
import { AppStore } from '../../types/appStore';
import { Dispatch } from 'redux';
import { setQuote } from '../../actions/quote-actions';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { setHasLoaded } from '../../actions/search-actions';
import { isMobile } from '../../utils';
import queryString from 'query-string';
import moment from 'moment';
import ServiceForm from './ServiceForm/ServiceForm';
import PeopleFormHaircut from './PeopleFormHaircut/PeopleFormHaircut';
import FormField from '../FormField/FormField';
import Axios from 'axios';
import { FLAYR_LIFE_API_URL, FLAYR_LIFE_SPA_URL } from '../../constants';
import { Spinner } from '../Spinner/Spinner';

interface QuoteFormProps extends RouteComponentProps {
  quote: Quote;
  setQuoteData: (quote: Quote) => void;
  setHasLoadedData: (hasLoaded: boolean) => void;
  getQuoteData: (quoteId: string) => void;
  getInitialData?: (suburb: string, state: string) => void;
  setSearchLoading?: (loading: boolean) => void;
  id?: string;
  locationModalOpen: boolean;
  setLocationModalOpen: (open: boolean) => void;
  forProfilePage?: boolean;
  onQuoteSearch?: () => void;
}

const QuoteForm: React.FunctionComponent<QuoteFormProps> = (props) => {
  const {
    quote,
    setQuoteData,
    getQuoteData,
    getInitialData,
    history,
    location,
    locationModalOpen,
    setLocationModalOpen,
    forProfilePage,
    onQuoteSearch,
    id = 'qf1',
  } = props;

  const [dateTimeOpen, setDateTimeOpen] = useState(false);
  const [peopleModalOpen, setPeopleModalOpen] = useState(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [calendarMode, setCalendarMode] = useState<'date' | 'time'>('time');
  const [peopleFormMode, setPeopleFormMode] = useState<'select' | 'view'>(
    'select'
  );

  useEffect(() => {
    let query = queryString.parse(location.search);
    if (query.date && moment(query.date).isValid()) {
      setQuoteElement({ event_date: moment(query.date).format('YYYY-MM-DD') });
    }

    if (quote.person_service_list.length) {
      setPeopleFormMode('view');
    }
  }, [quote.person_service_list]);

  const createQuote = async () => {
    showMissingFormFields();
    let quoteFieldsExist =
      quote.person_service_list &&
      quote.num_of_people &&
      quote.suburb &&
      quote.postcode &&
      quote.state &&
      quote.event_date;
    if (quoteFieldsExist) {
      runQuoteSearch({ ...quote });
      //close all
      setDateTimeOpen(false);
      setPeopleModalOpen(false);
      setLocationModalOpen(false);
    }
  };

  function showMissingFormFields() {
    if (quote.person_service_list.length < 1 && !peopleModalOpen) {
      //prompt people if no data
      setDateTimeOpen(false);
      setPeopleModalOpen(true);
      setLocationModalOpen(false);
    } else if (!quote.event_date && !dateTimeOpen) {
      //prompt date modal if no data
      setDateTimeOpen(true);
      setPeopleModalOpen(false);
      setLocationModalOpen(false);
    } else if (
      (!quote.suburb || !quote.postcode || !quote.state) &&
      !locationModalOpen
    ) {
      //prompt location if no location data;
      setDateTimeOpen(false);
      setPeopleModalOpen(false);
      setLocationModalOpen(true);
    } else {
      //close modals if they are open.
      setDateTimeOpen(false);
      setPeopleModalOpen(false);
      setLocationModalOpen(false);
    }
  }

  const handleLocationChange = (location: Address) => {
    if (location.postcode && location.suburb && location.state) {
      setQuoteData({
        display_suburb: location.name,
        postcode: location.postcode,
        suburb: location.suburb,
        state: location.state,
        shopify_id: quote.shopify_id,
        person_service_list: quote.person_service_list,
        lat: location.lat,
        lng: location.lng,
      });
    }

    //if location change has the 3 required items
    if (location.suburb && location.state && location.postcode) {
      setLocationModalOpen(false);
      let quoteFieldsExist =
        quote.person_service_list && quote.num_of_people && quote.event_date;
      //create new quote otherwise set location
      if (quoteFieldsExist) {
        runQuoteSearch({
          person_service_list: quote.person_service_list,
          num_of_people: quote.num_of_people,
          event_date: quote.event_date,
          shopify_id: quote.shopify_id,
          state: location.state,
          postcode: location.postcode,
          suburb: location.suburb,
          country: quote.country,
          type: quote.type,
          ref_id: quote.ref_id,
        });
      } else {
        //set the location search only
        if (getInitialData) setLocationSearch(location.suburb, location.state);
        if (!dateTimeOpen) setDateTimeOpen(true);
      }
    }
  };

  const handlePeopleModalOpen = () => {
    setPeopleModalOpen(!peopleModalOpen);
  };

  const handleLocationModalOpen = () => {
    setLocationModalOpen(!locationModalOpen);
  };

  const handleDateTimeOpen = () => {
    setDateTimeOpen(!dateTimeOpen);
  };

  const onCalendarDateSelect = (date: string) => {
    setQuoteElement({ event_date: date });
    setCalendarMode('time');
  };

  const onTimeChange = (time: string) => {
    setQuoteElement({ event_time: time });
  };

  const onPersonServiceSelect = (personService: string[]) => {
    //append person+service to redux value
    let currServiceList = [...quote.person_service_list, personService];
    setQuoteElement({
      person_service_list: currServiceList,
      num_of_people: currServiceList.length,
    });

    //set peopleformMODE to VIEW since we added person;
    setPeopleFormMode('view');
  };

  const onPersonServiceRemove = (idx: number) => {
    //remove and append new person service
    let currServiceList = [...quote.person_service_list];
    currServiceList.splice(idx, 1);
    setQuoteElement({
      person_service_list: currServiceList,
      num_of_people: currServiceList.length,
    });
  };

  const setQuoteElement = (element: Object) => {
    //set individual quote elements, avoids code repeat.
    setQuoteData({
      person_service_list: quote.person_service_list,
      shopify_id: quote.shopify_id,
      state: quote.state,
      suburb: quote.suburb,
      ...element,
    });
  };

  const runQuoteSearch = async (postData: Quote) => {
    try {
      flayrLifeSearchSubmit(postData);
      // if (onQuoteSearch) onQuoteSearch(); //callback to profile page to let know that quote is searching;
      // let quote: Quote = (await createQuoteAPI(postData)).data;
      // setQuoteData(quote);
      // if (!forProfilePage)
      //   history.push('/quote/' + quote.shopify_id + '/mobile');
      // getQuoteData!(quote.shopify_id);
    } catch (err) {
      console.error(err);
    }
  };

  const flayrLifeSearchSubmit = async (postData: Quote) => {
    setLoading(true);
    let data = {
      coordinates: [postData.lng, postData.lat],
      eventDate: postData.event_date,
      personServiceList: postData.person_service_list,
    };
    const res = await Axios.post(`${FLAYR_LIFE_API_URL}/v1/legacy/quote`, data);
    const id = res.data.data.search._id;
    (window as any).location = `${FLAYR_LIFE_SPA_URL}/quotes/${id}`;
    setLoading(false);
  };

  const setLocationSearch = async (suburb: string, state: string) => {
    //setQuoteData({ ...quote, suburb, state });
    history.push(
      `/${state.toLowerCase()}/${suburb.replace(/\s+/g, '-').toLowerCase()}`
    );
    if (getInitialData) getInitialData(suburb, state);
  };

  //take quote details and show on search form;
  const quoteLocation = {
    formattedAddress:
      quote.suburb && quote.state && quote.postcode
        ? `${quote.display_suburb || quote.suburb} ${quote.state}`
        : '',
    suburb: quote.suburb,
    postcode: '',
    state: quote.state,
  };

  function handleServiceChange(e) {
    // reset person service as well
    // because the user previously may have select an option that is
    // incompatible with the current service selected
    setQuoteElement({ type: e.target.value, person_service_list: [] });
  }

  return (
    <>
      {(!forProfilePage ||
        (forProfilePage && !isMobile() && (!quote || !quote.shopify_id))) && (
        <section className="QuoteForm columns is-multiline is-marginless">
          <div className="is-size-5 is-hidden-mobile has-margin-bottom-3 has-margin-n-top-3 has-text-white">
            Search
          </div>
          {!quoteLocation.formattedAddress && (
            <p className="is-size-14p has-line-height-4 has-margin-bottom-3 is-hidden-desktop has-text-white">
              enter the location to narrow down the search to artists near you
            </p>
          )}

          {/*Location button*/}
          <div className="columns is-marginless is-mobile is-multiline has-scroll-x-hidden">
            <div className="column is-12 is-paddingless is-narrow has-margin-bottom-3">
              <Button
                className={classnames(
                  'has-text-weight-medium has-text-dark-75 is-size-14p has-text-left',
                  {
                    'has-padding-y-3': !isMobile(),
                    'has-padding-x-3': isMobile(),
                  }
                )}
                isFullwidth
                isRounded={!isMobile()}
                onClick={handleLocationModalOpen}
              >
                <span className="has-text-left-mobile has-text-centered-desktop is-fill-x has-text-truncated">
                  <i className="fas fa-map-marker-alt has-margin-right-2" />
                  {!quoteLocation.formattedAddress && (
                    <span>
                      location{' '}
                      <small>
                        <small>e.g. Glebe, Hilton Sydney</small>
                      </small>
                    </span>
                  )}
                  {!!quoteLocation.formattedAddress && (
                    <span>
                      {quote.display_suburb || quoteLocation.suburb}{' '}
                      <small>
                        <small>{quoteLocation.state}</small>
                      </small>
                    </span>
                  )}
                </span>
              </Button>
            </div>

            {/* event date button */}
            <div className="column is-paddingless is-narrow has-margin-right-3">
              <Button
                className={classnames(
                  'has-text-weight-medium has-text-dark-75 is-size-14p has-text-left',
                  {
                    'has-padding-y-3': !isMobile(),
                  }
                )}
                isRounded={!isMobile()}
                onClick={handleDateTimeOpen}
              >
                {!quote.event_date && (
                  <span>
                    <i className="far fa-calendar-alt" /> date
                  </span>
                )}
                {!!quote.event_date && (
                  <span>
                    {format(
                      parse(new Date(quote.event_date)),
                      'ddd MMM DD YYYY'
                    )}
                    &nbsp;
                  </span>
                )}
              </Button>
            </div>

            {/*num of people button*/}
            <div className="column is-paddingless is-narrow">
              <Button
                className={classnames(
                  'has-text-weight-medium has-text-dark-75 is-size-14p has-text-left',
                  {
                    'has-padding-y-3': !isMobile(),
                  }
                )}
                isRounded
                onClick={handlePeopleModalOpen}
              >
                <span className="is-inline-block has-padding-x-3">
                  {!quote.person_service_list.length && '+ '}
                  <i
                    className={classnames('fas', {
                      'fa-user': quote.person_service_list.length <= 1,
                      'fa-user-friends': quote.person_service_list.length === 2,
                      'fa-users': quote.person_service_list.length > 2,
                    })}
                  />
                  {!!quote.person_service_list.length &&
                    ` ${quote.person_service_list.length} `}
                </span>
              </Button>
            </div>
          </div>
        </section>
      )}
      <>
        {/* date time picker modal */}
        <Modal
          open={dateTimeOpen}
          onBackdropClick={() => {
            setDateTimeOpen(false);
          }}
          header="Date"
          helper="Select date for exact pricing & availability"
          onClose={() => {
            setDateTimeOpen(false);
          }}
        >
          <div className="has-text-dark-75">
            <CalendarTimeForm
              onCalendarDateSelect={onCalendarDateSelect}
              calendarMode={calendarMode}
              dateString={quote.event_date || ''}
              setCalendarMode={setCalendarMode}
              timeString={quote.event_time || ''}
              onTimeChange={onTimeChange}
            />

            <div className="has-margin-top-4 has-text-right">
              <Button
                isDisabled={!quote.event_date}
                color="gradient-2"
                onClick={createQuote}
              >
                OK
              </Button>
            </div>
          </div>
        </Modal>
      </>

      {/*num of ppl modal*/}
      <Modal
        open={peopleModalOpen}
        onBackdropClick={handlePeopleModalOpen}
        header={
          <div className="">
            <span>Service</span>
            {!!quote.type && (
              <div>
                <ServiceForm
                  service={quote.type}
                  onChange={handleServiceChange}
                />
              </div>
            )}
          </div>
        }
        onClose={handlePeopleModalOpen}
      >
        {!quote.type && (
          <FormField label="Please select a service">
            <ServiceForm service={quote.type} onChange={handleServiceChange} />
          </FormField>
        )}
        {quote.type === 'makeup' && (
          <div className="has-text-dark-75">
            {/* to correctly mount and unmount the component */}
            <PeopleForm
              peopleFormMode={peopleFormMode}
              setPeopleFormMode={setPeopleFormMode}
              serviceList={quote.person_service_list}
              onPersonServiceSelect={onPersonServiceSelect}
              onPersonServiceRemove={onPersonServiceRemove}
            />
          </div>
        )}

        {quote.type === 'haircut' && (
          <PeopleFormHaircut
            serviceList={quote.person_service_list}
            setPeopleFormMode={setPeopleFormMode}
            peopleFormMode={peopleFormMode}
            onPersonServiceSelect={onPersonServiceSelect}
            onPersonServiceRemove={onPersonServiceRemove}
          />
        )}

        <div className="has-margin-top-4 has-text-right">
          <Button
            isDisabled={!quote.person_service_list.length}
            color="gradient-2"
            onClick={createQuote}
          >
            OK
          </Button>
        </div>
      </Modal>

      {/*location modal*/}
      <Modal
        open={locationModalOpen}
        onBackdropClick={handleLocationModalOpen}
        header="Location"
        helper="Find which stylists are available in your area"
        onClose={handleLocationModalOpen}
      >
        <div className="has-text-dark-75">
          {/* to correctly mount and unmount the component */}
          <div className="LocationInput">
            <LocationInput
              className="input"
              placeholder="Location e.g. Glebe, Hilton Sydney"
              id={id}
              address={quoteLocation}
              onChange={handleLocationChange}
            />
          </div>
          <div className="has-margin-top-4 has-text-right">
            <Button
              isDisabled={!quote.suburb && !quote.state && !quote.postcode}
              color="gradient-2"
              onClick={createQuote}
            >
              OK
            </Button>
          </div>
        </div>
      </Modal>
      <Spinner isShowing={loading} fullScreen />
    </>
  );
};

const mapStateToProps = ({ quote }: AppStore) => {
  return {
    quote: quote,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setQuoteData: (quote: Quote) => dispatch(setQuote(quote)),
    setHasLoadedData: (hasLoaded: boolean) => dispatch(setHasLoaded(hasLoaded)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(QuoteForm));
