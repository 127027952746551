import { Pricing } from '../quote/pricing';
import { Request } from '../request/request';

export interface Stylist {
  homeStudioAddress?: HomeStudioAddress;
  coverImage: string;
  profileImage: string;
  bio?: string;
  firstName: string;
  reviewScore: number;
  numOfReviews: number;
  providerId: string;
  mobile?: number;
  bookedOut?: boolean;
  pricing?: Pricing;
  makeupBrands?: string[];
  request?: Request;
  isActive?: boolean;
  images?: any[];
  suburb?: string;
  state?: string;
  distance?: number;
  isFeatured?: boolean;
  hairCutting?: HairCutting;
  location?: HomeStudioLocation;
  covidTrained?: boolean;
}

export interface HairCutting {
  coverImage: string;
  profileBlurb: string;
  offersHome: boolean;
  offersMobile: boolean;
  mobilePricing: {
    haircutWomen: number;
    haircutWomenBlowdry: number;
    haircutMen: number;
    haircutKids: number;
    coloringFullFoil: number;
    coloringHalfFoil: number;
    coloringBalayage: number;
    coloringFullHead: number;
    coloringRegrowth: number;
    treatmentKeratin: number;
    treatmentPerm: number;
    stylingHairUp: number;
    stylingHairDown: number;
  };
  homePricing: {
    haircutWomen: number;
    haircutWomenBlowdry: number;
    haircutMen: number;
    haircutKids: number;
    coloringFullFoil: number;
    coloringHalfFoil: number;
    coloringBalayage: number;
    coloringFullHead: number;
    coloringRegrowth: number;
    treatmentKeratin: number;
    treatmentPerm: number;
    stylingHairUp: number;
    stylingHairDown: number;
  };
  instagram: {
    username: string;
    verified: true;
  };
  homeStudioAddress: {
    fullAddress: string;
    country: string;
    suburb: string;
    postcode: string;
    state: string;
    streetAddress: string;
    apartment: string;
  };
  brands: string[];
}

export interface HomeStudioAddress {
  suburb: string;
  state: string;
  postcode: string;
}
export interface HomeStudioLocation {
  type: string;
  coordinates: number[];
}
export interface Image {
  src: string;
}

export enum EReview {
  friendliness = 'friendliness',
  nailedTheLook = 'nailedTheLook',
  onTime = 'onTime',
  wouldRecommend = 'wouldRecommend',
}

export interface IReview {
  summary: {
    [EReview.friendliness]: number;
    [EReview.nailedTheLook]: number;
    [EReview.onTime]: number;
    [EReview.wouldRecommend]: number;
  };
  review: IReviewItem;
}

export interface IReviewItem {
  customer: string;
  providerId?: string;
  totalRating: number;
  extraComments: string;
  title?: string;
  flayrReply?: string;
  reply?: string;
  reviewDate: string;
}
