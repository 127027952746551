import React from 'react';
import './StarRating.scss';

interface StarRatingProps {
  rating?: number;
}
const StarRating: React.FC<StarRatingProps> = ({ rating = 0 }) => {
  function getFill(num) {
    let fill = '100%';
    if (rating <= num) {
      fill = '0%';
    }
    if (Math.floor(rating) === num && rating > num) {
      fill = (rating % 1) * 100 + '%';
    }
    return {
      '--fill': fill,
    } as React.CSSProperties;
  }

  return (
    <div className="has-text-purple StarRating">
      {[0, 1, 2, 3, 4].map(num => (
        <span key={num} className="star" style={getFill(num)} />
      ))}
    </div>
  );
};
export default StarRating;
