import React from 'react';
import HelpEmailText from '../HelpEmailText/HelpEmailText';

interface NeedHelpProps {
  requestId?: string;
}

const NeedHelp: React.FunctionComponent<NeedHelpProps> = ({ requestId }) => {
  return (
    <div className="has-padding-y-4 has-padding-x-5 is-size-16p">
      <div className="is-family-secondary has-text-weight-bold has-margin-bottom-3">
        Need Help with this search?
      </div>
      <div>
        You can contact FLAYR using any of the links below and we'd be glad to
        help.
      </div>
      {!!requestId && (
        <div className="has-margin-top-3">
          <small>
            Search ID <strong className="has-text-dark-50">{requestId}</strong>
          </small>
        </div>
      )}

      <div>
        {/* <div className="is-family-secondary has-margin-top-4 has-text-weight-bold">
          Instant help
        </div>
        <div>Try our help desk articles</div> */}
        <div className="has-margin-y-3 has-border-bottom-5" />

        <HelpEmailText />
      </div>
    </div>
  );
};
export default NeedHelp;
