import { AnyAction } from "redux";
import { SET_TAB } from "../actions/action-types";
import { ITab } from "../types/search/tab";



const initialState: ITab = {
  selected: 'mobile'
};

export function tabReducer(state = initialState, { type, payload }: AnyAction) {
  switch (type) {
    case SET_TAB:
      return { ...state, selected: payload }
    default: return state;
  }
}