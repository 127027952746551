import React, { useState, useEffect } from 'react';
import './Accordion.scss';

interface AccordionProps {
  name: string;
  multiple?: boolean;
  onChange?: (e: any) => void;
  open?: boolean;
}

const Accordion: React.FunctionComponent<AccordionProps> = ({
  multiple = true,
  children,
  name,
  onChange,
  open = false,
}) => {
  const [checked, setChecked] = useState(open);

  useEffect(() => {
    setChecked(open);
  }, [open]);

  const childrenList = React.Children.toArray(children);

  function handleChange({ target }) {
    const { checked, name } = target;
    onChange && onChange({ id: name, open: checked });
    setChecked(checked);
  }

  return (
    <div className="Accordion">
      {childrenList.map((child, key) => (
        <label key={key}>
          {multiple && (
            <input
              className="Accordion-toggle"
              type="checkbox"
              name={child!['props'].id}
              checked={checked}
              onChange={handleChange}
            />
          )}
          <div className="Accordion-heading is-flex has-padding-x-4 has-padding-y-3 has-pointer is-vcentered">
            <h4 className="is-size-14p is-flex-fill">
              {child!['props'].heading}
            </h4>

            <span className="has-padding-left-3">
              <i className="fal fa-chevron-down Accordion-indicator" />
            </span>
          </div>
          <div
            className="Accordion-content has-padding-x-4 has-padding-y-2"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <div className="has-padding-4">{child}</div>
          </div>
        </label>
      ))}
    </div>
  );
};
export default Accordion;
