import { AnyAction } from 'redux';
import {
  SET_STYLISTS,
  SET_TAB,
  SET_CURRENT_PROFILE,
  SET_HAS_LOADED,
  SORT_STYLISTS,
  FILTER_STYLISTS,
  SET_HOME_PAGE,
  SET_MOBILE_PAGE,
  SET_REVIEWS,
  SET_FAQS,
  SET_PROFILE_PRICING,
  SET_STYLIST_REQUEST,
  SET_TIME_CHANGE,
} from '../actions/action-types';
import { Stylist } from '../types/stylist/stylist';
import { ISearch, IFilterBy, ESortBy } from '../types/search/search';
import { insertRequest } from '../utils';
import { Request } from '../types/request/request';

const initialState: ISearch = {
  mobileProviders: [],
  homeProviders: [],
  featuredHomeProviders: [],
  featuredMobileProviders: [],
  currentProfile: {
    coverImage: '',
    profileImage: '',
    firstName: '',
    reviewScore: 0,
    numOfReviews: 0,
    providerId: '',
    isFeatured: false,
    offers: [],
  },
  hasLoaded: false,
  filterBy: {
    hideBooked: false,
    hideNew: false,
    showInstantBooks: false,
  },
  sortBy: undefined,
  notifications: {
    mobile: 0,
    home: 0,
  },
  mobilePage: 1, //pagination for infinite scroll to maximize page speed
  homePage: 1,
};

export function searchReducer(
  state = initialState,
  { type, payload }: AnyAction
) {
  switch (type) {
    case SET_STYLISTS:
      const homeNotifications = payload.homeProviders.filter(
        home => home.request && home.request.stage === 20
      );
      const mobileNotifications = payload.mobileProviders.filter(
        mobile => mobile.request && mobile.request.stage === 20
      );
      return {
        ...state,
        ...payload,
        hasLoaded: true,
        notifications: {
          home: homeNotifications.length,
          mobile: mobileNotifications.length,
        },
      };
    case SORT_STYLISTS:
      return {
        ...state,
        sortBy: payload,
      };

    case SET_HOME_PAGE:
      return {
        ...state,
        homePage: payload,
      };
    case SET_MOBILE_PAGE:
      return {
        ...state,
        mobilePage: payload,
      };
    case FILTER_STYLISTS:
      return {
        ...state,
        filterBy: payload,
      };
    case SET_CURRENT_PROFILE:
      return { ...state, currentProfile: payload };
    case SET_HAS_LOADED:
      return { ...state, hasLoaded: payload };
    case SET_REVIEWS:
      return { ...state, currentReviews: payload };
    case SET_FAQS:
      return { ...state, faqs: payload };
    case SET_PROFILE_PRICING:
      return {
        ...state,
        currentProfile: { ...state.currentProfile, pricing: payload },
      };
    case SET_STYLIST_REQUEST:
      if (payload.forHomeStudio) {
        return {
          ...state,
          currentProfile: { ...state.currentProfile, request: payload },
          homeProviders: insertRequest(payload, state.homeProviders),
        };
      } else {
        return {
          ...state,
          currentProfile: { ...state.currentProfile, request: payload },
          mobileProviders: insertRequest(payload, state.mobileProviders),
        };
      }
    case SET_TIME_CHANGE:
      let newRequest: any = {
        ...state.currentProfile.request,
        timeChange: payload,
      };
      if (newRequest.forHomeStudio) {
        return {
          ...state,
          currentProfile: {
            ...state.currentProfile,
            request: newRequest,
          },
          homeProviders: insertRequest(newRequest, state.homeProviders),
        };
      } else {
        return {
          ...state,
          currentProfile: {
            ...state.currentProfile,
            request: newRequest,
          },
          mobileProviders: insertRequest(newRequest, state.mobileProviders),
        };
      }

    default:
      return state;
  }
}
