import { withRouter, RouteComponentProps } from 'react-router';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppStore } from '../../types/appStore';
import { Dispatch } from 'redux';
import { User } from '../../types/user';
import { Quote } from '../../types/quote/quote';
import ProfileDisplay from '../../shared-components/ProfileDisplay/ProfileDisplay';
import { Stylist } from '../../types/stylist/stylist';
import './Profile.scss';

import {
  getProfilePageData,
  getGenericProfile,
  getStylistReviews,
  getFaqs,
  requestProvider,
} from '../../services/stylist.services';
import { setQuote } from '../../actions/quote-actions';
import {
  setCurrentProfile,
  setFaqs,
  setStylists,
  setTab,
} from '../../actions/search-actions';
import Button from '../../shared-components/Button/Button';
import ProfileNav from './ProfileNav/ProfileNav';
import { RequestStage, Request, TimeChange } from '../../types/request/request';
import {
  formatReviews,
  findProviderInList,
  isMobile,
  getRequestStage,
  getPaymentWording,
} from '../../utils';
import { IReview } from '../../types/stylist/review';
import {
  setCurrentReviews,
  setProfilePricing,
} from '../../actions/stylist-actions';
import { IFaq } from '../../types/faq/faqs';
import About from './About/About';
import Kit from './Kit/Kit';
import Reviews from './Reviews/Reviews';
import QuoteForm from '../../shared-components/QuoteForm/QuoteForm';
import {
  quoteSearch,
  patchQuote,
  getProfileFromRequest,
} from '../../services/search.services';
import { QuoteResult } from '../../types/quote/quote-result';
import { Spinner } from '../../shared-components/Spinner/Spinner';
import Modal from '../../shared-components/Modal/Modal';
import ProfileFooter from './ProfileFooter/ProfileFooter';
import Accordion from '../../shared-components/Accordion/Accordion';
import AccordionItem from '../../shared-components/Accordion/AccordionItem/AccordionItem';
import Faqs from './Faqs/Faqs';
import SearchResultsBox from './SearchResultsBox/SearchResultsBox';
import HelpEmailText from '../../shared-components/HelpEmailText/HelpEmailText';
import { Pricing } from '../../types/quote/pricing';
import RequestInfo from './RequestInfo/RequestInfo';
import {
  setStylistRequest,
  setTimeChange,
} from '../../actions/request-actions';
import camelcaseKeys from 'camelcase-keys';
import QuoteDisplay from '../../shared-components/QuoteDisplay/QuoteDisplay';
import PricingInclusion from '../../shared-components/PricingInclusion/PricingInclusion';
import ProviderAlert from './ProviderAlert/ProviderAlert';
import { submitTimeChange } from '../../services/request.services';
import ProfileInfo from '../../shared-components/ProfileInfo/ProfileInfo';
import ConditionalWrapper from '../../shared-components/ConditionalWrapper/ConditionalWrapper';
import ReasonsToBook from '../../shared-components/ReasonsToBook/ResonsToBook';
import Jobs from '../../shared-components/Jobs/Jobs';
import classnames from 'classnames';
import CallToAction from './CallToAction/CallToAction';
import ReactGA from 'react-ga';
import ReadMore from '../../shared-components/ReadMore/ReadMore';
import TimeSpotAlert from '../../shared-components/TimeSpotAlert/TimeSpotAlert';
import OffersReceived from '../../shared-components/OffersReceived/OffersReceived';
import { timeMapping } from '../../static-data/timeMapping';
import { serviceBasedContent } from '../../static-data/contentMapping';
import FreeCancellation from '../../shared-components/FreeCancellation/FreeCancellation';
import useToggle from '../../hooks/useToggle';
ReactGA.initialize('UA-127854499-2');

interface ProfileProps
  extends User,
    RouteComponentProps<{
      providerId: string;
      quoteId: string;
      type: 'home' | 'mobile';
      requestId?: string;
    }> {
  quote: Quote;
  provider: Stylist;
  faqs?: IFaq[];
  setQuoteData: (quote: Quote) => void;
  setCurrentProfileData: (profile: Stylist) => void;
  setCurrentReviewData: (reviews: IReview) => void;
  setFaqData: (faqs: IFaq[]) => void;
  setProfilePricingData: (pricing: Pricing | undefined) => void;
  setStylistRequestData: (request: Request) => void;
  reviews: any;
  setStylistsData: (homeStylists: Stylist[], mobileStylists: Stylist[]) => void;
  fromSearch: boolean;
  mobileList?: Stylist[];
  homeList?: Stylist[];
  selectedTab: 'home' | 'mobile';
  setTabSelected: (tab: 'home' | 'mobile') => void;
  setTimeChangeData: (timeChange: TimeChange) => void;
  offers?: Stylist[];
}

function isCtaVisible(requestStage, quote, provider) {
  // show cta if booked
  if (requestStage === RequestStage.booked) {
    return true;
  }
  // else, if the provider is booked out or is inactive or doesn't have pricing
  //  or even if the quote doesn't have a pricing but has a shopify_id field, hide the cta
  if (
    provider.bookedOut ||
    !provider.isActive ||
    (!provider.pricing && quote.shopify_id)
  ) {
    return false;
  }
  return true;
}

const Profile: React.FunctionComponent<ProfileProps> = (props) => {
  const {
    setStylistsData,
    provider,
    match,
    quote,
    setQuoteData,
    setCurrentProfileData,
    setProfilePricingData,
    setCurrentReviewData,
    setStylistRequestData,
    setTimeChangeData,
    setFaqData,
    faqs,
    reviews,
    fromSearch,
    mobileList = [],
    homeList = [],
    history,
    selectedTab,
    setTabSelected,
    offers = [],
  } = props;

  const freeCancellationModal = useToggle();

  const [searchLoading, setSearchLoading] = useState(false);
  const [requestModalOpen, setRequestModalOpen] = useState(false);
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const [profileLoading, setProfileLoading] = useState(
    fromSearch ? false : true
  );
  const [notFound, setNotFound] = useState(false);
  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const [checkAvailLoading, setCheckAvailLoading] = useState(false);
  const [timeChangeLoading, setTimeChangeLoading] = useState(false);
  const [timeChangeModalOpen, setTimeChangeModalOpen] = useState(false);

  const { providerId, quoteId, type, requestId } = match.params;
  useEffect(() => {
    if (providerId && quoteId && type) {
      //if quote link
      setTabSelected(type);
      fetchProvider(providerId, quoteId, type); //quote profile
    } else if (!providerId! && !quoteId && !type && requestId) {
      //if offer link  we do additional fetch;
      setDataFromRequestIdUrl(requestId);
    } else {
      //generic profile
      fetchGenericProvider(providerId);
    }
    //fetch faqs if not fetched
    //if (!faqs) fetchFaqs();
    window.scroll(0, 0);
  }, [match.params.providerId]);

  useEffect(() => {
    fetchFaqs();
  }, [quote]);
  // fetch reviews

  useEffect(() => {
    if (providerId) {
      fetchReviews(providerId);
    }
    return () => {
      setCurrentReviewData({ reviews: [], summary: {} });
    };
  }, [match.params.providerId]);

  useEffect(() => {
    if (quote.shopify_id && quote.user_id) {
      //send GA set user;
      ReactGA.set({ userId: quote.user_id });
      //send pageview
      if (match && match.url) {
        ReactGA.pageview(match.url);
      }
      //set user for heap
      if (window['heap'] && quote.user_id) {
        window['heap'].identify(quote.user_id);
      }
    }
  }, [quote]);

  const setDataFromRequestIdUrl = async (requestId: string) => {
    try {
      let data = (await getProfileFromRequest(requestId)).data;
      let { providerId, quoteId, type } = data;
      if (providerId && quoteId && type) {
        setTabSelected(type);
        fetchProvider(providerId, quoteId, type);
        // fetchReviews(providerId);
      } else {
        console.log('no request');
        history.push('/404');
      }
    } catch (err) {
      console.log(err);
      history.push('/404');
    }
  };

  const fetchFaqs = async () => {
    try {
      let faqs;
      if (quote.type && quote.type === 'haircut') {
        faqs = (await getFaqs('quote_haircut')).data;
      } else {
        faqs = (await getFaqs('quote')).data;
      }
      setFaqData(faqs);
    } catch (err) {
      console.log(err);
    }
  };

  /* Check if stage is requested */
  const isRequested = (quote: Quote, request?: Request) => {
    if (quote.shopify_id && request && request.stage === 10) {
      return true;
    } else {
      return false;
    }
  };

  const fetchReviews = async (
    providerId: string,
    type?: 'makeup' | 'haircut'
  ) => {
    try {
      let reviews = (await getStylistReviews(providerId, type || 'makeup'))
        .data;
      if (reviews) reviews = formatReviews(reviews); //format reviews in correct type format
      setCurrentReviewData(reviews); //set redux state for current reviews
    } catch (err) {
      console.log(err);
    }
  };

  //for generic profile
  const fetchGenericProvider = async (providerId: string) => {
    try {
      let provider = (await getGenericProfile(providerId)).data;
      if (provider) setCurrentProfileData(provider);
      setProfileLoading(false);
    } catch (err) {
      console.log(err);
      setProfileLoading(false);
    }
  };

  //for quote profile
  const fetchProvider = async (providerId, quoteId, type) => {
    try {
      let data = (await getProfilePageData(quoteId, providerId, type)).data;
      const { stylist, quote, offers } = data;
      if (quote) {
        setQuoteData(quote);
        fetchReviews(providerId, quote.type);
      } else {
        fetchReviews(providerId, 'makeup');
      }
      setCurrentProfileData({ ...stylist, offers });
      setProfileLoading(false);
    } catch (err) {
      console.log(err);
      setProfileLoading(false);
    }
  };

  function getGalleryImages(provider: Stylist) {
    // add existing cover image into the gallery images list
    // then remove the first 2 images from the list
    // the first 2 images are profile pic and cover image
    return [{ src: provider.coverImage }, ...(provider.images || []).slice(1)];
  }

  //onTimeChangeSubmit
  const handleTimeChangeSubmit = async (
    timeToChange: string,
    time: string,
    reason: string
  ) => {
    try {
      if (
        provider.request &&
        provider.request.id &&
        provider.request.stage === RequestStage.offered
      ) {
        setTimeChangeLoading(true);
        setTimeChangeModalOpen(false);
        let response = (
          await submitTimeChange(
            reason,
            time,
            timeToChange,
            provider.request.id
          )
        ).data;
        setTimeChangeData(response.time_change);
        setTimeChangeLoading(false);
      }
    } catch (err) {
      console.log(err);
      setTimeChangeLoading(false);
    }
  };

  //run a quote search
  const getQuoteData = async (quoteId: string) => {
    try {
      setSearchLoading(true);
      let data: QuoteResult = (await quoteSearch(quoteId)).data;
      if (!data) {
        setNotFound(true);
      } else {
        setStylistsData(data.home, data.mobile);
        setQuoteData(data.quote);
        let foundProvider = findProviderInList(
          data.mobile,
          provider.providerId
        );
        if (foundProvider) {
          console.log(foundProvider);
          setCurrentProfileData({ ...provider, ...foundProvider });
          history.replace(
            `/quote/${data.quote.shopify_id}/mobile/${provider.providerId}`
          );
          setTabSelected('mobile');
        }
      }
      setSearchLoading(false);
    } catch (err) {
      setSearchLoading(false);
      console.log(err);
    }
  };

  //function triggered after quote is searching
  const onQuoteSearch = () => {
    setSearchModalOpen(true);
    setSearchLoading(true);
  };

  //triggered when request can be made
  const onCheckAvail = async (
    name: string,
    mobile: string,
    email: string,
    event: string,
    event_time: string
  ) => {
    try {
      const { type } = match.params;
      setCheckAvailLoading(true);
      let newQuote: Quote = (
        await patchQuote(
          quote.shopify_id,
          name,
          email,
          mobile,
          event,
          event_time
        )
      ).data;
      setQuoteData(newQuote);
      let request: any = (
        await requestProvider(
          provider.providerId,
          quote.shopify_id,
          type === 'home' ? true : false
        )
      ).data;
      request = camelcaseKeys(request);
      setStylistRequestData(request);
      setCheckAvailLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  function handleAccordionClick({ id, open }) {
    if (id === 'needHelp' && open) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }

  function handleFooterBtnClick() {
    if (searchModalOpen) {
      setSearchModalOpen(false);
    }
    if (!quote.shopify_id) {
      // if no quote
      setLocationModalOpen(true);
      // }
      // else if (
      //   getRequestStage(quote, provider) === RequestStage.expired
      // ) {
    } else {
      // if quote, open request form
      setRequestModalOpen(true);
    }
  }

  function handleGoBack() {
    setRequestModalOpen(false);
    history.goBack();
  }

  const getLocationForCard = () => {
    if (
      selectedTab === 'home' &&
      provider.homeStudioAddress &&
      provider.homeStudioAddress.state &&
      provider.homeStudioAddress.suburb
    ) {
      return `${provider.homeStudioAddress.suburb}, ${provider.homeStudioAddress.state}`;
    } else if (provider.suburb && provider.state) {
      return `${provider.suburb}, ${provider.state}`;
    } else {
      return '';
    }
  };

  function getAbout(provider: Stylist) {
    const about = provider.bio || provider['profileBlurb'];
    return about || '';
  }

  const handleProfileClick = (
    type: 'home' | 'mobile',
    providerId: string,
    quoteId,
    profile: Stylist
  ) => {
    setCurrentProfileData(profile);
    let qs: any = {
      recommended: !!profile.isFeatured,
      position: 'profile-carousel',
    };

    qs = Object.keys(qs)
      .map((key) => `${key}=${qs[key]}`)
      .join('&');
    if (quote.shopify_id) {
      history.push(`/quote/${quoteId}/${type}/${providerId}?${qs}`);
    } else {
      history.push(`/profile/${providerId}?${qs}`);
    }
    window.scrollTo(0, 0);
  };

  function handleInputChange(e) {
    const { name, value } = e.target;

    const quoteData = { ...quote, ...{ [name]: value } };

    setQuoteData(quoteData);
    handleFooterBtnClick();
  }

  const requestStage = getRequestStage(quote, provider);

  return (
    <section className="has-background-white">
      <ProfileNav quote={quote} tab={selectedTab} />
      {(provider.isActive || requestStage === RequestStage.booked) && (
        <div
          className={classnames('Profile-alert', {
            'is-sticky': requestStage === RequestStage.offered,
          })}
        >
          <ProviderAlert
            requestStage={requestStage.toString()}
            offerDuration={
              provider.request ? provider.request.offerExpiryDate : undefined
            }
            isOfferExpired={requestStage === RequestStage.expired}
          />
        </div>
      )}

      <div className="container Profile-container">
        <div className="columns is-marginless">
          <aside
            className={classnames(
              'column Profile-sidebar is-paddingless is-4-desktop is-3-fullhd',
              {
                'has-margin-top-4-desktop': !quote || !quote.shopify_id,
                'is-offered': requestStage === RequestStage.offered,
              }
            )}
          >
            {/* Quoteform invisible if forProfilePage, also invisible when requested */}
            {(!quote || !quote.shopify_id) && (
              <div className=" Profile-quote-form has-margin-bottom-5-desktop">
                <QuoteForm
                  getQuoteData={(quoteId: string) => getQuoteData(quoteId)}
                  locationModalOpen={locationModalOpen}
                  setLocationModalOpen={setLocationModalOpen}
                  forProfilePage
                  onQuoteSearch={onQuoteSearch}
                />
              </div>
            )}

            {/* quote details, duplicated to show in desktop and hide on mobile */}
            <div className="is-hidden-mobile">
              {!!quote.shopify_id && (
                <div className="has-padding-x-4 profile-quote-details">
                  <QuoteDisplay
                    quote={quote}
                    type={selectedTab}
                    provider={provider}
                    requestStage={requestStage}
                    onTimeSubmit={handleTimeChangeSubmit}
                    timeChange={
                      provider.request && provider.request.timeChange
                        ? provider.request.timeChange
                        : undefined
                    }
                    timeChangeModalOpen={timeChangeModalOpen}
                    setTimeChangeModalOpen={setTimeChangeModalOpen}
                    timeChangeLoading={timeChangeLoading}
                    serviceType={quote.type || 'makeup'}
                  />
                </div>
              )}

              {!!provider.pricing && (
                <div className="has-padding-x-4 has-margin-y-5">
                  <PricingInclusion
                    type={quote ? quote.type || 'makeup' : 'makeup'}
                    total={provider.pricing.cost}
                    deposit={provider.pricing.take}
                    payableOnDay={provider.pricing.payment}
                    paymentOption={getPaymentWording({
                      ...provider.request,
                      quote_details: quote,
                    })}
                  />
                </div>
              )}

              <div className="has-margin-top-3 has-text-centered">
                {isCtaVisible(requestStage, quote, provider) && (
                  <>
                    {requestStage === RequestStage.noRequest && (
                      <div className="select is-fullwidth has-margin-y-4">
                        <select
                          name="event_time"
                          id="event_time_profile_desktop"
                          value={quote.event_time}
                          onChange={handleInputChange}
                        >
                          <option value="" disabled>
                            {
                              serviceBasedContent[quote.type || 'makeup']
                                .requestForm.event_time.label
                            }
                          </option>
                          {timeMapping.map((opt) => (
                            <option value={opt.value} key={opt.value}>
                              {opt.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}

                    {requestStage === RequestStage.offered && (
                      <div
                        className="has-margin-bottom-4 is-size-18p has-pointer has-text-dark-100 has-text-left has-padding-left-4 has-background-white-ter has-padding-y-3"
                        onClick={freeCancellationModal.toggle}
                        style={{ borderRadius: 4 }}
                      >
                        COVID cancellation policy.{' '}
                        <span className="is-underlined">Learn more</span>
                      </div>
                    )}
                    <CallToAction
                      onClick={
                        requestStage === RequestStage.offered
                          ? undefined
                          : handleFooterBtnClick
                      }
                      stage={requestStage}
                      requestId={
                        provider && provider.request
                          ? provider.request.requestId
                          : ''
                      }
                      providerId={provider.providerId}
                      deposit={
                        provider.request ? provider.request.take : undefined
                      }
                    />
                  </>
                )}

                {(provider.bookedOut || provider.isActive === false) &&
                  requestStage !== RequestStage.booked && (
                    <div className="has-text-danger">Booked Out</div>
                  )}
              </div>
              {requestStage === RequestStage.offered && provider.isActive && (
                <TimeSpotAlert />
              )}
            </div>
          </aside>
          <main className="Profile-content column is-8-desktop is-9-fullhd is-paddingless has-padding-right-4-desktop">
            {!profileLoading && (
              <>
                {provider && (
                  <>
                    {/* collage */}
                    <ProfileDisplay
                      profileImg={provider.profileImage}
                      coverImg={provider.coverImage}
                      name={provider.firstName}
                      rating={provider.reviewScore}
                      reviews={provider.numOfReviews}
                      hideReviews={quote.type === 'haircut'}
                      price={
                        provider.pricing ? provider.pricing.cost : undefined
                      }
                      deposit={
                        provider.pricing ? provider.pricing.take : undefined
                      }
                      imgSize="56"
                      key={provider.providerId}
                      providerId={provider.providerId}
                      forProfilePage
                      galleryImages={getGalleryImages(provider)}
                      generic={!quote.shopify_id}
                      location={getLocationForCard()}
                      requested={isRequested(quote, provider.request)}
                      type={selectedTab}
                      className={classnames('has-margin-bottom-3', {
                        'has-margin-top-4-desktop':
                          !provider || !provider.request,
                      })}
                    />
                    <ConditionalWrapper
                      wrap={!isMobile()}
                      className="Profile-details"
                    >
                      {/* profile pic and ratings */}
                      <div className="Profile-info has-margin-top-3 has-margin-top-0-desktop">
                        <ProfileInfo
                          img={provider.profileImage}
                          name={provider.firstName}
                          rating={provider.reviewScore}
                          reviews={provider.numOfReviews}
                          hideReviews={quote.type === 'haircut'}
                          price={
                            provider.pricing ? provider.pricing.cost : undefined
                          }
                          deposit={
                            provider.pricing ? provider.pricing.take : undefined
                          }
                          covidTrained={provider.covidTrained}
                          imgSize="56"
                          forProfilePage
                          className="ProfileDisplay-info"
                          shortRatingDisabled
                        />
                      </div>

                      {/* Quote info section aka Appointment Section, hidden on desktop, dupicated*/}
                      {!!quote.shopify_id && (
                        <div className="has-padding-x-4 has-margin-y-5 profile-quote-details is-hidden-desktop">
                          <QuoteDisplay
                            quote={quote}
                            type={selectedTab}
                            provider={provider}
                            requestStage={requestStage}
                            onTimeSubmit={handleTimeChangeSubmit}
                            timeChange={
                              provider.request && provider.request.timeChange
                                ? provider.request.timeChange
                                : undefined
                            }
                            timeChangeModalOpen={timeChangeModalOpen}
                            setTimeChangeModalOpen={setTimeChangeModalOpen}
                            timeChangeLoading={timeChangeLoading}
                            serviceType={quote.type || 'makeup'}
                          />
                        </div>
                      )}
                    </ConditionalWrapper>

                    {/* inclusion, duplicated, hidden on desktop */}
                    {!!provider.pricing && (
                      <div className="has-padding-x-4 has-margin-y-5 is-hidden-desktop">
                        <PricingInclusion
                          type={quote ? quote.type || 'makeup' : 'makeup'}
                          total={provider.pricing.cost}
                          deposit={provider.pricing.take}
                          payableOnDay={provider.pricing.payment}
                          paymentOption={getPaymentWording({
                            ...provider.request,
                            quote_details: quote,
                          })}
                        />

                        {/* cta */}
                        {!provider.bookedOut &&
                          provider.isActive !== false &&
                          !(!provider.pricing && quote.shopify_id) && (
                            <>
                              {requestStage === RequestStage.noRequest && (
                                <div className="select is-fullwidth has-margin-y-4">
                                  <select
                                    name="event_time"
                                    id="event_time_profile_mobile"
                                    value={quote.event_time}
                                    onChange={handleInputChange}
                                  >
                                    <option value="" disabled>
                                      {
                                        serviceBasedContent[
                                          quote.type || 'makeup'
                                        ].requestForm.event_time.label
                                      }
                                    </option>
                                    {timeMapping.map((opt) => (
                                      <option value={opt.value} key={opt.value}>
                                        {opt.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              )}
                              {requestStage === RequestStage.offered && (
                                <div
                                  className="has-margin-y-4 is-size-18p has-pointer has-text-dark-100 has-text-left has-padding-left-4 has-background-white-ter has-padding-y-3"
                                  onClick={freeCancellationModal.toggle}
                                  style={{ borderRadius: 4 }}
                                >
                                  COVID cancellation policy.{' '}
                                  <span className="is-underlined">
                                    Learn more
                                  </span>
                                </div>
                              )}
                              <CallToAction
                                onClick={
                                  requestStage === RequestStage.offered
                                    ? undefined
                                    : handleFooterBtnClick
                                }
                                stage={requestStage}
                                requestId={
                                  provider && provider.request
                                    ? provider.request.requestId
                                    : ''
                                }
                                providerId={provider.providerId}
                                deposit={
                                  provider.request
                                    ? provider.request.take
                                    : undefined
                                }
                              />
                            </>
                          )}
                        {requestStage === RequestStage.offered &&
                          provider.isActive && <TimeSpotAlert />}
                      </div>
                    )}

                    {/* about section */}
                    <div>
                      <About
                        about={getAbout(provider)}
                        open={requestStage !== RequestStage.offered}
                        onCollapseChange={handleAccordionClick}
                      />
                    </div>

                    {/* kit section */}
                    <div>
                      <Kit
                        kit={
                          Array.isArray(provider.makeupBrands)
                            ? provider.makeupBrands.join(', ')
                            : provider.makeupBrands || ''
                        }
                        open={requestStage !== RequestStage.offered}
                        onCollapseChange={handleAccordionClick}
                      />
                    </div>

                    {/* reviews */}
                    {quote.type !== 'haircut' && (
                      <div>
                        <Accordion
                          name="reviews"
                          onChange={handleAccordionClick}
                          open={requestStage !== RequestStage.offered}
                        >
                          <AccordionItem
                            heading={
                              <div className="is-flex has-padding-right-3">
                                <span>Reviews</span>{' '}
                                <span className="has-margin-left-auto">
                                  {provider.reviewScore}{' '}
                                  <span className="star has-text-gradient-1" />{' '}
                                  <small className="has-text-dark-50 has-text-weight-normal">
                                    ({provider && provider.numOfReviews})
                                  </small>
                                </span>
                              </div>
                            }
                            id="reviews"
                          >
                            <Reviews
                              summary={reviews ? reviews.summary : {}}
                              reviews={reviews ? reviews.reviews : []}
                            />
                          </AccordionItem>
                        </Accordion>
                      </div>
                    )}

                    {/* Help section */}
                    <div>
                      <Accordion name="faqs" onChange={handleAccordionClick}>
                        <AccordionItem heading="FAQs" id="faqs">
                          <Faqs faqs={faqs || []} />
                        </AccordionItem>
                      </Accordion>

                      {/*                       <div className="has-padding-x-3 has-margin-top-3 has-margin-left-3">
                        <div className="is-family-secondary">
                          <b>Need Help?</b> <br />
                          Reach out to us with any of the links below
                        </div>
                        {quote && quote.shopify_id && (
                          <div>
                            ID {quote.shopify_id}_{provider.providerId}
                          </div>
                        )}
                      </div>
                      <div className="has-margin-top-5">
                        <HelpEmailText
                          requestId={`${quote.shopify_id}_${
                            provider.providerId
                          }`}
                        />
                      </div> */}
                    </div>
                    <Accordion name="needHelp" onChange={handleAccordionClick}>
                      <AccordionItem heading="Need Help?" id="needHelp">
                        <div className="has-margin-n-top-3 has-margin-bottom-3">
                          <small>
                            You can contact Flayr using any of the links below
                            and we'd be glad to help.
                          </small>
                        </div>
                        {quote.shopify_id && (
                          <div className="has-margin-bottom-3">
                            <small>
                              Offer code:{' '}
                              <span className="has-text-weight-medium">
                                {`${quote.shopify_id}_${provider.providerId}`}
                              </span>
                            </small>
                          </div>
                        )}
                        <HelpEmailText
                          requestId={`${quote.shopify_id}_${provider.providerId}`}
                        />
                      </AccordionItem>
                    </Accordion>

                    {
                      //  received offers, show only when not booked
                      requestStage !== RequestStage.booked && (
                        <div className="has-margin-y-4">
                          <OffersReceived
                            type={selectedTab}
                            providers={
                              offers || offers!.length
                                ? offers
                                : selectedTab === 'mobile'
                                ? mobileList
                                : homeList
                            }
                            exclude={provider.providerId}
                            heading={
                              offers.length ||
                              requestStage === RequestStage.offered
                                ? 'Other Offers'
                                : `Offers you've received`
                            }
                            onProfileClick={(p) =>
                              handleProfileClick(
                                selectedTab,
                                p.providerId,
                                quote.shopify_id,
                                p
                              )
                            }
                          />
                        </div>
                      )
                    }
                  </>
                )}
              </>
            )}
            {/* reason to book and jobs for makeup artists section */}
            <section className="is-hidden-mobile has-margin-top-4">
              <ReasonsToBook mode="content" />
              {/* <Jobs /> */}
            </section>
          </main>
        </div>
      </div>

      {/* modal for request info */}
      {requestModalOpen && (
        <Modal
          open
          fullScreen={
            isMobile() &&
            !(
              provider.request &&
              provider.request.stage === RequestStage.requested
            )
          }
          onClose={() => {
            setRequestModalOpen(false);
          }}
          header="Check availability"
        >
          <RequestInfo
            onCheckAvail={onCheckAvail}
            quote={quote}
            checkAvailLoading={checkAvailLoading}
            provider={provider}
            onBack={handleGoBack}
          />
        </Modal>
      )}

      {/* modal for search results */}
      <Modal
        open={searchModalOpen}
        fullScreen={
          !searchLoading &&
          isMobile() &&
          mobileList &&
          mobileList.some((prov) => prov.providerId === provider.providerId)
        }
        onClose={() => {
          setSearchModalOpen(false);
        }}
        header={
          !searchLoading && provider ? (
            <small>{'Instant Pricing for ' + provider.firstName}</small>
          ) : (
            ''
          )
        }
      >
        <SearchResultsBox
          quote={quote}
          searchLoading={searchLoading}
          provider={provider}
          mobileList={mobileList || []}
          onCheckAvailability={handleFooterBtnClick}
        />
      </Modal>
      <FreeCancellation
        open={freeCancellationModal.on}
        onClose={freeCancellationModal.toggle}
      />

      {/* Loader for profile page*/}
      <div>
        {profileLoading && (
          <>
            <div className="level has-margin-top-5">
              <div className="level-item">
                <Spinner message="Loading" isShowing fullScreen={false} />
              </div>
            </div>
          </>
        )}
      </div>

      {!profileLoading && (
        <ProfileFooter
          className="is-hidden-desktop"
          onBtnClick={handleFooterBtnClick}
          stage={requestStage}
          onLeftSectionClick={freeCancellationModal.toggle}
        />
      )}
    </section>
  );
};

const mapStateToProps = (store: AppStore) => {
  const { quote, search, tab } = store;
  return {
    quote: quote,
    faqs: search.faqs,
    mobileList: search.mobileProviders,
    homeList: search.homeProviders,
    selectedTab: tab.selected,
    fromSearch:
      search.mobileProviders && search.mobileProviders.length > 0
        ? true
        : false,
    provider: search.currentProfile,
    reviews: search.currentReviews,
    offers: search.currentProfile.offers,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setStylistsData: (homeStylists: Stylist[], mobileStylists: Stylist[]) =>
      dispatch(setStylists(homeStylists, mobileStylists)),
    setQuoteData: (quote: Quote) => dispatch(setQuote(quote)),
    setCurrentProfileData: (profile: Stylist) =>
      dispatch(setCurrentProfile(profile)),
    setCurrentReviewData: (reviews: IReview) =>
      dispatch(setCurrentReviews(reviews)),
    setFaqData: (faqs: IFaq[]) => dispatch(setFaqs(faqs)),
    setProfilePricingData: (pricing: Pricing | undefined) =>
      dispatch(setProfilePricing(pricing)),
    setTabSelected: (tab: 'home' | 'mobile') => dispatch(setTab(tab)),
    setStylistRequestData: (request: Request) =>
      dispatch(setStylistRequest(request)),
    setTimeChangeData: (timeChange: TimeChange) =>
      dispatch(setTimeChange(timeChange)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Profile));
