import React, { useState, useEffect } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './AvailabilityAlert.scss';

import {
  parse,
  isTomorrow,
  isSameWeek,
  isFriday,
  isSaturday,
  isSunday,
  isThisWeek,
  addWeeks,
  isToday,
  isAfter,
} from 'date-fns';

interface AvailabilityAlertProps {
  date?: string;
  loading?: boolean;
  quoteId?: string;
  quoteType?: string;
}

const AvailabilityAlert: React.FunctionComponent<AvailabilityAlertProps> = ({
  date = '',
  loading,
  quoteType = 'makeup',
}) => {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    if (quoteType === 'makeup') {
      if (percentage !== 100) {
        // if (loading) {
        //   setPercentage(0);
        // }

        // covid 19 change
        setPercentage(100);
      } else {
        if (loading) {
          setPercentage(0);
        }
      }
    }
  }, [loading]);

  function getColor() {
    if (percentage === 0) {
      return 'transparent';
    }
    if (percentage <= 60) {
      return 'var(--green)';
    }
    if (percentage > 60 && percentage <= 70) {
      return 'var(--orange)';
    }
    return 'var(--red)';
  }

  function getPathProgressConfig() {
    const color = getColor();
    return {
      pathColor: color,
      textColor: color,
      trailColor: 'transparent',
    };
  }

  function getText() {
    if (!date) {
      return '';
    }

    const parsedDate = parse(date);

    if (quoteType === 'makeup') {
      if (isAfter(parsedDate, '2020-05-31')) {
        return `Due to postponed weddings & events, this date is in high demand. Book now to secure this price.`;
      }
    } else {
      // new corona virus changes

      // if today
      if (isToday(parsedDate)) {
        if (percentage !== 89) {
          setPercentage(89);
        }
        return `89% of appointment spots are reserved today, so check availability with 5 stylists to make sure you get an appointment spot.`;
      }

      // if tomorrow
      if (isTomorrow(parsedDate)) {
        if (percentage !== 87) {
          setPercentage(87);
        }
        return `87% of appointment spots are reserved, so check availability with 5 stylists to make sure you get an appointment spot for tomorrow.`;
      }
      if (isThisWeek(parsedDate)) {
        // if coming friday
        if (isFriday(parsedDate)) {
          if (percentage !== 78) {
            setPercentage(78);
          }
          return `78% of appointment spots are reserved this Friday, so check availability with 3 stylists to make sure you get an appointment spot.`;
        }
        // if coming saturday
        if (isSaturday(parsedDate)) {
          if (percentage !== 82) {
            setPercentage(82);
          }
          return `82% of appointment spots are reserved for this Saturday, so check availability with 5 stylists to make sure you get an appointment spot.`;
        }

        if (percentage !== 63) {
          setPercentage(63);
        }
        return `Your event is this week, 63% of appointment spots are reserved, so check availability with 3 stylists to make sure you get an appointment spot.`;
      }

      // if coming sunday
      if (
        isSunday(parsedDate) &&
        isSameWeek(addWeeks(new Date(), 1), parsedDate)
      ) {
        if (percentage !== 80) {
          setPercentage(80);
        }
        return `Check availability with 3 stylists to make sure you get an appointment spot for this Sunday, as 80% of appointment spots are reserved.`;
      }

      // coming 3 weeks
      if (
        isSameWeek(addWeeks(new Date(), 1), parsedDate) ||
        isSameWeek(addWeeks(new Date(), 2), parsedDate) ||
        isSameWeek(addWeeks(new Date(), 3), parsedDate)
      ) {
        if (isFriday(parsedDate)) {
          if (percentage !== 64) {
            setPercentage(64);
          }
          return `64% of appointment spots are reserved in advance on Fridays. Check availability with 3 stylists to make sure you get an appointment spot.`;
        }
        if (isSaturday(parsedDate)) {
          if (percentage !== 71) {
            setPercentage(71);
          }
          return `71% of appointment spots are reserved in advance on Saturdays. Check availability with 3 stylists to make sure you get an appointment spot.`;
        }
        if (isSunday(parsedDate)) {
          if (percentage !== 69) {
            setPercentage(69);
          }
          return `Check availability with 3 stylists to make sure you get an appointment spot, as 69% of appointment spots are reserved in advance on Sundays.`;
        }
      }

      if (percentage) {
        setPercentage(0);
      }
    }

    return '';
  }

  return (
    <div
      className="columns is-mobile is-vcentered AvailabilityAlert has-padding-x-4-tablet has-padding-x-3 is-marginless"
      style={{ height: percentage ? 'auto' : 0 }}
    >
      {quoteType === 'haircut' && (
        <div
          className="column is-paddingless has-margin-right-3 is-narrow"
          style={{ width: 46 }}
        >
          <CircularProgressbar
            value={percentage}
            text={`${percentage}%`}
            styles={buildStyles(getPathProgressConfig())}
          />
        </div>
      )}
      <div
        className="column is-paddingless is-size-16p has-line-height-4"
        style={{ color: getColor() }}
      >
        {!loading && getText()}
      </div>
    </div>
  );
};
export default AvailabilityAlert;
