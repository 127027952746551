import React, { useState } from 'react';
import ProfileContent from '../../../shared-components/ProfileContent/ProfileContent';
import ReadMore from '../../../shared-components/ReadMore/ReadMore';
import Accordion from '../../../shared-components/Accordion/Accordion';
import AccordionItem from '../../../shared-components/Accordion/AccordionItem/AccordionItem';
import { isMobile } from '../../../utils';

interface AboutProps {
  about: string;
  open?: boolean;
  onCollapseChange?: (e: any) => void;
}

const About: React.FunctionComponent<AboutProps> = ({
  about,
  open,
  onCollapseChange,
}) => {
  if (!about) {
    return null;
  }
  return (
    <Accordion name="about" onChange={onCollapseChange} open={open}>
      <AccordionItem heading="About Me" id="about">
        <ReadMore text={about} max={isMobile() ? 130 : 360} />
      </AccordionItem>
    </Accordion>
  );
};
export default About;
