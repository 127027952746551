import React, { useState, useEffect, useRef } from 'react';
import './HomeStudioMap.scss';
import { Stylist, HomeStudioLocation } from '../../types/stylist/stylist';
import Button from '../Button/Button';
import Map, { MarkerProps } from '../Map/Map';
import Modal from '../Modal/Modal';
import ScrollPanel from '../ScrollPanel/ScrollPanel';
import ProviderCard from '../ProviderCard/ProviderCard';
import ProfileDisplay from '../ProfileDisplay/ProfileDisplay';
import { RequestStage } from '../../types/request/request';
import { Quote } from '../../types/quote/quote';
import classnames from 'classnames';

interface HomeStudioMapProps {
  stylists?: Stylist[];
  onCardClick?: (stylist) => void;
  quote: Quote;
}

const HomeStudioMap: React.FunctionComponent<HomeStudioMapProps> = ({
  stylists = [],
  onCardClick,
  quote,
}) => {
  const [mapVisible, setMapVisibility] = useState(true);
  const [selectedIndex, selectIndex] = useState(0);
  const [markers, setMarkers] = useState<MarkerProps[]>([]);

  const providerListRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (stylists.length) {
      selectIndex(1);
      if (providerListRef.current) {
        providerListRef.current.scrollLeft = 0;
      }
      setMarkers(
        stylists.map(s => ({
          lat: s.location!.coordinates[1] || 0,
          lng: s.location!.coordinates[0] || 0,
          title: s.firstName,
          label: s.pricing ? `$${s.pricing.cost}` : '',
        }))
      );
    }
  }, [stylists]);

  function toggleMapVisibility() {
    setMapVisibility(!mapVisible);
  }

  function isInViewport(elem, parent, offset = 0) {
    if (!elem) return false;
    const left = elem.getBoundingClientRect().left;
    return (
      left + offset - parent.getBoundingClientRect().left >= 0 &&
      left - offset + parent.getBoundingClientRect().left <= window.innerWidth
    );
  }

  function handleScroll(e) {
    const items = e.target.children[0].children[0].children[0].children;
    if (items && items.length) {
      const elem = [...items].find(itm => isInViewport(itm, e.target, 10));
      if (
        elem &&
        +elem.dataset.index &&
        selectedIndex !== +elem.dataset.index
      ) {
        selectIndex(+elem.dataset.index);
      }
    }
  }

  const getProviderLocation = (provider: Stylist, type: 'home' | 'mobile') => {
    if (type === 'home') {
      return provider.suburb;
    } else if (provider.suburb && provider.state) {
      return `${provider.suburb}, ${provider.state}`;
    } else if (quote.suburb && quote.state) {
      return `${quote.suburb}, ${quote.state}`;
    } else {
      return undefined;
    }
  };

  function handleMarkerClick(idx) {
    if (providerListRef.current) {
      const parent =
        providerListRef.current.children[0].children[0].children[0];
      const child = parent.children[idx];
      if (child) {
        const num = child.getBoundingClientRect().width * idx;
        providerListRef.current.scrollLeft = num;
      }
    }
  }

  return (
    <>
      {/* <Button
        className="HomeStudioMap-location-button"
        isRounded
        onClick={toggleMapVisibility}
      >
        <i className="far fa-map-marker-alt" />
      </Button> */}

      {mapVisible && !!selectedIndex && (
        // <Modal
        //   open
        //   onBackdropClick={toggleMapVisibility}
        //   onClose={toggleMapVisibility}
        //   header="Home Studios"
        // >
        <>
          <div className="HomeStudioMap-map">
            <Map
              height={window.innerHeight / 2.5}
              center={{
                lat: stylists[selectedIndex - 1].location!.coordinates[1],
                lng: stylists[selectedIndex - 1].location!.coordinates[0],
              }}
              markers={markers}
              highlightMarker={selectedIndex - 1}
              onMarkerClick={handleMarkerClick}
            />
          </div>

          <ScrollPanel
            onScroll={handleScroll}
            className="has-shadow-1"
            ref={providerListRef}
          >
            <div className="HomeStudioMap-provider-list columns is-mobile is-marginless">
              {stylists.map((s, idx) => (
                <div
                  className={classnames(
                    'HomeStudioMap-provider-list-item',
                    'column is-5-mobile is-3-desktop is-2-widescreen',
                    {
                      'HomeStudioMap-provider-list-item-selected':
                        idx + 1 === selectedIndex,
                    }
                  )}
                  key={s.providerId}
                  data-index={idx + 1}
                >
                  <ProviderCard
                    profileImg={s.profileImage}
                    coverImg={s.coverImage}
                    name={s.firstName}
                    rating={s.reviewScore}
                    reviews={s.numOfReviews}
                    price={s.pricing ? s.pricing.cost : undefined}
                    deposit={s.pricing ? s.pricing.take : undefined}
                    kit={
                      Array.isArray(s.makeupBrands)
                        ? s.makeupBrands.join(', ')
                        : s.makeupBrands
                    }
                    key={s.providerId}
                    onClick={() => {
                      onCardClick && onCardClick(s);
                    }}
                    startTime={s.request && s.request.startTime}
                    endTime={s.request && s.request.finishTime}
                    providerId={s.providerId}
                    type="home"
                    location={getProviderLocation(s, 'home')}
                    distance={Math.round((s.distance || 0) / 1000)}
                    requested={
                      s.request && s.request.stage === RequestStage.requested
                    }
                    bookedOut={
                      s.bookedOut ||
                      (s.request && s.request.stage === RequestStage.declined)
                    }
                    instantOffer={
                      s.request &&
                      (s.request.instantOffer ||
                        s.request.stage === RequestStage.offered)
                    }
                  />
                </div>
              ))}
              <div className="column" style={{ minWidth: '60vw' }}></div>
            </div>
          </ScrollPanel>
        </>
        // </Modal>
      )}
    </>
  );
};
export default HomeStudioMap;
